import { Input } from "@mui/material";
import DatePicker from "./DatePicker";

function FilterElement({
  label,
  predicate,
  filterValue,
  changePredicate,
  changeFilter,
  selectedDate,
  onDateChange,
  options,
}) {
  const filterOptions = {
    "Customer Type": [
      { label: "Select Type", value: "" },
      { label: "individual", value: "0" },
      { label: "business", value: "1" },
    ],
    "Discount Method": [
      { label: "Select Method", value: "" },
      { label: "Percentage", value: "0" },
    ],
  };

  return (
    <div className="mt-2 ml-2 ">
  <label htmlFor="filter" className="filter-label mt-2 text-[#969696] font-bold">
    {label}
  </label>
  <div className="flex items-center flex-row">
    <select
      className="filter-select w-auto mt-2  rounded-sm h-[2.75rem] outline-[#969696]"
      id="filter"
      value={predicate}
      onChange={(e) => changePredicate(e.target.value)}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {(label === "Status" ||
      label === "Customer Type" ||
      label === "Discount Method") && (
      <select
        className="filter-select w-auto h-[2.75rem] mt-2 outline-[#969696] rounded-sm"
        value={filterValue}
        onChange={(e) => changeFilter(e.target.value)}
      >
        {filterOptions[label].map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )}
    {!(label === "Status" || label === "Customer Type" || label === "Discount Method") && (
      <input
        type="text"
        className="filter-input w-auto mt-2 h-[2.75rem] outline-[#969696]  box-border border-[0.5px] rounded-sm"
        id="basic-url"
        aria-describedby=""
        value={filterValue}
        onChange={(e) => changeFilter(e.target.value)}
      />
    )}
    {selectedDate && (
      <DatePicker
        className="filter-datepicker"
        placeholderText="Select Date"
        selected={selectedDate}
        onChange={(date) => onDateChange(date)}
      />
    )}
  </div>
  </div>

  );
}

export default FilterElement;
