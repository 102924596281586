import React from 'react'
import Sidebar from './Sidebar'

function AdminSideBar() {
    const menuItems = [
        {
          key: "dashboard",
          text: "Dashboard",
          src:"/icons/dashboard.svg",
          path: "/",
          allowedRoles: [1, 2,3],
        },
        {
          key: "manageUsers",
          text: "Manage Users",
          src: "/icons/manageUser.svg",
          path: "/all-user",
          allowedRoles: [1, 2],
        },
        {
          key: "manageuser",
          text: "Manage Users",
          icon: "fas fa-users-cog",
          dropdown: true,
          subMenuItems: [
            { key: "createUser", text: "Create User", path: "/create-admin" },
          ],
          allowedRoles: [3],
        },
        {
          key: "cashierdashboard",
          text: "Dashboard",
          icon: "fas fa-fire",
          path: "/",
          allowedRoles: [4],
        },
        {
          key: "Cashiersales",
          text: "Sales",
          icon: "fa fa-shopping-cart",
          dropdown: true,
          subMenuItems: [
            { key: "createSales", text: "Create Sales", path: "/sale-pages" },
            { key: "allSales", text: "All Sales", path: "/sales" },
          ],
          allowedRoles: [4],
        },
        {
          key: "cashierSalemancommission",
          text: "Commission",
          icon: "fa fa-shopping-cart",
          path: "/allpos-salecommission",
          allowedRoles: [4],
        },
        {
          key: "configurations",
          text: "Configurations",
          src: "/icons/settings.svg",
          dropdown: true,
          subMenuItems: [
            {
              key: "allConfigurations",
              text: "Commissions",
              path: "/all-commission",
              src: "/icons/commisions.svg"
            },
          ],
          allowedRoles: [3],
        },
        {
          key: "configurationsAdmin",
          text: "Configurations",
          src: "icons/settings.svg",
          dropdown: true,
          subMenuItems: [
            {
              key: "allConfigurations",
              text: "Commissions",
              path: "/all-commission",
              src: "icons/commisions.svg"
            },
            {
              key: "cashierSession",
              text: "Cashier Session",
              path: "/cashier-session",
              src: "icons/commisions.svg"
            },
            {
              key: "cardcrud",
              text: "Add Card",
              path: "/all-cards",
              src: "icons/commisions.svg"
            },
          ],
          allowedRoles: [2],
        },
        {
            key: "reports",
            text: "Reports",
            src:"/icons/reports.svg",
            dropdown: true,
            subMenuItems: [
              { key: "posReports", text: "POS Report", path: "/pos-reports" ,src:"/icons/posReports.svg"},
              { key: "customReports", text: "Custom Report", path: "/custom-reports" ,src:"/icons/posReports.svg"},
            ],
            allowedRoles: [2],
          },
          {
            key: "inventorymanagement",
            text: "Inventory Management",
            src:"/icons/inventoryManagement.svg",
            dropdown: true,
            subMenuItems: [
              { key: "Alllocations", text: "Locations", path: "/all-locations" ,src:"/icons/locations.svg"},
              { key: "Transfer", text: "Transfers", path: "/transfer-history" ,src:"/icons/ship.svg"},
              { key: "locationpermission", text: "Location Permission ", path: "/location-permission" ,src:"/icons/ship.svg"},
              { key: "accounts", text: "Accounts", path: "/all-accounts" ,src:"/icons/ship.svg"},
            ],
            allowedRoles: [2],
          },
      ];
  return (
    <Sidebar menuItems={menuItems} />
  )
}

export default AdminSideBar