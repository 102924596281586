import React, { useState, useEffect } from "react";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import FilterElement from "Components/Common/FilterElement";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Pagination from "Components/Common/Pagination";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import PosSaleCommissionAPI from "api/posapi/salecommission.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AllSaleCommission() {
  const userRole = useSelector((state) => state.auth.currentUser.role_id);
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterId, setFilterId] = useState("");
  const [percentagePredicate, setPercentagePredicate] = useState("eq");
  const [filterPercentage, setFilterPercentage] = useState("");
  const [valuePredicate, setValuePredicate] = useState("eq");
  const [filtervalue, setFiltervalue] = useState("");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });

  const handleAdvanceFilter = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  useEffect(() => {
    setLoading(true);
    PosSaleCommissionAPI.AllSaleCommissionPOS(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      percentagePredicate,
      filterPercentage,
      valuePredicate,
      filtervalue
    )
      .then((response) => {
        setDataToShow(response.data.sale_commisions);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [
    pageNumber,
    filterPerpage,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && percentagePredicate,
    filter && filterPercentage,
    filter && valuePredicate,
    filter && filtervalue,
  ]);

  const filterElements = [
    {
      label: "ID",
      predicate: idPredicate,
      filterValue: filterId,
      changePredicate: (value) => setIdPredicate(value),
      changeFilter: (value) => setFilterId(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Percentage",
      predicate: percentagePredicate,
      filterValue: filterPercentage,
      changePredicate: (value) => setPercentagePredicate(value),
      changeFilter: (value) => setFilterPercentage(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Value",
      predicate: valuePredicate,
      filterValue: filtervalue,
      changePredicate: (value) => setValuePredicate(value),
      changeFilter: (value) => setFiltervalue(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];
  const navigate = useNavigate();

  const handleDetailsClick = (commissionData) => {
    navigate(`/salesman-detail`, { state: { commissionData } });
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || !str) return "";
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };
  

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between">
          <div className="text-24 font-semibold">Sales Man Commissions</div>
        </div>
        <div>
          <div className="">
        <div className="flex  justify-end">
              <div className=" justify-end mt-4">
                <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md "
                  onClick={handleAdvanceFilter}
                  type="button"
                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
              </div>
            </div>
            </div>  
          {showAdvancedFilters && (
            <div className="card">
              <div className="card-body mt-2">
                <div className="flex flex-wrap py-4">
                  {filterElements.map((filterElement, index) => (
                    <FilterElement
                      key={index}
                      label={filterElement.label}
                      predicate={filterElement.predicate}
                      filterValue={filterElement.filterValue}
                      changePredicate={filterElement.changePredicate}
                      changeFilter={filterElement.changeFilter}
                      options={filterElement.options}
                    />
                  ))}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelectDropDown
                      className="form-group col-md-2"
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <table className="lg:table-auto w-full py-5 text-center">
            <thead>
              <tr>
                <th class="pb-6 pt-10 w-[90px] text-center  text-blue">
                  Invoice ID
                </th>
                <th class="pb-6 pt-10 w-[90px] text-center  text-blue">
                  Invoice No
                </th>
                <th class="pb-6 pt-10 w-[90px] text-center  text-blue">
                  Commission Type
                </th>
                <th class="pb-6 pt-10 w-[90px] text-center  text-blue">
                  Value Type
                </th>
                <th class="pb-6 pt-10 w-[90px] text-center  text-blue">
                  Com/Amt
                </th>
                <th class="pb-6 pt-10 w-[90px]  text-center  text-blue">
                  Value
                </th>
                {userRole != 4 && (
                  <th class="pb-6 pt-10 w-[90px] text-center text-blue">
                    Salesman Name
                  </th>
                )}
                <th class="pb-6 pt-10 text-center w-[90px] font-extrabold  text-blue">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {dataToShow.length === 0 ? (
                <tr>
                  <td colSpan={9} className="pb-4 text-center">
                    No Data Available
                  </td>
                </tr>
              ) : (
                dataToShow.map((commission, index) => {
                  return (
                    <tr key={index}>
                      <td className="py-4 w-[90px] text-center">
                        {commission.sale_id || "N/A"}
                      </td>
                      <td className="py-4 w-[90px] text-center">
                        {commission.invoice_no || "N/A"}
                      </td>
                      <td className="py-4 w-[90px] text-center">
                        {capitalizeFirstLetter(
                          commission.sale_commision_type || "N/A"
                        )}
                      </td>
                      <td className="py-4 w-[90px] text-center">
                        {capitalizeFirstLetter(
                          commission.commision_type || "N/A"
                        )}
                      </td>
                      <td className="py-4 w-[90px] text-center">
                        {commission.commision_value || "N/A"}
                      </td>
                      <td className="py-4 w-[90px] text-center">
                        {commission.value || "N/A"}
                      </td>
                      {userRole != 4 && (
                        <td className="py-4 w-[90px] text-center">
                          {capitalizeFirstLetter(commission.salesman || "N/A")}
                        </td>
                      )}
                      <td className="py-4 w-[90px] text-center">
                        <i
                          className="fas fa-eye"
                          onClick={() => handleDetailsClick(commission)}
                        ></i>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllSaleCommission;
