import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";

function CommissionDetails() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [commissionData, setCommissionData] = useState("");

  useEffect(() => {
    ConfigurationAPI.viewCommission({ id })
      .then((response) => {
        setLoading(false);
        setCommissionData(response?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [id]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Invoice Commission Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img
              src={Loader}
              className="rounded-full m-auto"
              alt="Loading"
            />
          </div>
        ) : (
          <>
            <div className="w-full min-h-[50vh] mt-5">
              <div className="w-full flex justify-between">
                <div className="w-[40%] min-h-[50vh] bg-white rounded-3xl">
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Commission Type:</div>
                    <div>
                      {
                        commissionData?.sale_commision_configuration
                          ?.commision_type
                      }
                    </div>
                  </div>
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Commission Value:</div>
                    <div>
                      {commissionData?.sale_commision_configuration?.value}
                    </div>
                  </div>
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Discount Type:</div>
                    <div>
                      {
                        commissionData?.sale_commision_configuration
                          ?.discount_type
                      }
                    </div>
                  </div>
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className="font-bold">Discount Value:</div>
                    <div>
                      {
                        commissionData?.sale_commision_configuration
                          ?.discount_value
                      }
                    </div>
                  </div>
                </div>
                <div className="w-[60%] bg-white rounded-3xl ml-5">
                  <div className="mt-4 max-h-[50vh] overflow-y-auto overflow-x-hidden">
                    <div className="w-full p-3 flex justify-center">
                      <table className="w-[80%] bg-white border-2 border-b-0 border-gray-300">
                        <thead className="w-full">
                          <tr className="w-full justify-between flex border-b-2 border-gray-300">
                            <th className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                              Id
                            </th>
                            <th className="py-2 px-4 w-[80%] flex justify-center">
                              Location
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {commissionData.sale_commision_configuration.locations.map(
                            (item) => (
                              <tr
                                key={item.location_id}
                                className="w-full justify-between flex border-b-2 border-gray-300"
                              >
                                <td className="py-2 px-4 w-[20%] border-r-2 border-gray-300 flex justify-center">
                                  {item.location_id}
                                </td>
                                <td className="py-2 px-4 w-[80%] flex justify-center">
                                  {item.address}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CommissionDetails;
