import React, { useState, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import LocationsAPI from "api/location/location";
import AccountsApi from "api/inventorymanagement/accounts";
import ShowComponent from "Components/ShowComponent";

function CreateAccounts({ contentOnly = false, setPopupId, fetchData }) {
  const [accounts, setAccounts] = useState([{ name: "", code: "" }]);

  const [accountdata, setAccountdata] = useState({
    locations: [],
    selectedBranch: "",
    accounts: accounts,
  });

  useEffect(() => {
    setAccountdata((accountData) => ({ ...accountdata, accounts }));
  }, [accounts]);

  useEffect(() => {
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setAccountdata((prevState) => ({
          ...prevState,
          locations: response?.data?.locations,
        }));
      })
      .catch((error) => {
        if (error) {
          toast.error("Error fetching locations");
        }
      });
  }, []);

  const handleInputChange = (e) => {
    const { id, name, value } = e.target;

    if (name === "selectedBranch") {
      setAccountdata((accountdata) => ({ ...accountdata, [name]: value }));
      return;
    }

    const updatedData = accounts.map((obj, index) =>
      index == id ? { ...obj, [name]: value } : obj
    );
    setAccounts(updatedData);
  };

  const addAccount = () => {
    setAccounts((accounts) => [...accounts, { name: "", code: "" }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setAccounts((account) =>
      account.map((account) => ({
        ...account,
        location_id: accountdata.selectedBranch,
      }))
    );

    console.log({
      account_codes: [...accounts],
    });

    console.log(accountdata);

    AccountsApi.createAccount({
      account_codes: accounts.map(account => ({...account, location_id: accountdata.selectedBranch})),
    })
      .then((response) => {
        toast.success(response.data?.message);
        setAccountdata({
          locations: [],
          selectedBranch: "",
          code: "",
          name: "",
        });
        setPopupId("");
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="relative">
      <div className="text-24 w-full text-center pb-4">Create Account</div>
      <div className="w-full mt-4 max-h-[50vh] min-h-[50vh] overflow-auto">
        <div className="w-[90%] mx-2">
          <label htmlFor="branchName">Branch Name</label>
          <select
            className="form-control mt-2"
            name="selectedBranch"
            value={accountdata.selectedBranch}
            required
            onChange={handleInputChange}
          >
            <option value="">Select Branch</option>
            {accountdata.locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.address}
              </option>
            ))}
          </select>
        </div>

        {accounts
          .map((account, index) => (
            <div className="flex items-center">
              <div className={`mx-2 ${index != 0 ? "mt-2" : "no-margin-top"}`}>
                <input
                  type="number"
                  id={index}
                  className="form-control mt-2 hideNumberArrow"
                  name="code"
                  placeholder="Enter Code"
                  value={account.code}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className={`mx-2 ${index != 0 ? "mt-2" : "no-margin-top"}`}>
                <input
                  type="text"
                  id={index}
                  className="form-control mt-2 hideNumberArrow"
                  name="name"
                  placeholder="Enter Name"
                  value={account.name}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <ShowComponent condition={index == 0}>
                <button
                  className=" bg-[#287dfd] rounded-full text-white min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px] flex justify-center items-center"
                  onClick={addAccount}
                >
                  +
                </button>
              </ShowComponent>
            </div>
          ))
          .reverse()}
      </div>
      <div className="w-full flex justify-center my-8">
        <Button onClick={handleSubmit}>Create Account</Button>
      </div>
      {/* <div className="w-full flex justify-center my-8">
      
        <Button type="button" onClick={addAccount}>
          Add Account
        </Button>
      </div> */}
    </div>
  );
}

export default CreateAccounts;
