import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function SalesReportInvoices({
  startDate,
  endDate,
  showTime,
  selectedReport,
  selectedBranch,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center mt-5 mb-10">
          {selectedReport === "sales_by_invoice_report_return"
            ? "Sales Report Invoices - Return"
            : "Sales Report Invoices - Summary"}
        </div>
        <div className="flex w-full justify-between px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">From Date:</div>
              <div className="w-[50%]">{FormatDate(startDate)}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">To Date:</div>
              <div className="w-[50%]">{FormatDate(endDate)}</div>
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full text-center">
              <th className="w-[28%] px-2 py-4" style={{ textAlign: "start" }}>
                Salesman
              </th>
              <th className="w-[12%] pr-2 py-4">Total Price</th>
              <th className="w-[12%] pr-2 py-4">Discount</th>
              <th className="w-[12%] pr-2 py-4">Coupon</th>
              {selectedReport === "sales_by_invoice_report_return" && (
                <th className="w-[12%] pr-2 py-4">Taxable</th>
              )}
              {(selectedReport === "sales_by_invoice_report" ||
                selectedReport === "sales_by_invoice_report_return") && (
                <th className="w-[12%] pr-2 py-4">Tax</th>
              )}
              <th className="w-[12%] pr-2 py-4">Bonus</th>
              <th className="w-[12%] px-2 py-4">Net</th>
            </tr>
          </thead>
          <tr>
            <td colSpan="10" className="p-4 font-bold">
              Branch:{" "}
              {selectedBranch?.name
                ? capitalizeFirstLetter(selectedBranch?.name)
                : ""}
            </td>
          </tr>
          <tbody className="w-full pt-3">
            <>
              {reportData?.map((item, index) => (
                <tr key={index} className="w-full text-center">
                  <td
                    class="w-[28%] pr-2 py-4 pl-4"
                    style={{ textAlign: "start" }}
                  >
                    {item?.salesman}
                  </td>
                  <td class="w-[12%] pr-2 py-4">{item?.total_price}</td>
                  <td class="w-[12%] pr-2 py-4">{item?.discount}</td>
                  <td class="w-[12%] pr-2 py-4">{item?.coupon_discount}</td>
                  {selectedReport === "sales_by_invoice_report_return" && (
                    <td class="w-[12%] pr-2 py-4">{item?.taxable}</td>
                  )}
                  {(selectedReport === "sales_by_invoice_report" ||
                    selectedReport === "sales_by_invoice_report_return") && (
                    <td class="w-[12%] pr-2 py-4">{item?.tax}</td>
                  )}
                  <td class="w-[12%] pr-2 py-4">{item?.salesman_reward}</td>
                  {(selectedReport === "sales_by_invoice_report" ||
                    selectedReport === "sales_by_invoice_report_return") ? (
                    <td class="w-[12%] pr-2 py-4">{item?.net_sale}</td>
                  ) : (
                    <td class="w-[12%] pr-2 py-4">
                      {item?.without_vat_net_sale}
                    </td>
                  )}
                </tr>
              ))}
              <tr className="w-full text-center border-t-1 border-black">
                <td class="w-[28%] px-2 py-4"></td>
                <td class="w-[12%] pr-2 py-4 font-bold">
                  {reportSummary?.total_total_price}
                </td>
                <td class="w-[12%] pr-2 py-4 font-bold">
                  {reportSummary?.total_discount}
                </td>
                <td class="w-[12%] pr-2 py-4 font-bold">
                  {reportSummary?.total_coupon_discount}
                </td>
                {selectedReport === "sales_by_invoice_report_return" && (
                  <td class="w-[12%] pr-2 py-4 font-bold">
                    {reportSummary?.total_taxable}
                  </td>
                )}
                {(selectedReport === "sales_by_invoice_report" ||
                  selectedReport === "sales_by_invoice_report_return") && (
                  <td class="w-[12%] pr-2 py-4 font-bold">
                    {reportSummary?.total_tax}
                  </td>
                )}
                <td class="w-[12%] pr-2 py-4 font-bold">
                  {reportSummary?.total_salesman_reward}
                </td>
                {(selectedReport === "sales_by_invoice_report" ||
                selectedReport === "sales_by_invoice_report_return") ? (
                  <td class="w-[12%] pr-2 py-4 font-bold">
                    {reportSummary?.total_net_sale}
                  </td>
                ) : (
                  <td class="w-[12%] pr-2 py-4 font-bold">
                    {reportSummary?.total_without_vat_net_sale}
                  </td>
                )}
              </tr>
            </>
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesReportInvoices;
