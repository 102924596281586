import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

export default function CouponSaleReport({
  startDate,
  endDate,
  selectedBranch,
  couponSaleReport,
  couponSummary,
  showTime,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center my-5">
          Sales Report by Coupon
        </div>
        <div className="text-20 font-bold text-center mt-5 mb-10">
          From Date: {FormatDate(startDate)} To Date: {FormatDate(endDate)}
        </div>
        <div className="flex w-full justify-end px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full text-center">
              <th className="w-[15%] px-2 py-4">Voucher Code</th>
              <th className="w-[15%] pr-2 py-4">Invoice No</th>
              <th className="w-[15%] pr-2 py-4">Invoice Date</th>
              <th className="w-[14%] pr-2 py-4">Total Price</th>
              <th className="w-[14%] pr-2 py-4">Discount</th>
              <th className="w-[14%] pr-2 py-4">Net Amount</th>
              <th className="w-[13%] pr-2 py-4">RET</th>
            </tr>
          </thead>
          <tr>
            <td colSpan="10" className="p-4 font-bold">
              Branch:{" "}
              {selectedBranch?.name
                ? capitalizeFirstLetter(selectedBranch?.name)
                : ""}
            </td>
          </tr>
          <tbody className="w-full pt-3">
            {couponSaleReport && couponSaleReport.length > 0 ? (
              <>
                {couponSaleReport?.map((item, index) => (
                  <tr
                    key={index}
                    className="w-full text-center border-t-1 border-black"
                  >
                    <td className="w-[15%] px-2 py-4">{item.coupon_code}</td>
                    <td className="w-[15%] pr-2 py-4">{item.invoice_no}</td>
                    <td className="w-[15%] pr-2 py-4">
                      {FormatDate(item.updated_at)}
                    </td>
                    <td className="w-[14%] pr-2 py-4">{item.sub_total}</td>
                    <td className="w-[14%] pr-2 py-4">{item.coupon_discount}</td>
                    <td className="w-[14%] pr-2 py-4">{item.total_price}</td>
                    <td className="w-[13%] pr-2 py-4 font-bold">
                      {item.status === "completed" ? "N" : "Y"}
                    </td>
                  </tr>
                ))}
                <tr className="w-full text-center">
                  <td class="w-[15%] px-2 py-4"></td>
                  <td class="w-[15%] pr-2 py-4"></td>
                  <td class="w-[15%] pr-2 py-4"></td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_sub_total}
                  </td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_coupon_discount}
                  </td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_total_price}
                  </td>
                  <td class="w-[13%] pr-2 py-4"></td>
                </tr>
                <tr className="w-full text-center">
                  <td class="w-[15%] px-2 py-4"></td>
                  <td class="w-[15%] pr-2 py-4"></td>
                  <td class="w-[15%] pr-2 py-4"></td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_sub_total}
                  </td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_coupon_discount}
                  </td>
                  <td class="w-[14%] pr-2 py-4 font-bold">
                    {couponSummary?.total_total_price}
                  </td>
                  <td class="w-[13%] pr-2 py-4"></td>
                </tr>
              </>
            ) : (
              <tr className="w-full pt-4 text-center border-t-1 border-black">
                <td colSpan="10" className="p-4 font-bold">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}
