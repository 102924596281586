import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function InvoicesList({
  startDate,
  endDate,
  selectedReport,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center my-5">
          {selectedReport ? capitalizeFirstLetter(selectedReport) : ""}
        </div>
        <div className="text-20 font-bold text-center mt-5 mb-10">
          From Date: {FormatDate(startDate)} To Date: {FormatDate(endDate)}
        </div>
        <div className="flex w-full justify-end px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full text-center">
              <th className="w-[7%] px-2 py-4">B #</th>
              <th className="w-[7%] pr-2 py-4">Invoice#</th>
              <th className="w-[13%] pr-2 py-4">Invoice Date</th>
              <th className="w-[13%] pr-2 py-4">Salesman</th>
              <th className="w-[10%] pr-2 py-4">Order #</th>
              <th className="w-[10%] pr-2 py-4">Bouns</th>
              <th className="w-[10%] pr-2 py-4">Subtotal</th>
              <th className="w-[10%] pr-2 py-4">Discount</th>
              <th className="w-[10%] pr-2 py-4">Tax</th>
              <th className="w-[10%] pr-2 py-4">Total</th>
            </tr>
          </thead>
          <tbody className="w-full pt-3">
            {reportData?.map((item, index) => (
              <tr
                key={index}
                className="w-full text-center border-b-1 border-black"
              >
                <td className="w-[7%] px-2 py-2">{item?.location_id}</td>
                <td className="w-[7%] pr-2 py-2">{item?.invoice_no}</td>
                <td className="w-[13%] pr-2 py-2">
                  {item.updated_at ? FormatDate(item.updated_at) : ""}
                </td>
                <td className="w-[13%] pr-2 py-2">{item?.salesman_name}</td>
                <td className="w-[10%] pr-2 py-2">{"Local"}</td>
                <td className="w-[10%] pr-2 py-2">{item?.salesman_reward}</td>
                <td className="w-[10%] pr-2 py-2">{item?.total_sale_price}</td>
                <td className="w-[10%] pr-2 py-2">{item?.total_discount}</td>
                <td className="w-[10%] pr-2 py-2">{item?.total_tax}</td>
                <td className="w-[10%] pr-2 py-2">{item?.net_sale}</td>
              </tr>
            ))}

            <tr className="w-full text-center border-b-2 border-gray-400">
              <td className="w-[10%] px-2 py-4">{""}</td>
              <td className="w-[10%] pr-2 py-4">{""}</td>
              <td className="w-[10%] pr-2 py-4">{""}</td>
              <td className="w-[10%] pr-2 py-4">{""}</td>
              <td className="w-[10%] pr-2 py-4">{""}</td>
              <td className="w-[10%] pr-2 py-4 font-bold">
                {reportSummary?.total_salesman_reward}
              </td>
              <td className="w-[10%] pr-2 py-4 font-bold">
                {reportSummary?.total_total_price}
              </td>
              <td className="w-[10%] pr-2 py-4 font-bold">
                {reportSummary?.total_discount}
              </td>
              <td className="w-[10%] pr-2 py-4 font-bold">
                {reportSummary?.total_tax}
              </td>
              <td className="w-[10%] pr-2 py-4 font-bold">
                {reportSummary?.total_net_sale}
              </td>
            </tr>
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default InvoicesList;
