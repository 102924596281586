import React from "react";

function GeneralSelectDropDown({ title, value, onChange, options, className, name, required }) {
  if (!options) {
    return null;
  }

  return (
    title === "Show per page" ? (
      <div className="mt-2 form-group">
        <div className="text-[#969696] font-bold ml-2">{title}</div>
        <select
          className={`mt-2 h-[2.75rem] ${className} ml-2`}
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ) : title === "Payment Status" ?(
      <div className={className}>
        <div className="mb-2">{title}</div> 
        <select
          className="h-[2.75rem] rounded-md w-[100%] border-[0.5px] mb-4" // Add className here
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ):(
      <div className="form-group">
        <div className="mb-2">{title}</div> 
        <select
          className={className}
          value={value}
          name={name}
          onChange={onChange}
          required={required}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ) 
  );
}

export default GeneralSelectDropDown;
