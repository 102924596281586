import React from "react";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function PagesWrapper({ children }) {
  return (
    <div className="">
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
      <section className="">{children}</section>
    </div>
  );
}
export default PagesWrapper;
