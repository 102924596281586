import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Button from "Components/Common/Button";

function SaleManDetail() {
  const location = useLocation();
  const commissionData = location.state?.commissionData || {};
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || !str) return "";
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/allpos-salecommission");
  };

  return (
    <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Sales Man Commission Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <table className="w-[50%] bg-white border rounded-2xl flex flex-row mt-5">
          <thead className="flex-1 mt-10">
            <tr className="flex flex-col w-full">
              <th className="py-4 px-4 border-b">Commission Type</th>
              <th className="py-4 px-4 border-b">Value Type</th>
              <th className="py-4 px-4 border-b">Value</th>
              <th className="py-4 px-4">Salesman Name</th>
            </tr>
          </thead>
          <tbody className="flex-1 mt-10">
            <tr className="flex flex-col w-full">
              <td className="py-4 px-4 border-b w-full flex justify-end">
                {capitalizeFirstLetter(commissionData.sale_commision_type) ||
                  "N/A"}
              </td>
              <td className="py-4 px-4 border-b w-full flex justify-end">
                {capitalizeFirstLetter(commissionData.commision_type) || "N/A"}
              </td>
              <td className="py-4 px-4 border-b w-full flex justify-end">
                {commissionData.commision_value || "N/A"}
              </td>
              <td className="py-4 px-4 w-full flex justify-end">
                {capitalizeFirstLetter(commissionData.salesman) || "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SaleManDetail;
