import React, { useEffect, useState } from "react";
import ShowComponent from "Components/ShowComponent";
import Table from "Components/Common/GeneralTable";
import DatePicker from "Components/Common/DatePicker";
import PurchasesAPI from "api/posapi/purchases";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import GernalInput from "Components/Common/GeneralInput";
import Button from "Components/Common/Button";
import Popup from "Components/Common/Pages/Popup";
import CreateVendor from "./CreateVendor";
import { toast } from "react-toastify";
import LocationsAPI from "api/location/location";
import Select from "react-select";
import FormatDate from "Components/Common/FormatDate";

function CreatePurchase({fetchData,setPopupId}) {
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState("");
  const [barcodealert, setBarcodeAlert] = useState("");
  const [price, setPrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [date, setDate] = useState(new Date());
  const [barcode_error, setBarCodeError] = useState(false);
  const [error_message, setErrorMessage] = useState("");
  const [locationerror, setLocationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [vendorid, setVendorId] = useState(null);
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [notfound, setNotFound] = useState("");
  const [showSearchedCustomers, setShowSearchedCustomers] = useState(false);
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [popId, setPopId] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [itemDetails, setItemDetails] = useState({
    barcode: "",
    productid: null,
    productName: null,
    unitPrice: null,
    quantity: null,
    unitCost: null,
  });

  const handleListItemClick = (phoneNo, vendorid) => {
    setVendorId(vendorid);
    setFilterPhoneNumber(phoneNo);
    setShowSearchedCustomers(false);
  };
  useEffect(() => {
    PurchasesAPI.allVendor(filterPhoneNumber)
      .then((response) => {
        setSearchedCustomers(response?.data.vendors);
        setLoading(false);
      })
      .catch((error) => {
        setNotFound(error.response?.data.message);
        setTimeout(() => {
          setNotFound("");
        }, 3000);
        setLoading(false);
      });
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filterPhoneNumber]);
  const handleInputfilterChange = (event) => {
    setFilterPhoneNumber(event.target.value);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setItemDetails({ ...itemDetails, barcode: value });
    const remainingCharacters = 13 - parseInt(value.length);

    if (value.length !== 13) {
      setBarcodeAlert(
        remainingCharacters >= 1
          ? `Remaining characters: ${remainingCharacters}`
          : ""
      );
      setBarCodeError(true);
      setErrorMessage("Number of characters should be equal to 13!");
    } else {
      PurchasesAPI.searchProductAPI(value)
        .then((response) => {
          const name = response.data.product.name;
          const id = response.data.product.id;
          setItemDetails({
            ...itemDetails,
            productName: name,
            productid: id,
            barcode: value,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      setBarcodeAlert("");
      setBarCodeError(false);
      setErrorMessage("");
    }
  };
  const handleSendToBackend = (e) => {
    e.preventDefault();
    if (
      tableData.length === 0 &&
      (!itemDetails.productName ||
        !itemDetails.unitPrice ||
        !itemDetails.quantity)
    ) {
      setAlert(
        "Please add items to the table or fill in the items details fields."
      );
      setTimeout(() => {
        setAlert("");
      }, 3000);
      return;
    }
    let purchaseItems = [];
    if (tableData.length > 0) {
      purchaseItems = tableData.map((item) => ({
        product_id: parseInt(item?.id),
        unit_price: parseFloat(item.unit_price),
        quantity: parseInt(item.quantity),
        unit_cost: parseFloat(item.unit_cost),
      }));
    } else {
      purchaseItems = [
        {
          product_id: parseInt(itemDetails.productid),
          quantity: parseInt(itemDetails.quantity),
          unit_price: parseFloat(itemDetails.unitPrice),
          unit_cost: parseFloat(itemDetails.unitCost),
        },
      ];
    }

    const purchaseData = {
      purchase: {
        purchase_price: parseFloat(purchasePrice),
        vendor_id: vendorid,
        purchase_items: purchaseItems,
        purchase_date: FormatDate(date),
        location_id: selectedLocation,
      },
    };

    setLoading(true);
    PurchasesAPI.createProductpurchasesAPI(purchaseData)
      .then((response) => {
        setLocationError(response?.data?.[0]);
        toast.success(response.data.purchases.message);
        fetchData()
        setPopupId("")
        setItemDetails({
          productName: "",
          unitPrice: "",
          quantity: "",
          barcode: "",
          unitCost: "",
        });
        setPrice("");
        setPurchasePrice("");
        setDate(new Date());
        setTableData([]);
        setShow(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
        setLoading(false);
      });
  };

  const handleDeleteClick = (itemToDelete) => {
    const updatedTableData = tableData.filter(
      (item) => item.id !== itemToDelete.id
    );
    setTableData(updatedTableData);
  };

  useEffect(() => {
    let sum = 0;
    tableData.forEach((item) => {
      sum += item.Total;
    });
    setPurchasePrice(sum);
  }, [tableData.length]);

  const inputFields = [
    {
      title: "Barcode",
      type: "number",
      value: itemDetails.barcode,
      onChange: handleInputChange,
      placeholder: "Search by Barcode",
    },
    {
      title: "Product Title",
      type: "text",
      value: itemDetails.productName,
      placeholder: "Product Title",
    },
    {
      title: "Unit Cost",
      type: "number",
      placeholder: "0",
      value: itemDetails.unitCost,
      onChange: (e) =>
        setItemDetails({ ...itemDetails, unitCost: e.target.value }),
    },
    {
      title: "Unit Price",
      value: itemDetails.unitPrice,
      type: "number",
      onChange: (e) =>
        setItemDetails({ ...itemDetails, unitPrice: e.target.value }),
      placeholder: "0",
    },
    {
      title: "Quantity",
      value: itemDetails.quantity,
      type: "number",
      onChange: (e) =>
        setItemDetails({ ...itemDetails, quantity: e.target.value }),
      placeholder: "0",
    },
    
    {
      title: "Total",
      value: itemDetails.unitCost * itemDetails.quantity,
      type: "number",
      onChange: null,
      placeholder: "0",
    },
  ];

  const handleSaveToTable = (e) => {
    e.preventDefault();
    if (
      !itemDetails.productName ||
      !itemDetails.unitPrice ||
      !itemDetails.quantity
    ) {
      setAlert("Please fill the items details fields.");
      setTimeout(() => {
        setAlert("");
      }, 3000);
      return;
    }
    setShow(true);
    const newDataToSave = {
      id: itemDetails.productid,
      name: itemDetails.productName,
      barcode: itemDetails.barcode,
      unit_cost: itemDetails.unitCost,
      unit_price: itemDetails.unitPrice,
      quantity: itemDetails.quantity,
      Total: itemDetails.unitCost * itemDetails.quantity,
    };
    setTableData((prevTableData) => [...prevTableData, newDataToSave]);
    setItemDetails({
      productName: "",
      unitPrice: "",
      quantity: "",
      barcode: "",
      unitCost: "",
    });
    setPrice("");
  };

  const handleVendorCreate = (vendorid) => {
    setVendorId(vendorid);
    setTimeout(() => {
      setPopId("");
    }, 2000);
  };

  return (
    <PagesWrapper>
      <h1 className="text-18 mb-4 text-center font-semibold"> Create Purchase</h1>
      <form onSubmit={handleSendToBackend}>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
          <div>
          <p className="font-semibold mb-3">Search Vendor</p>
          <div className="flex w-full mt-2">
            <input
              type="number"
              min="0"
              className="form-control rounded-r-none rounded-l-lg"
              placeholder="By Phone No"
              value={filterPhoneNumber}
              onChange={handleInputfilterChange}
              onFocus={() => setShowSearchedCustomers(true)}
              required
            />
            <div className="bg-blue w-[40px] flex items-center justify-center rounded-r-lg cursor-pointer"
               onClick={() => setPopId("addVendor")}
               >
              <img 
              className="h-[15px] cursor-pointer"
               src="/icons/createCustomers.svg"
               onClick={() => setPopId("addVendor")}
              />
              </div>
            </div>
            {filterPhoneNumber.length > 0 && showSearchedCustomers && (
              <ul
                className="m-0 list-inline bg-white border position-absolute w-100 z-index-1"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                {searchedCustomers.map((customer) => (
                  <li
                    className="px-2 py-1"
                    key={customer.id}
                    onClick={() =>
                      handleListItemClick(customer.phone_no, customer.id)
                    }
                  >
                    {customer.phone_no}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <p className="font-semibold mb-3">Location</p>
            <Select
              classNames={{ control: () => "form-control p-1.5" }}
              required
              value={selectedLocation?.id}
              onChange={(selectedOption) =>
                setSelectedLocation(selectedOption?.value)
              }
              options={locations.map((value) => ({
                value: value.id,
                label: value.address,
              }))}
              isClearable={true}
              isSearchable={true}
            />
          </div>
          <div>
            <p className="font-semibold mb-3">Date</p>
            <input type="date" className="form-control" value={date} onChange={ (e) => setDate(e.target.value)}/>
          </div>
        </div>

        <h1 className="text-18 mb-4 text-center font-semibold mt-6">Items Details </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          {inputFields.map((obj, index) => (
            <GernalInput
              key={index}
              title={obj.title}
              value={obj.value}
              onChange={obj.onChange}
              placeholder={obj.placeholder}
              type={obj.type}
              required={obj.required}
              paraClassName= "font-semibold mb-3"
            />
          ))}
        </div>
        <div className="text-center">
         <Button
          type="button"
          className="mt-5"
          onClick={handleSaveToTable}
        >
          Add
        </Button>
        </div>
        <div className="text-center">
          {barcodealert && <p className="mt-3 text-red">{barcodealert}</p>}
          {barcode_error && <p className="mt-2 text-red">{error_message}</p>}
          <p className="mt-5 mb-5 text-center text-blue">
            Note: Click "Add" Button for multiple products
          </p>
        </div>
        {alert && (
          <div className="alert alert-danger mt-3 mb-0 text-center">
            {alert}
          </div>
        )}
    <ShowComponent condition={show}>
  {tableData.length > 0 && (
 <div className="overflow-x-auto">
 <table className="table-auto w-full border rounded-lg min-w-[500px]">
   <thead>
     <tr>
      <div className="flex w-full text-center border-b">
       <th className="pb-4 pt-5 w-[20%] text-center text-blue">Product Name</th>
       <th className="pb-4 pt-5 w-[20%] text-center text-blue">Barcode</th>
       <th className="pb-4 pt-5 w-[12%] text-center text-blue">Unit Cost</th>
       <th className="pb-4 pt-5 w-[12%] text-center text-blue">Unit Price</th>
       <th className="pb-4 pt-5 w-[12%] text-center text-blue">Quantity</th>
       <th className="pb-4 pt-5 w-[12%] text-center text-blue">Total</th>
       <th className="pb-4 pt-5 w-[12%] text-center text-blue">Actions</th>
       </div>
     </tr>
   </thead>
   <tbody>
     {tableData.map((item) => (
       <tr key={item.id}>
        <div className="flex w-full text-center border-b">
         <td className="py-4 w-[20%] text-center">{item.name}</td>
         <td className="py-4 w-[20%] text-center">{item.barcode}</td>
         <td className="py-4 w-[12%] text-center">{item.unit_cost}</td>
         <td className="py-4 w-[12%] text-center">{item.unit_price}</td>
         <td className="py-4 w-[12%] text-center">{item.quantity}</td>
         <td className="py-4 w-[12%] text-center">{item.Total}</td>
         <td className="py-4 w-[12%] text-center">
          <i
            className="fas fa-trash-alt"
            color="red"
            onClick={() => handleDeleteClick(item)}
          ></i>
         </td>
         </div>
       </tr>
     ))}
   </tbody>
 </table>
</div>

  )}
</ShowComponent>

        {tableData.length > 0 && (
          <div className="w-full flex justify-end mt-4">
            <div class="w-[300px] border rounded p-4">
              <div class="flex justify-between">
                <div class="font-bold">Grand Total:</div>
                <div>{purchasePrice}</div>
              </div>
            </div> 
          </div> )}
        <div className="text-center mt-5">
          <Button
            className={
              loading ? "btn-primary btn-progress" : "btn btn-primary"
            }
            type="submit"
          >
            Create Purchase
          </Button>
        </div>
        {notfound && (
          <div className="alert alert-danger mt-3 mb-0 text-center">
            {notfound}
          </div>
        )}
        {locationerror && (
          <div className="alert alert-danger mt-3 mb-0 text-center">
            {locationerror}
          </div>
        )}
      </form>
      <ShowComponent condition={popId === "addVendor"}>
        <Popup setPopup={() => setPopId("")} className="w-[450px]">
          <CreateVendor vendorCreated={handleVendorCreate} contentOnly={true} 
          setPopupId={setPopupId}
          />
        </Popup>
      </ShowComponent>
    </PagesWrapper>
  );
}

export default CreatePurchase;
