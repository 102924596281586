import axiosClient from "..";

class CustomReportApi {
    static dailySalesReport(branchID, startDate, endDate){
        return axiosClient.get(`/api/reports/sales_custom_report.json?location_id=${branchID}&start_date=${startDate}&end_date=${endDate}`);
    }
    static dailySalesmanReport(branchID,salesmanID, startDate, endDate){
        return axiosClient.get(`/api/reports/salesman_sales_custom_report.json?location_id=${branchID}&salesman_id=${salesmanID}&start_date=${startDate}&end_date=${endDate}`);
    }
    static dailyCouponSaleReport(branchID, startDate, endDate){
        return axiosClient.get(`/api/reports/coupon_sales_custom_report.json?location_id=${branchID}&start_date=${startDate}&end_date=${endDate}`);
    }
    static endDaySaleReport(branchID, salesmanID, startDate){
        return axiosClient.get(`/api/reports/end_day_custom_report.json?location_id=${branchID}&salesman_id=${salesmanID}&start_date=${startDate}`);
    }
    static invoicesListReport(branchID, startDate, endDate, status){
        return axiosClient.get(`/api/reports/invoice_analysis_return_custom_report.json?location_id=${branchID}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
    }
    static saleReportInvoices(branchID, startDate, endDate, status){
        return axiosClient.get(`/api/reports/invoice_summary_return_custom_report.json?location_id=${branchID}&start_date=${startDate}&end_date=${endDate}&status=${status}`);
    }
    static invoiceDetailsReport(branchID, startDate, endDate){
        return axiosClient.get(`/api/reports/invoice_details_report.json?location_id=${branchID}&start_date=${startDate}&end_date=${endDate}`);
    }
    static salesInvoiceJV(branchID, invoiceNo, invoiceDate){
        return axiosClient.get(`/api/reports/invoice_sale_jv.json?location_id=${branchID}&invoice_no=${invoiceNo}&invoice_date=${invoiceDate}`);
    }
}
export default CustomReportApi;