import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TaxAPI from "api/posapi/tax";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";
import PosSidebar from "Components/Common/PosSidebar";
import Table from "Components/Common/GeneralTable";
import AppWrapper from "Components/Routes/AppWrapper";
import CreateTax from "./CreateTax";

function AllTaxes() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([]);
  const [filterdData,setFilterdData] = useState([]) 
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showpopup, setShowPopUp] = useState(false);
  const [popupId, setPopupId] = useState("");
  const handleDetailsClick = (itemId) => {
    navigate(`/tax/details/${itemId}`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setShowPopUp(true);
  };
  const deleteConfirmed = () => {
    setShowPopUp(false);
    if (itemToDelete) {
      TaxAPI.DeleteTax(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setDataToShow(newData);
          setStatus(response.status);
          setTimeout(() => {
            setStatus("");
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
      setItemToDelete(null);
    }
  };
  const cancelDelete = () => {
    setShowPopUp(false);
    setItemToDelete(null);
  };
  const actions = [
    {
      name: "View",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Delete",
      onClick: handleDeleteClick,
    },
  ];

 const fetchTaxes = () => {
  setLoading(true);
  TaxAPI.allTaxAPI()
    .then((response) => {
      setDataToShow(response.data);
      setFilterdData(response.data.map((item)=>{
        return {id:item.id,percentage:item.percentage,name:item.name,status:item.active === true ? 'Active':'Non-Active'}
      }))
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
 }

  useEffect(() => {
    fetchTaxes()
  }, []);
  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <div className="w-full flex my-4 justify-between">
            <div className="flex items-center justify-center">
              <h1 className="text-24 font-semibold">VATS</h1>
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("createVat")}
              >
                Create VAT
              </button>
            </div>
          </div>
          <ShowComponent condition={popupId === "createVat"}>
            <Popup setPopup={() => setPopupId("")} className="w-[450px]">
              <CreateTax contentOnly={true}
              setPopupId={setPopupId}
              fetchTaxes={fetchTaxes}
              />
            </Popup>
          </ShowComponent>
          {status && (
            <div className="alert alert-danger mt-3 mb-0">
              VAT deleted successfully
            </div>
          )}
          <ShowComponent condition={showpopup}>
            <Popup
              showpopup={showpopup}
              setShowPopUp={setShowPopUp}
              ClassName="modal-md"
            >
              {itemToDelete && (
                <div className="row text-center mt-4">
                  <p className="col-8">
                    Are you sure you want to delete this VAT?{" "}
                  </p>
                  <Button
                    className="btn btn-danger bg-red  text-white p-2 mt-2 rounded-md mb-5"
                    onClick={deleteConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn btn-success bg-green  text-white p-2 mt-2 rounded-md mb-5 ml-2"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <Table data={filterdData} loading={loading} actions={actions} />
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default AllTaxes;
