import React, { useState, useEffect } from "react";
import Table from "Components/Common/GeneralTable";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import { toast } from "react-toastify";
import CompanyDocumentTypesApi from "api/hrmapi/companyDocumentTypes";
import UpdateCompanyDocumentTypes from "./UpdateCompanyDocumentTypes";
function AllCompanyDocumentTypes({ fetchCompanyDocumentTypes, setFetchCompanyDocumentTypes }) {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupId, setPopupId] = useState("");
  const [upDateId, setUpDateId] = useState("");

  const handleDeleteClick = (itemToDelete) => {
    CompanyDocumentTypesApi.deleteCompanyDocumentTypesApi(itemToDelete.id)
      .then((response) => {
        const newData = dataToShow.filter(
          (item) => item.id !== itemToDelete.id
        );
        setDataToShow(newData);
        const Message = response
          ? "Record Deleted Successfully From List"
          : "An error occurred";
        toast.success(`${Message}`);
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
      });
  };
  const handleUpdateClick = (itemId) => {
    setPopupId("updateCompanyDocumentTypes");
    setUpDateId(itemId);
  };
  const actions = [
    {
      name: "Edit",
      icon: "fas fa-edit",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      color: "red",
      icon: "fas fa-trash-alt",
      onClick: handleDeleteClick,
    },
  ];
  useEffect(() => {
    CompanyDocumentTypesApi.allCompanyDocumentTypesApi()
      .then((res) => {
        const modifiedData = res.data.map((item) => ({
          id: item.id,
          name: item.name,
          required: item.is_mandatory,
          expiry_date: item.has_expiry_date,
          document_no: item.has_document,
          has_image: item.has_image,
        }));
        setDataToShow(modifiedData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, [fetchCompanyDocumentTypes]);
  return (
    <>
      <ShowComponent condition={popupId === "updateCompanyDocumentTypes"}>
        <Popup setPopup={() => setPopupId("")} className="w-[50%]">
          <UpdateCompanyDocumentTypes
            setPopupId={setPopupId}
            upDateId={upDateId}
            setFetchCompanyDocumentTypes={setFetchCompanyDocumentTypes}
          />
        </Popup>
      </ShowComponent>

      <Table data={dataToShow} loading={loading} actions={actions} />
    </>
  );
}

export default AllCompanyDocumentTypes;
