import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import TaxAPI from "api/posapi/tax";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";

function TaxDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("");
  const showTextApi = () => {
    setLoading(true);
    TaxAPI.showTaxAPI(params)
      .then((response) => {
        setDataToShow([response.data]);
        setActive(response.data.active);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    showTextApi();
  }, []);
  const handleDetailsClick = (id) => {
    setLoading(true)
    TaxAPI.updateTaxAPI(id)
      .then((response) => {
        showTextApi();
        // setDataToShow([response.data]);
        // setActive(response.data.active);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const actions = [
    {
      name: "toggle",
      icon: active === true ? "fas fa-toggle-on" : "fas fa-toggle-off",
      onClick: (item) => handleDetailsClick(item.id),
    },
  ];

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-taxes");
  };

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
      <PagesWrapper>
      <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
            VAT Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
        <Table data={dataToShow} loading={loading} actions={actions} />
      </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default TaxDetails;
