import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "./Common/GeneralTable";
import PagesWrapper from "./Common/Pages/PagesWrapper";
import FilterElement from "./Common/FilterElement";
import DateFilter from "./Common/DatePicker";
import GernalSelect from "./Common/GeneralSelectDropDown";
import Pagination from "./Common/Pagination";
import ActiveInactiveButton from "./Common/ActiveInactiveButton";
import FormatDate from "./Common/FormatDate";
import UserApi from "api/adminapi/user";
import AppWrapper from "./Routes/AppWrapper";
import AdminSideBar from "./Common/AdminSideBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "./Common/Pages/Popup";
import ShowComponent from "./ShowComponent";
import CreateAdmin from "./CreateAdmin";

function AllAdmin() {
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.currentUser?.role_id);
  const [tabledata, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });

  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    emailPredicate: "cont",
    filterEmail: "",
    usernamePredicate: "cont",
    filterUsername: "",
    userRolePredicate: "cont",
    filterUserRole: "",
    startDate: "",
    endDate: "",
  });
  const handleSendClick = (itemId) => {
    UserApi.resendInvitationAPI(itemId)
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: date,
    }));
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  const fetchData = () => {
    setLoading(true);
    const {
      idPredicate,
      filterId,
      emailPredicate,
      filterEmail,
      usernamePredicate,
      filterUsername,
      userRolePredicate,
      filterUserRole,
      startDate,
      endDate,
    } = filters;
    const formattedStartDate = startDate ? FormatDate(startDate) : "";
    const formattedEndDate = endDate ? FormatDate(endDate) : "";
    UserApi.allUsers(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      encodeURIComponent(filterEmail),
      emailPredicate,
      usernamePredicate,
      filterUsername,
      userRolePredicate,
      filterUserRole,
      formattedStartDate,
      formattedEndDate
    )
      .then((response) => {
        const filterData = (response?.data?.users).map((item) => {
          const {
            id,
            email,
            username,
            invitation_sent_at,
            invitation_accepted_at,
            invitation_accepted,
            created_at,
            ...rest
          } = item;
          const username_email = item.email || item.username;
          return { id, username_email, ...rest };
        });
        setTableData(filterData);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, [pageNumber, filterPerpage, filter, filters, role]);

  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Email",
      predicate: filters.emailPredicate,
      filterValue: filters.filterEmail,
      changePredicate: (value) => handleFilterChange("emailPredicate", value),
      changeFilter: (value) => handleFilterChange("filterEmail", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Username",
      predicate: filters.usernamePredicate,
      filterValue: filters.filterUsername,
      changePredicate: (value) =>
        handleFilterChange("usernamePredicate", value),
      changeFilter: (value) => handleFilterChange("filterUsername", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "User Role",
      predicate: filters.userRolePredicate,
      filterValue: filters.filterUserRole,
      changePredicate: (value) =>
        handleFilterChange("userRolePredicate", value),
      changeFilter: (value) => handleFilterChange("filterUserRole", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Start Date",
      selectedDate: filters.startDate,
      onDateChange: handleStartDateChange,
    },
    {
      label: "End Date",
      selectedDate: filters.endDate,
      onDateChange: handleEndDateChange,
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];
  const handleUpdateClick = (itemId) => {
    navigate(`/user/details/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "fas fa-edit",
      onClick: (item) => handleUpdateClick(item.id),
    },
  ];
  return (
    <AppWrapper sideBar={<AdminSideBar/>}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24 font-semibold">
            All Users
          </div>
          <div>
            <button
              className="btn-primary text-center px-8"
              onClick={() => setPopupId("createUser")}
            >
              Create User
            </button>
          </div>
        </div>
        <ShowComponent condition={popupId === "createUser"}>
          <Popup setPopup={() => setPopupId("")} className="w-[444px]">
            <CreateAdmin contentOnly={true}
             setPopupId={setPopupId} 
             fetchData={fetchData}
             />
          </Popup>
        </ShowComponent>
        <div className="w-[18%] mt-2  flex justify-end text-end">
          <button
            className="w-full flex justify-around items-center bg-white rounded p-3"
            onClick={() => setShowFilters(!showFilters)}
          >
            <div className="w-[8%] ">
              <img src="./icons/filter.png" alt="image" className="mx-auto" />
            </div>
            <div>Advanced Filters</div>
          </button>
        </div>
        {showFilters && (
          <div className="card mt-2">
            <div className="card-body">
              <div className="flex flex-wrap gap-3">
                {filterElements.map((obj, index) => {
                  if (obj.selectedDate !== undefined) {
                    return (
                      <DateFilter
                        key={index}
                        label={obj.label}
                        selectedDate={obj.selectedDate}
                        onDateChange={obj.onDateChange}
                      />
                    );
                  } else if (role === 1 && obj.label === "User Role") {
                    return null;
                  } else {
                    return (
                      <FilterElement
                        key={index}
                        label={obj.label}
                        predicate={obj.predicate}
                        filterValue={obj.filterValue}
                        changePredicate={obj.changePredicate}
                        changeFilter={obj.changeFilter}
                        options={obj.options}
                      />
                    );
                  }
                })}
                {selectPerpage.map((Item, index) => (
                  <GernalSelect
                    key={index}
                    title={Item.title}
                    value={Item.value}
                    onChange={Item.onChange}
                    options={Item.options}
                  />
                ))}
                <ActiveInactiveButton filter={filter} setFilter={setFilter} />
              </div>
            </div>
          </div>
        )}
        <Table
          data={tabledata}
          loading={loading}
          startValue={paginationValue.startValue}
          onSendClick={handleSendClick}
          actions={actions}
        />
          {tabledata.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
      </PagesWrapper>
    </AppWrapper>
  );
}
export default AllAdmin;
