import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "Components/Common/GeneralTable";
import OrderApi from "api/posapi/order";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import FormatDate from "Components/Common/FormatDate";
import Button from "Components/Common/Button";
import ShowComponent from "Components/ShowComponent";

function SaleDetail() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [customerInfo, setCustomerInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [salesManInfo, setSalesmanInfo] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [invoiceCreatedAt, setInvoiceCreatedAt] = useState("");
  const [invoicePayment, setInvoicePayment] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [totalTax, setTotalTax] = useState("")
  const [discount, setTotalDiscount] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [aprovalCode, setAprovalCode] = useState("");

  useEffect(() => {
    setLoading(true);
    OrderApi.viewOrderAPI(params)
      .then((response) => {
        setAprovalCode(response.data.sale?.sale_received_amounts[1]?.approval_code)
        const filterData = response.data.sale_items.map((item) => {
          const { created_at, ...filterData } = item;
          return filterData;
        });
        setData(filterData);
        setDataToShow([response?.data?.sale]);
        setCustomerInfo(response?.data?.sale.customer);
        setInvoiceId(response?.data?.sale?.invoice_no);
        setInvoiceStatus(response?.data?.sale?.status);
        setInvoiceCreatedAt(response?.data?.sale?.created_at);
        setInvoicePayment(response?.data?.sale?.payment_type);
        setTotalPrice(response?.data?.sale?.total_price);
        setTotalDiscount(response?.data?.sale?.discount);
        setTotalTax(response?.data?.sale?.tax)
        setSalesmanInfo(response?.data?.sale?.sale_created_by);
        setPhoneInfo(response?.data?.sale?.phone_no);
        setBranchName(response?.data?.sale?.gulehri);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);
  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/sales");
  };

  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
        <div className="flex justify-between my-4">
            <div className="flex items-center text-24 font-semibold">
              Sale Detail
            </div>
            <div className="w-[8%]">
              <Button
                className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
                onClick={handleBack}
              >
                Back
              </Button>
            </div>
          </div>
          <div class="flex flex-wrap gap-6">
            <div class="border border-box h-[254px] w-[330px] rounded-2xl bg-white py-3">
              <p class="text-[#374957] bold-lg indent-4 text-base font-bold py-7">
                Customer Information
              </p>
              <ul>
                <div className="flex flex-wrap px-2 indent-4">
                  <div className="bg-[#287DFD] rounded-2xl w-[25px] h-[25px] ">
                    <img
                      className="py-1 ml-1  h-7 "
                      src="/icons/fi-rr-user.svg"
                    />
                  </div>
                  <li className="text-[#374957] text-sm mt-2">
                    {customerInfo}
                  </li>
                </div>
                <div className="flex flex-wrap px-2 indent-4 mt-2">
                  <div className="bg-[#287DFD] rounded-2xl w-[25px] h-[25px] ">
                    <img
                      className="py-1   h-7 "
                      src="/icons/fi-rr-phone-call.svg"
                    />
                  </div>
                  <li className="text-[#374957] text-sm indent-4 mt-2">
                    {phoneInfo}
                  </li>
                </div>
              </ul>
            </div>
            <div class="border border-box h-[254px] w-[330px] rounded-2xl float-center bg-white py-3">
              <p class="text-[#374957] indent-4 text-base font-bold py-7">
                Invoice Information
              </p>
              <div>
                <p className="text-[#374957] text-sm indent-4">
                  <b className="text-[#3491d8]">Invoice No :</b> {invoiceId}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-[#374957] text-sm indent-4 ">
                  <b className="text-[#3491d8]">Status :</b> {capitalizeFirstLetter(invoiceStatus)}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-[#374957] text-sm indent-4 ">
                  <b className="text-[#3491d8]">Created At :</b>{" "}
                  {FormatDate(invoiceCreatedAt)}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-[#374957] text-sm indent-4 ">
                  <b className="text-[#3491d8]">Payment Method :</b>{" "}
                  {invoicePayment}
                </p>{" "}
              </div>
              <ShowComponent condition={aprovalCode}>
              <div className="mt-2">
                <p className="text-[#374957] text-sm indent-4 ">
                  <b className="text-[#3491d8]">Auth Code :</b>{" "}
                  {aprovalCode}
                </p>{" "}
              </div>
              </ShowComponent>
            </div>
            <div class="border border-box h-[254px] w-[330px] rounded-2xl float-right  bg-white py-3">
              <p class="text-[#374957] bold-lg indent-4 text-base font-bold py-7">
                Salesman Information
              </p>
              <ul>
                <div className="flex flex-wrap px-2 indent-4">
                  <div className="bg-[#287DFD] rounded-2xl w-[25px] h-[25px] ">
                    <img
                      className="py-1 ml-1  h-7 "
                      src="/icons/fi-rr-user.svg"
                    />
                  </div>
                  <li className="text-[#374957] text-sm mt-2">
                    {salesManInfo}
                  </li>
                </div>
                <div className="flex flex-wrap px-2 indent-4 mt-2">
                  <div className="bg-[#287DFD] rounded-2xl w-[25px] h-[25px] ">
                    <img
                      className="py-1 ml-1  h-7 "
                      src="/icons/fi-rr-marker.svg"
                    />
                  </div>
                  <li className="text-[#374957] text-sm indent-4 mt-2">
                    {branchName}
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <div className="bg-white border border-box  mt-2 pt-3">
            <div className=" w-auto h-[63px] bg-white ">
              <h1 className="text-[#374957] font-bold indent-2 py-4 text-xl">
                Order Summary
              </h1>
            </div>
            <div className="flex-shrink-0">
              <Table data={data} loading={loading} />
            </div>
          </div>
          <div className="w-full flex justify-end my-3">
            <div class="h-[200px] w-[50%] rounded-2xl ml-4 bg-white">
              <div className="flex flex-nowrap gap-[18rem] mt-3">
                <p className="w-[80%] text-[#374957] text-sm indent-4">
                  <b>Total Discount</b>
                </p>
                <p className="w-[20%] text-[#374957] text-sm">{discount}</p>
              </div>
              <div className="flex flex-nowrap gap-[18rem] mt-8">
                <p className="w-[80%] text-[#374957] text-sm indent-4">
                  <b>Total Tax</b>
                </p>
                <p className="w-[20%] text-[#374957] text-sm">{totalTax}</p>
              </div>
              <div className="flex flex-nowrap gap-[18rem] mt-8">
                <p className="w-[80%] text-[#374957] text-sm indent-4">
                  <b>Grand Total </b>
                </p>
                <p className="w-[20%] text-[#374957] text-sm">{totalPrice}</p>
              </div>
            </div>
          </div>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default SaleDetail;
