import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function EndDayReport({
  showTime,
  selectedSalesman,
  selectedBranch,
  endDaySaleReport,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="w-1/3 flex justify-center">
          <img
            src="/icons/noimg.png"
            alt="No Image Found"
            className="w-[370px] h-[150px]"
          />
        </div>
        <div className="w-1/3 text-center font-bold mb-5">End Day Report</div>
        <div className="w-1/3 text-10 text-center mb-4">
          {endDaySaleReport
            ? FormatDate(endDaySaleReport?.session_created_at)
            : ""}
        </div>
        <div className="w-1/3 mx-2 border-1 border-black m-3 p-3">
          <div className="flex w-full">
            <div className="font-bold w-[60%]">OP #: </div>
            <div className="w-[40%]">253</div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]">Branch #: </div>
            <div className="w-[40%]">
              1 (ID: {selectedBranch ? selectedBranch?.id : ""})
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]">Salesman: </div>
            <div className="w-[40%]">
              {selectedSalesman?.name
                ? capitalizeFirstLetter(selectedSalesman?.name)
                : ""}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]">Print Date: </div>
            <div className="w-[40%]">{FormatDate(new Date())}</div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]">Print Time: </div>
            <div className="w-[40%]">{showTime}</div>
          </div>
        </div>
        <div className="w-1/3 mx-2 border-1 border-black m-3 p-3">
          <div className="font-bold w-[50%] mb-5">Sales Activity</div>
          <div className="flex w-full">
            <div className="w-[60%]">Cash: </div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_cash_received
                ? endDaySaleReport?.total_cash_received
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Visa Cash:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_visa_received
                ? endDaySaleReport?.total_visa_received
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Return Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.return ? endDaySaleReport?.return : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Coupon Discount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.coupon_discount
                ? endDaySaleReport?.coupon_discount
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Discount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_discount
                ? endDaySaleReport?.total_discount
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Tax:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_tax
                ? endDaySaleReport?.total_tax
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="w-[60%]">Rewards Amount:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_reward
                ? endDaySaleReport?.total_reward
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-5">
            <div className="font-bold w-[60%]">Total Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.total_sale_price
                ? endDaySaleReport?.total_sale_price
                : "0.0"}
            </div>
          </div>
          <div className="flex w-full mt-3">
            <div className="font-bold w-[60%]"> Net Sales:</div>
            <div className="w-[40%]">
              {endDaySaleReport?.net_sale ? endDaySaleReport?.net_sale : "0.0"}
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default EndDayReport;
