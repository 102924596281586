import PagesWrapper from "./Common/Pages/PagesWrapper";
import AppWrapper from "./Routes/AppWrapper";
import AdminSideBar from "./Common/AdminSideBar";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import UserApi from "api/adminapi/user";
import TableDetails from "./Common/TableDetails";
import Button from "./Common/Button";

function UserDetails() {
  let params = useParams();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    UserApi.viewUser(params)
      .then((response) => {
        const userData = response?.data?.user;
        const displayName = userData.email ? userData.email : userData.username;
        const filteredData = {
          id: userData?.id,
          username_email: displayName,
          role: userData?.role,
          company_name: userData?.company_name,
          default_location: userData?.location_address,
          created_at: userData?.created_at

        };
        setDataToShow(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-user");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            User Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="w-1/2">
          <TableDetails data={dataToShow} loading={loading} header={false} />
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default UserDetails;
