import React from "react";
import Chart from "react-apexcharts";

const GeneralChart = ({ options, series, type }) => {
  const chartOptions = {
    ...options,
    chart: {
      ...options.chart,
      type,
    },
  };
  return <Chart options={chartOptions} series={series} type={type} />;
};

export default GeneralChart;
