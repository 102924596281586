import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import ShowComponent from "Components/ShowComponent";

function SalesInvoiceVoucherJV({
  showTime,
  invoiceNo,
  reportData,
  reportSummary,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center my-5">
          Transaction Listing
        </div>
        <div className="text-lg font-bold text-center mb-5">
          Posted Transaction
        </div>
        <div className="text-22 font-bold text-center mb-10">{invoiceNo}</div>
        <div className="flex w-full justify-between px-14">
          <div className="w-1/4">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Date:</div>
              <div className="w-[50%]">{"10/05/2024"}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Description:</div>
              <div className="w-[50%]">{"Sales Invoice Voucher"}</div>
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>

        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full">
              <th className="w-[15%] px-2 py-4">Account Code</th>
              <th className="w-[20%] pr-2 py-4">Account Name</th>
              <th className="w-[40%] pr-2 py-4">Description</th>
              <th className="w-[10%] pr-2 py-4">Debit</th>
              <th className="w-[15%] pr-2 py-4">Credit</th>
            </tr>
          </thead>

          <tbody className="w-full pt-3">
            {reportData && reportData?.length > 0 ? (
              <>
                {reportData?.map((item, index) => (
                  <tr
                    key={index}
                    className="text-center border-t-1 border-black"
                  >
                    {/* <tr className="text-center border-t-1 border-black"> */}
                    <td class="w-[15%] pr-2 py-4">{item?.code}</td>
                    <td class="w-[20%] pr-2 py-4">{item?.name}</td>
                    <td class="w-[40%] pr-2 py-4">{item?.description}</td>
                    <ShowComponent condition={item?.entry_type === "debit"}>
                      <td class="w-[10%] pr-2 py-4">{item?.value}</td>
                      <td class="w-[15%] pr-2 py-4">{"0.00"}</td>
                    </ShowComponent>
                    <ShowComponent condition={item?.entry_type === "credit"}>
                      <td class="w-[10%] pr-2 py-4">{"0.00"}</td>
                      <td class="w-[15%] pr-2 py-4">{item?.value}</td>
                    </ShowComponent>
                  </tr>
                ))}

                <tr className="text-center border-y-1 border-black">
                  <td class="w-[15%] pr-2 py-4"></td>
                  <td class="w-[20%] pr-2 py-4"></td>
                  <td
                    class="w-[40%] pr-2 py-4 font-bold"
                    style={{ textAlign: "end" }}
                  >
                    Grand Total:
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.debit}
                  </td>
                  <td class="w-[15%] pr-2 py-4 font-bold">
                    {reportSummary?.credit}
                  </td>
                </tr>
              </>
            ) : (
              <tr className="w-full pt-4 text-center border-t-1 border-black">
                <td colSpan="10" className="p-4 font-bold">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex w-[90%] pr-10 ml-12 mt-20">
          <div className="w-[35%] text-lg flex justify-center mr-20">
            <span className="text-lg font-bold mr-3">Prepared by: </span>
            Administrator
          </div>
          <div className="w-[35%] text-lg font-bold flex justify-center">
            Checked by
          </div>
          <div className="w-[35%] text-lg font-bold flex justify-center ml-20">
            Verified by
          </div>
        </div>
        <div className="flex w-[90%] pr-10 ml-12 mt-20">
          <div className="w-[35%] border-b-1 border-black mr-20"></div>
          <div className="w-[35%] border-b-1 border-black"></div>
          <div className="w-[35%] border-b-1 border-black ml-20"></div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesInvoiceVoucherJV;
