import React, { useEffect, useState } from "react";
import AdminSideBar from "Components/Common/AdminSideBar";
import AppWrapper from "Components/Routes/AppWrapper";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import OrderApi from "api/posapi/order";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";
import FormateDateTime from "Components/Common/FormateDateTime";

function CashierSession() {
  const [tableData, setTableData] = useState([]);

  const allData = () => {
    OrderApi.allSessions()
      .then((response) => {
        setTableData(response?.data?.cashier_sessions);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    allData();
  }, []);

  const handleReOpen = (userId) => {
    OrderApi.ReOpenCashier({
      user_id: userId,
    })
      .then((response) => {
        if (response?.data?.message) {
          toast.success(response.data.message);
          allData();
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="w-full flex mb-4 justify-between items-center pt-4">
          <div className="text-24 font-semibold">Cashier Session</div>
        </div>
        <table className="min-w-full my-10 bg-white rounded-2xl">
          <thead>
            <tr class="text-center">
              <th class="pb-6 pl-5 pt-10 w-[10%] text-blue align-middle">ID</th>
              <th class="pb-6 pt-10 w-[25%] text-blue align-middle">
                Start Time
              </th>
              <th class="pb-6 pt-10 w-[25%] text-blue align-middle">
                End Time
              </th>
              <th class="pb-6 pt-10 w-[10%] text-blue align-middle">User ID</th>
              <th class="pb-6 pt-10 w-[15%] text-blue align-middle">Status</th>
              <th class="pb-6 pt-10 mr-10 w-[15%] font-extrabold align-middle text-blue">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.length === 0 ? (
              <tr>
                <td colSpan={9} className="pb-4 text-center">
                  No Data Available
                </td>
              </tr>
            ) : (
              tableData.map((data, index) => (
                <tr key={index} className="text-center">
                  <td className="py-4 pl-5 w-[10%] align-middle">{data.id}</td>
                  <td className="py-4 w-[25%] align-middle">
                    {FormateDateTime(data.start_time)}
                  </td>
                  <td className="py-4 w-[25%] align-middle">
                    {FormateDateTime(data.end_time)}
                  </td>
                  <td className="py-4 w-[10%] align-middle">{data.user_id}</td>
                  <td className="py-4 w-[15%] align-middle">
                    {capitalizeFirstLetter(data.status)}
                  </td>
                  <td className="w-[15%]  align-middle">
                  <div className="flex justify-center">
                    <Button
                      className="h-[40px] w-[100px] flex items-center justify-center"
                      onClick={() => handleReOpen(data.user_id)}
                    >
                      {data.status === "open" ? "Open" : "Re-Open"}
                    </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CashierSession;
