import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomerApi from "api/customerapi/customer";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Table from "Components/Common/GeneralTable";
import Pagination from "Components/Common/Pagination";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import FilterElement from "Components/Common/FilterElement";
import GernalSelect from "Components/Common/GeneralSelectDropDown";
import Button from "Components/Common/Button";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import CreateCustomer from "./CreateCustomer";
import LocationsAPI from "api/location/location";
import { toast } from "react-toastify";

function AllCustomer() {
  const location = useLocation();
  const isAccounts = location.pathname.startsWith("/accounts-customers");
  const [dataToShow, setDataToShow] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showpopup, setShowPopUp] = useState(false);
  const [customerTypePredicate, setCustomerTypePredicate] = useState("");
  const [filterCustomerType, setFilterCustomerType] = useState("");
  const [popupId, setPopupId] = useState("");
  const [customerMode, setCustomerMode] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const [customerFilters, setCustomerFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    customerNamePredicate: "eq",
    filterCustomerName: "",
    phoneNumberPredicate: "eq",
    filterPhoneNumber: "",
  });

  const handleCustomerFilterChange = (filterName, filterValue) => {
    setCustomerFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  useEffect(() => {
    LocationsAPI.allLocationsAPI()
      .then((response) => {
        setLocations(response?.data?.locations);
      })
      .catch((error) => {
        if (error) {
          toast.error("Error fetching locations");
        }
      });
  }, []);

  const fetchCustomers = () => {
    setLoading(true);
    const {
      idPredicate,
      filterId,
      customerNamePredicate,
      filterCustomerName,
      phoneNumberPredicate,
      filterPhoneNumber,
    } = customerFilters;

    CustomerApi.AllCustomer(
      pageNumber,
      filterPerpage,
      selectedLocation,
      filter,
      idPredicate,
      filterId,
      customerNamePredicate,
      filterCustomerName,
      phoneNumberPredicate,
      filterPhoneNumber,
      customerTypePredicate,
      filterCustomerType
    )
      .then((response) => {
        const filterData = response.data.customers.map((item) => {
          const {
            address,
            age_bracket,
            age_group,
            birthdate,
            birthday,
            country,
            customer_type,
            gender,
            nationality,
            ...filterData
          } = item;
          return filterData;
        });
        setDataToShow(filterData);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, [
    pageNumber,
    filterPerpage,
    filter,
    customerFilters,
    customerTypePredicate,
    filterCustomerType,
    selectedLocation
  ]);

  const navigate = useNavigate();
  const handleDetailsClick = (itemId) => {
    if (isAccounts) {
      navigate(`/accounts/customer/details/${itemId}`);
    } else {
      navigate(`/pos/customer/details/${itemId}`);
    }
  };
  const handleUpdateClick = (itemId) => {
    if (isAccounts) {
      navigate(`/accounts/customer/edit/${itemId}`);
    } else {
      navigate(`/pos/customer/edit/${itemId}`);
    }
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setShowPopUp(true);
  };
  const deleteConfirmed = () => {
    setShowPopUp(false);
    if (itemToDelete) {
      CustomerApi.DeleteCustomer(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setDataToShow(newData);
          setStatus(response.status);
          setTimeout(() => {
            setStatus("");
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
      setItemToDelete(null);
    }
  };
  const cancelDelete = () => {
    setShowPopUp(false);
    setItemToDelete(null);
  };

  const customerActions = [
    {
      name: "View",
      icon: "fas fa-eye",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "fas fa-edit",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      icon: "fas fa-trash-alt",
      onClick: handleDeleteClick,
    },
  ];

  const filterElements = [
    {
      label: "ID",
      predicate: customerFilters.idPredicate,
      filterValue: customerFilters.filterId,
      changePredicate: (value) =>
        handleCustomerFilterChange("idPredicate", value),
      changeFilter: (value) => handleCustomerFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Name",
      predicate: customerFilters.customerNamePredicate,
      filterValue: customerFilters.filterCustomerName,
      changePredicate: (value) =>
        handleCustomerFilterChange("customerNamePredicate", value),
      changeFilter: (value) =>
        handleCustomerFilterChange("filterCustomerName", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Phone Number",
      predicate: customerFilters.phoneNumberPredicate,
      filterValue: customerFilters.filterPhoneNumber,
      changePredicate: (value) =>
        handleCustomerFilterChange("phoneNumberPredicate", value),
      changeFilter: (value) =>
        handleCustomerFilterChange("filterPhoneNumber", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Customer Type",
      value: customerTypePredicate,
      onChange: (e) => setCustomerTypePredicate(e.target.value),
      options: [{ label: "=", value: "eq" }],
      changeFilter: (value) => setFilterCustomerType(value),
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];

  const handleCustomerCreated = (customerId) => {
    setPopupId("");
  };

  return (
    <>
      <AppWrapper sideBar={isAccounts ? <AccountsSidebar /> : <PosSidebar />}>
        <PagesWrapper>
        <div className="w-full flex my-4 justify-between">
            <div className="flex text-24 font-semibold items-center justify-center">
              All Customers
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("customer")}
              >
                Create Customer
              </button>
            </div>
          </div>
          <ShowComponent condition={popupId === "customer"}>
            <Popup setPopup={() => setPopupId("")} className="w-[60%]">
              <CreateCustomer
                onCustomerCreated={handleCustomerCreated}
                contentOnly={true}
                setCustomerMode={setCustomerMode}
                setPopupId={setPopupId}
                fetchCustomers={fetchCustomers}
              />
            </Popup>
          </ShowComponent>
          <div className="flex justify-between mt-8 w-full">
            <div className="w-[30%] ml-4">
              <label htmlFor="branchName">Branch Name</label>
              <select
                className="form-control mt-2"
                required
                value={selectedLocation}
                onChange={(e) =>
                  setSelectedLocation(e.target.value)
                }
              >
                <option value="">Select Branch</option>
                {locations?.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.address}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                className="p-2 flex justify-around items-center bg-white rounded-md"
                onClick={() => setShowFilters(!showFilters)}
                type="button"
              >
                <div className="w-[8%]">
                  <img
                    src="./icons/fi-rr-settings-sliders.svg"
                    alt="image"
                    className="m-2"
                  />
                </div>
                <div className="m-2">Advanced Filters</div>
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-4">
                  {filterElements.map((filterElement, index) => (
                    <FilterElement
                      key={index}
                      label={filterElement.label}
                      predicate={filterElement.predicate}
                      filterValue={filterElement.filterValue}
                      changePredicate={filterElement.changePredicate}
                      changeFilter={filterElement.changeFilter}
                      options={filterElement.options}
                    />
                  ))}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelect
                      className="form-group col-md-2"
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          {status && (
            <div className="alert alert-danger mt-3 mb-0">
              customer deleted successfully
            </div>
          )}
          <ShowComponent condition={showpopup}>
            <Popup
              showpopup={showpopup}
              setShowPopUp={setShowPopUp}
              ClassName="modal-md"
            >
              {itemToDelete && (
                <div className="row text-center mt-4">
                  <p className="col-8">
                    Are you sure you want to delete this customer?
                  </p>
                  <Button
                    className="btn btn-danger bg-red  text-white p-2 mt-2 rounded-md mb-5"
                    onClick={deleteConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn btn-success bg-green  text-white p-2 mt-2 rounded-md mb-5 ml-2"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <Table
            data={dataToShow}
            loading={loading}
            actions={customerActions}
            startValue={paginationValue.startValue}
          />
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default AllCustomer;
