import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function DailySaleReport({
  startDate,
  endDate,
  showTime,
  selectedBranch,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center mt-5 mb-10">
          Daily Sales Report
        </div>
        <div className="flex w-full justify-between px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">From Date:</div>
              <div className="w-[50%]">{FormatDate(startDate)}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">To Date:</div>
              <div className="w-[50%]">{FormatDate(endDate)}</div>
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full text-center">
              <th className="w-[12%] px-2 py-4">Date</th>
              <th className="w-[10%] pr-2 py-4">Cash Amount</th>
              <th className="w-[10%] pr-2 py-4">Visa Amount</th>
              <th className="w-[10%] pr-2 py-4">Total Price</th>
              <th className="w-[10%] pr-2 py-4">Discount & Coupon</th>
              <th className="w-[10%] pr-2 py-4">Tax Amount</th>
              <th className="w-[10%] pr-2 py-4">Bonus Amount</th>
              <th className="w-[9%] pr-2 py-4">Net Amount</th>
              <th className="w-[10%] pr-2 py-4">Cost Amount</th>
              <th className="w-[9%] pr-2 py-4">Profit</th>
            </tr>
          </thead>
          <tr>
            <td colSpan="10" className="p-4 font-bold">
              Branch:{" "}
              {selectedBranch?.name
                ? capitalizeFirstLetter(selectedBranch?.name)
                : ""}
            </td>
          </tr>
          <tbody className="w-full pt-3">
            {reportData && reportData.length > 0 ? (
              <>
                {reportData?.map((item, index) => (
                  <tr
                    key={index}
                    className="text-center border-t-1 border-black"
                  >
                    <td class="w-[12%] px-2 py-4">
                      {FormatDate(item?.updated_at)}
                    </td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_cash_amount}</td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_visa_amount}</td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_total_price}</td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_discount}</td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_tax}</td>
                    <td class="w-[10%] pr-2 py-4">
                      {item?.total_salesman_reward}
                    </td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_net_sale}</td>
                    <td class="w-[9%] pr-2 py-4">{item?.total_cost_amount}</td>
                    <td class="w-[10%] pr-2 py-4">{item?.total_profit}</td>
                  </tr>
                ))}
                <tr className="w-full pt-4 text-center border-t-1 border-black">
                  <td class="w-[12%] px-2 py-4 font-bold"></td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_cash_amount}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_visa_amount}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_total_price}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_discount}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_tax}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_salesman_reward}
                  </td>
                  <td class="w-[9%] pr-2 py-4 font-bold">
                    {reportSummary?.total_net_sale}
                  </td>
                  <td class="w-[10%] pr-2 py-4 font-bold">
                    {reportSummary?.total_cost_amount}
                  </td>
                  <td class="w-[9%] pr-2 py-4 font-bold">
                    {reportSummary?.total_profit}
                  </td>
                </tr>
              </>
            ) : (
              <tr className="w-full pt-4 text-center border-t-1 border-black">
                <td colSpan="10" className="p-4 font-bold">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default DailySaleReport;
