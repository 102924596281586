import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import DiscountAPI from "api/posapi/discount";
import FormatDate from "Components/Common/FormatDate";
import PosSidebar from "Components/Common/PosSidebar";
import CompleteTable from "Components/Common/CompleteTable";
import AppWrapper from "Components/Routes/AppWrapper";
import GeneralTable from "../Common/GeneralTable";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";

function AllDiscounts() {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [status, setStatus] = useState("");
  const [showpopup, setShowPopUp] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState({ item_list: [] });
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const availableDiscountTypes = {
    product: (id) =>
      DiscountAPI.discountProducts(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    category: (id) =>
      DiscountAPI.discountCategory(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    coupon_sale: (id) =>
      DiscountAPI.discountCoupenSale(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    coupon_product: (id) =>
      DiscountAPI.discountCoupenProduct(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    coupon_category: (id) =>
      DiscountAPI.discountCoupenCategory(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    offer_get_y_item: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    offer_get_y_amount: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    offer_get_y_category: (id) =>
      DiscountAPI.discountOffers(id)
        .then((res) => setSelectedDiscount(res.data))
        .catch((res) => console.log(res.data)),
    // access_code: (id) => DiscountAPI.discountCoupenCategory(id).then(res => setSelectedDiscount(res.data)).catch(res => console.log(res.data)),
    access_code: (id) => console.log("access code discount"),
  };
  const handleDetailsClick = (id, discount_type) => {
    navigate(`/discounts/${discount_type}/${id}`);
    // availableDiscountTypes[discount_type](id)
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopupId("delete");
  };
  const handleUpdateClick = (item) => {
    setStatus(item.active);
    setItemToDelete(item);
    setPopupId("Inactive");
  };
  const cancelDelete = () => {
    setPopupId("");
    setItemToDelete(null);
  };
  const deleteConfirmed = () => {
    if (itemToDelete) {
      DiscountAPI.DeleteDiscount(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setPopupId();
          setDataToShow(newData);
        })
        .catch((error) => console.log(error));
      setItemToDelete(null);
    }
  };
  const InactiveConfirmed = () => {
    if (itemToDelete) {
      DiscountAPI.toggleDiscount(itemToDelete.id, !status)
        .then((response) => {
          setPopupId();
          fetchDiscount();
        })
        .catch((error) => console.log(error));
      setItemToDelete(null);
    }
  };
  const handleStartDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: date,
    }));
  };
  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    titlePredicate: "eq",
    filterTitlename: "",
    valuePredicate: "eq",
    filtervalues: "",
    discountmethodPredicate: "eq",
    filterdiscountmethod: "",
    discounttypePredicate: "eq",
    filterdisdiscounttype: "",
    startDate: "",
    endDate: "",
  });
  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };
  useEffect(() => {
    fetchDiscount();
  }, [pageNumber, filterPerpage, filter, filters]);
  const fetchDiscount = () => {
    setLoading(true);
    const {
      idPredicate,
      filterId,
      titlePredicate,
      filterTitlename,
      valuePredicate,
      filtervalues,
      discountmethodPredicate,
      filterdiscountmethod,
      discounttypePredicate,
      filterdisdiscounttype,
      startDate,
      endDate,
    } = filters;
    const filterParams = {};

    if (filterId) {
      filterParams[`q[id_${idPredicate}]`] = filterId;
    }
    if (filterTitlename) {
      filterParams[`q[title_${titlePredicate}]`] = filterTitlename;
    }
    if (filtervalues) {
      filterParams[`q[value_${valuePredicate}]`] = filtervalues;
    }
    if (filterdiscountmethod) {
      filterParams[`q[discount_method_${discountmethodPredicate}]`] =
        filterdiscountmethod;
    }
    if (filterdisdiscounttype) {
      filterParams[`q[discountable_type_${discounttypePredicate}]`] =
        filterdisdiscounttype;
    }
    if (startDate) {
      filterParams[`q[created_at_gteq]`] = FormatDate(startDate);
    }
    if (endDate) {
      filterParams[`q[created_at_lteq]`] = FormatDate(endDate);
    }
    let apiURL = `page=${pageNumber}&per_page_count=${filterPerpage}`;
    if (Object.keys(filterParams).length > 0 && filter) {
      apiURL += "&" + new URLSearchParams(filterParams);
    }
    DiscountAPI.allDiscountAPI(apiURL)
      .then((response) => {
        const filterData = (response?.data?.discounts).map((item) => {
          const {type_id, discount_method, discount_type, ...filterData} = item;
          return filterData;
        })
        setDataToShow(filterData);
        setTotalPages(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setStartValue(response.data.starting_value);
        setEndValue(response.data.ending_value);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Title",
      predicate: filters.titlePredicate,
      filterValue: filters.filterTitlename,
      changePredicate: (value) => handleFilterChange("titlePredicate", value),
      changeFilter: (value) => handleFilterChange("filterTitlename", value),
      options: [{ label: "=", value: "eq" }],
    },
    {
      label: "Value",
      predicate: filters.valuePredicate,
      filterValue: filters.filtervalues,
      changePredicate: (value) => handleFilterChange("valuePredicate", value),
      changeFilter: (value) => handleFilterChange("filtervalues", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Discount Method",
      predicate: filters.discountmethodPredicate,
      filterValue: filters.filterdiscountmethod,
      changePredicate: (value) =>
        handleFilterChange("discountmethodPredicate", value),
      changeFilter: (value) =>
        handleFilterChange("filterdiscountmethod", value),
      options: [{ label: "=", value: "eq" }],
    },
    {
      label: "Discount Type",
      predicate: filters.discounttypePredicate,
      filterValue: filters.filterdisdiscounttype,
      changePredicate: (value) =>
        handleFilterChange("discounttypePredicate", value),
      changeFilter: (value) =>
        handleFilterChange("filterdisdiscounttype", value),
      options: [{ label: "=", value: "eq" }],
    },
  ];
  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      onClick: ({ discount_id, type ,id}) => handleDetailsClick(discount_id, type),
    },
    {
      name: "Toggle",
      onClick: handleUpdateClick,
    },
    {
      name: "Delete",
      icon: "fas fa-trash-alt",
      onClick: handleDeleteClick,
    },
  ];
  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];
  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <ShowComponent condition={popupId === "delete"}>
            <Popup setPopup={() => setPopupId("")} className="w-[35%]">
              {itemToDelete && (
                <div className="  mt-4">
                  <div className="text-center justify-center flex">
                    Are you sure you want to delete this Discount?
                  </div>
                  <div className="text-center justify-center flex mt-4">
                    <button
                      className="btn btn-danger bg-red  text-white p-2 mt-2 rounded-md mb-5 "
                      onClick={deleteConfirmed}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-success bg-green  text-white p-2 mt-2 rounded-md mb-5 ml-2"
                      onClick={cancelDelete}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <ShowComponent condition={popupId === "Inactive"}>
            <Popup setPopup={() => setPopupId("")} className="w-[35%]">
              {itemToDelete && (
                <div className="  mt-4">
                  <div className="text-center justify-center flex">
                    Are you sure you want to{" "}
                    {status === true ? "Inactive" : "active"} this Discount?
                  </div>
                  <div className="text-center justify-center flex mt-4">
                    <button
                      className="btn btn-danger bg-red  text-white p-2 mt-2 rounded-md mb-5"
                      onClick={InactiveConfirmed}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-success bg-green  text-white p-2 mt-2 rounded-md mb-5 ml-2"
                      onClick={cancelDelete}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <div className="w-full flex mb-4 justify-between">
            <div className="text-24 font-semibold">
              All Discount
            </div>
            <div className="flex justify-center mt-4">
            <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md "
                  onClick={() => setShowFilters(!showFilters)}
                  type="button"
                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
            </div>
          </div>
          <CompleteTable
            selectPerpage={selectPerpage}
            handleStartDateChange={handleStartDateChange}
            actions={actions}
            filterElements={filterElements}
            handleEndDateChange={handleEndDateChange}
            dataToShow={dataToShow}
            totalPages={totalPages}
            loading={loading}
            filters={filters}
            filter={filter}
            showFilters={showFilters}
            setFilter={setFilter}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalCount={totalCount}
            startValue={startValue}
            endValue={endValue}
          />
          <div></div>
          <div className="flex justify-between items-center">
            <span className="font-bold text-[18px]">
              <ShowComponent condition={selectedDiscount?.item_list?.length}>
                Discounted List
              </ShowComponent>
            </span>
            <ShowComponent
              condition={selectedDiscount?.discount_meta?.coupon_code}
            >
              <div>
                <span className="font-bold text-[18px]">Coupon Code: </span>
                <span className="font-bold text-[15px] text-[#287dfd]">
                  {selectedDiscount?.discount_meta?.coupon_code}
                </span>
              </div>
            </ShowComponent>
          </div>
          <ShowComponent condition={selectedDiscount?.item_list?.length}>
            <GeneralTable data={selectedDiscount?.item_list} />
          </ShowComponent>
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default AllDiscounts;
