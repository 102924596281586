import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import SupplierApi from "api/supplierapi/supplier";
import Table from "Components/Common/GeneralTable";
import Pagination from "Components/Common/Pagination";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import FilterElement from "Components/Common/FilterElement";
import GernalSelect from "Components/Common/GeneralSelectDropDown";
import Button from "Components/Common/Button";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import AccountsSidebar from "Components/Common/AccountsSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import CreateSupplier from "./CreateSupplier";

function AllSupplier() {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showpopup, setShowPopUp] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    supplierNamePredicate: "eq",
    filterSupplierName: "",
    phoneNumberPredicate: "eq",
    filterPhoneNumber: "",
  });

  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  const fetchSuppliers = () => {
    setLoading(true);
    const {
      idPredicate,
      filterId,
      supplierNamePredicate,
      filterSupplierName,
      phoneNumberPredicate,
      filterPhoneNumber,
    } = filters;

    SupplierApi.AllSupplier(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      supplierNamePredicate,
      filterSupplierName,
      phoneNumberPredicate,
      filterPhoneNumber
    )
      .then((response) => {
        setDataToShow(response.data.suppliers);
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSuppliers();
  }, [pageNumber, filterPerpage, filter, filters]);

  const navigate = useNavigate();

  const handleDetailsClick = (itemId) => {
    navigate(`/supplier/details/${itemId}`);
  };

  const handleUpdateClick = (itemId) => {
    navigate(`/supplier/edit/${itemId}`);
  };

  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setShowPopUp(true);
  };

  const deleteConfirmed = () => {
    setShowPopUp(false);
    if (itemToDelete) {
      SupplierApi.DeleteSupplier(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setDataToShow(newData);
          setStatus(response.status);
          setTimeout(() => {
            setStatus("");
          }, 3000);
        })
        .catch((error) => console.log(error));
      setItemToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowPopUp(false);
    setItemToDelete(null);
  };

  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "fas fa-edit",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      icon: "fas fa-trash-alt",
      onClick: handleDeleteClick,
    },
  ];

  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Name",
      predicate: filters.supplierNamePredicate,
      filterValue: filters.filterSupplierName,
      changePredicate: (value) =>
        handleFilterChange("supplierNamePredicate", value),
      changeFilter: (value) => handleFilterChange("filterSupplierName", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Phone Number",
      predicate: filters.phoneNumberPredicate,
      filterValue: filters.filterPhoneNumber,
      changePredicate: (value) =>
        handleFilterChange("phoneNumberPredicate", value),
      changeFilter: (value) => handleFilterChange("filterPhoneNumber", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
  ];

  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];

  return (
    <>
      <AppWrapper sideBar={<AccountsSidebar />}>
        <PagesWrapper>
          <div className="w-full flex my-4 justify-between">
            <div className="flex text-24 font-semibold items-center justify-center">
              All Suppliers
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("supplier")}
              >
                Create Suppliers
              </button>
            </div>
          </div>
          <div className="flex justify-end w-full">
            <div className="justify-center mt-2">
              <button
                className="w-full p-2 flex justify-around items-center bg-white rounded-md"
                onClick={() => setShowFilters(!showFilters)}
                type="button"
              >
                <div className="w-[8%]">
                  <img
                    src="./icons/fi-rr-settings-sliders.svg"
                    alt="image"
                    className="m-2"
                  />
                </div>
                <div className="m-2">Advanced Filters</div>
              </button>
            </div>
          </div>
          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-2">
                  {filterElements.map((filterElement, index) => (
                    <FilterElement
                      key={index}
                      label={filterElement.label}
                      predicate={filterElement.predicate}
                      filterValue={filterElement.filterValue}
                      changePredicate={filterElement.changePredicate}
                      changeFilter={filterElement.changeFilter}
                      options={filterElement.options}
                    />
                  ))}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelect
                      className="form-group col-md-2"
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <ShowComponent condition={popupId === "supplier"}>
            <Popup setPopup={() => setPopupId("")} className="w-[35%]">
              <CreateSupplier
                contentOnly={true}
                fetchSuppliers={fetchSuppliers}
                setPopupId={setPopupId}
              />
            </Popup>
          </ShowComponent>
          {status && (
            <div className="alert alert-danger mt-3 mb-0">
              supplier deleted successfully
            </div>
          )}
          <ShowComponent condition={showpopup}>
            <Popup
              showpopup={showpopup}
              setShowPopUp={setShowPopUp}
              ClassName="modal-md"
            >
              {itemToDelete && (
                <div className="row  mt-4">
                  <p className="col-8">
                    Are you sure you want to delete this supplier?
                  </p>
                  <Button
                    className="btn btn-danger mb-5"
                    onClick={deleteConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn btn-success mb-5 ml-2"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              )}
            </Popup>
          </ShowComponent>
          <Table
            data={dataToShow}
            loading={loading}
            actions={actions}
            startValue={paginationValue.startValue}
          />
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default AllSupplier;
