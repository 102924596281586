import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";

function UpdateCard() {
  const [cardName, setCardName] = useState("");
  const [image, setImage] = useState(null);
  const params = useParams();

  useEffect(() => {
    ConfigurationAPI.cardDetail(params.id)
      .then((response) => {
        setCardName(response?.data?.card?.name);
        setImage(response?.data?.card?.card_img);
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  }, [params.id]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpdateCard = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("card[name]", cardName);
    ConfigurationAPI.cardUpdate(params.id, formData)
      .then((response) => {
        const message = response?.data?.message;
        const error = response?.data?.error;
        if (message && error) {
          toast.warning(message + " " + error);
        } else {
          toast.success(response.data.message);
        }
        setCardName("");
        setImage("");
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-cards");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between w-full mt-10">
          <div className="flex text-2xl font-bold items-center">
            Update Card
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="border-t-2 border-blue bg-white rounded-md mt-4 min-h-[70vh]">
          <form onSubmit={handleUpdateCard}>
            <div className="flex w-full mt-4">
              <div className="w-[50%] mx-4">
                <label htmlFor="cardName">Card Name</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Enter Card Name"
                  value={cardName}
                  required
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
              {/* <div className="w-[30%] mr-4 mt-2">
                <img src={image} alt="Image" className="w-full h-14" />
              </div> */}
              <div className="w-full my-5">
                <Button type="submit">Update Card</Button>
              </div>
            </div>
          </form>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default UpdateCard;
