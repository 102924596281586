import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function InvoicesDetailsReport({
  startDate,
  endDate,
  selectedReport,
  selectedBranch,
  selectedSalesman,
  showTime,
  reportData,
  reportSummary,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center mt-5 mb-10">
          Sales Report Invoices - Details
        </div>
        <div className="flex w-full justify-between px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">From Date:</div>
              <div className="w-[50%]">{FormatDate(startDate)}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">To Date:</div>
              <div className="w-[50%]">{FormatDate(endDate)}</div>
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full text-center">
              <th className="w-[25%] px-2 py-4" style={{ textAlign: "start" }}>
                Salesman
              </th>
              <th className="w-[10%] pr-2 py-4">Invoice No</th>
              <th className="w-[15%] pr-2 py-4">Invoice Date</th>
              <th className="w-[10%] pr-2 py-4">Total Price</th>
              <th className="w-[10%] pr-2 py-4">Discount</th>
              <th className="w-[10%] pr-2 py-4">Tax</th>
              <th className="w-[10%] pr-2 py-4">Bonus</th>
              <th
                className="w-[10%] pl-2 pr-4 py-4"
                style={{ textAlign: "end" }}
              >
                Net
              </th>
            </tr>
          </thead>
          <tr>
            <td colSpan="10" className="p-4 font-bold">
              Branch:{" "}
              {selectedBranch?.name
                ? capitalizeFirstLetter(selectedBranch?.name)
                : ""}
            </td>
          </tr>
          <tbody className="w-full pt-3">
            <>
              {reportData?.map((item, index) => (
                <tr
                  key={index}
                  className="w-full text-center border-t-2 border-gray-300"
                >
                  <td
                    class="w-[25%] pr-2 py-2 pl-4"
                    style={{ textAlign: "start" }}
                  >
                    {item?.salesman}
                  </td>
                  <td class="w-[10%] pr-2 py-2">{item?.invoice_no}</td>
                  <td class="w-[15%] pr-2 py-2">
                    {item?.updated_at ? FormatDate(item?.updated_at) : ""}
                  </td>
                  <td class="w-[10%] pr-2 py-2">{item?.total_price}</td>
                  <td class="w-[10%] pr-2 py-2">{item?.discount}</td>
                  <td class="w-[10%] pr-2 py-2">{item?.tax}</td>
                  <td class="w-[10%] px-2 py-2">{item?.salesman_reward}</td>
                  <td
                    class="w-[10%] pl-2 pr-4 py-2"
                    style={{ textAlign: "end" }}
                  >
                    {item?.net_sale}
                  </td>
                </tr>
              ))}

              <tr className="w-full text-center border-t-2 border-black">
                <td class="w-[25%] px-2 py-2"></td>
                <td class="w-[10%] pr-2 py-2 font-bold"></td>
                <td class="w-[15%] pr-2 py-2 font-bold"></td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_total_price}
                </td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_discount}
                </td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_tax}
                </td>
                <td class="w-[10%] px-2 py-2 font-bold">
                  {reportSummary?.total_salesman_reward}
                </td>
                <td
                  class="w-[10%] pl-2 pr-4 py-2 font-bold"
                  style={{ textAlign: "end" }}
                >
                  {reportSummary?.total_net_sale}
                </td>
              </tr>

              <tr className="w-full text-center border-t-2 border-gray-300">
                <td class="w-[25%] px-2 py-2"></td>
                <td class="w-[10%] pr-2 py-2 font-bold"></td>
                <td class="w-[15%] pr-2 py-2 font-bold"></td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_total_price}
                </td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_discount}
                </td>
                <td class="w-[10%] pr-2 py-2 font-bold">
                  {reportSummary?.total_tax}
                </td>
                <td class="w-[10%] px-2 py-2 font-bold">
                  {reportSummary?.total_salesman_reward}
                </td>
                <td
                  class="w-[10%] pl-2 pr-4 py-2 font-bold"
                  style={{ textAlign: "end" }}
                >
                  {reportSummary?.total_net_sale}
                </td>
              </tr>
            </>
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default InvoicesDetailsReport;
