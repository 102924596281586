import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import OrderApi from "api/posapi/order";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import Pagination from "Components/Common/Pagination";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import FilterElement from "Components/Common/FilterElement";
import DateFilter from "Components/Common/DatePicker";
import FormatDate from "Components/Common/FormatDate";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import PosSidebar from "Components/Common/PosSidebar";
import Table from "Components/Common/GeneralTable";
import AppWrapper from "Components/Routes/AppWrapper";


function AllSales() {
  const [dataToShow, setDataToShow] = useState([]);
  const [filteredTableData,setFilterTableData] = useState([])
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [filter, setFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });
  const navigate = useNavigate();
  const handleDetailsClick = (itemId) => {
    navigate(`/sales/details/${itemId}`);
  };
  const refundOrder = item => {
    console.log(item.invoice_no)
    OrderApi.refund(item.invoice_no).then(res => fetchProducts()).catch(err => console.log(err))
  }
   
  
  
  const fetchProducts = () => {
    setLoading(true);
    const { idPredicate, filterId, startDate, endDate } = filters;
    const formattedStartDate = startDate ? FormatDate(startDate) : "";
    const formattedEndDate = endDate ? FormatDate(endDate) : "";
    OrderApi.allOrderAPI(
      pageNumber,
      filterPerpage,
      filter,
      idPredicate,
      filterId,
      formattedStartDate,
      formattedEndDate
    )
      .then((response) => {
        setDataToShow(response.data.sale);
        console.log(dataToShow)
        setFilterTableData(response.data.sale.map((item) => {
          return { id: item.id ,invoice_no:item.invoice_no,branch:item.location_name,customer:item.customer,payment_Type: item.payment_type, created_At:FormatDate(item.created_at),grand_Total:item.total_price,status:item.status === 'refunded_sale_child' || item.status === 'complete_refunded' ? 'Refunded' : 'Completed'
      };
        }))
        
        setTotalPages(response.data.total_pages);
        setPaginationValue({
          ...paginationValue,
          totalCount: response.data.total_count,
          startValue: response.data.starting_value,
          endValue: response.data.ending_value,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const actions = [
    {
      name: "View",
      icon:"",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Refund",
      icon:"",
      onClick: (item) => refundOrder(item),
    },
  ];
  const handleStartDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: date,
    }));
  };
  const handleEndDateChange = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: date,
    }));
  };
  const [filters, setFilters] = useState({
    idPredicate: "eq",
    filterId: "",
    startDate: "",
    endDate: "",
  });
  const handleFilterChange = (filterName, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  useEffect(() => {
    fetchProducts();
  }, [pageNumber, filterPerpage, filter, filters]);
  const filterElements = [
    {
      label: "ID",
      predicate: filters.idPredicate,
      filterValue: filters.filterId,
      changePredicate: (value) => handleFilterChange("idPredicate", value),
      changeFilter: (value) => handleFilterChange("filterId", value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Start Date",
      selectedDate: filters.startDate,
      onDateChange: handleStartDateChange,
    },
    {
      label: "End Date",
      selectedDate: filters.endDate,
      onDateChange: handleEndDateChange,
    },
  ];
  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];
  return (
    <>
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
          <div className="w-full flex mb-4 justify-between">
            <div className="text-24 font-semibold">
              Sales
            </div>
            <div className="flex justify-end w-1/2">
              <div className="justify-center  mt-4 ">
                <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md "
                  onClick={() => setShowFilters(!showFilters)}
                  type="button"

                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
              </div>
            </div>
          </div>
          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-4">
                  {filterElements.map((filterElement, index) =>
                    filterElement.selectedDate !== undefined ? (
                      <DateFilter
                        key={index}
                        label={filterElement.label}
                        selectedDate={filterElement.selectedDate}
                        onDateChange={filterElement.onDateChange}
                      />
                    ) : (
                      <FilterElement
                        key={index}
                        label={filterElement.label}
                        predicate={filterElement.predicate}
                        filterValue={filterElement.filterValue}
                        changePredicate={filterElement.changePredicate}
                        changeFilter={filterElement.changeFilter}
                        options={filterElement.options}
                      />
                    )
                  )}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelectDropDown
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <Table
            data={filteredTableData}
            loading={loading}
            actions={actions}
            startValue={paginationValue.startValue}
          />       
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}

export default AllSales;
