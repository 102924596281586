import React, { useEffect, useState } from "react";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import CreateCard from "./CreateCard";
import Table from "Components/Common/GeneralTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";

function AllCards() {
  const [popupId, setPopupId] = useState("");
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemToDelete, setItemToDelete] = useState("");

  const navigate = useNavigate();

  const fetchCards = () => {
    ConfigurationAPI.showAllCards()
      .then((response) => {
        setLoading(false);
        const filterData = response?.data?.cards.map((item) => {
          const { company_id, ...filterData } = item;
          return filterData;
        });
        setCardsData(filterData);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const handleDetailsClick = (itemId) => {
    navigate(`/card-detail/${itemId}`);
  };

  const handleCardUpdate = (itemId) => {
    navigate(`/edit-card/${itemId}`);
  };

  const handleDeleteCard = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopupId("cardDelete");
  };

  const cancelDelete = () => {
    setPopupId("");
    setItemToDelete("");
  };

  const deleteCardConfirmed = () => {
    setPopupId("");
    if (itemToDelete) {
      ConfigurationAPI.cardDelete(itemToDelete.id)
        .then((response) => {
          toast.success(response?.data?.message);
          const newData = cardsData.filter(
            (item) => item.id !== itemToDelete.id
          );
          setCardsData(newData);
          setItemToDelete("");
        })
        .catch((error) => {
          if (error) {
            toast.error("Something Went Wrong!");
          }
        });
    }
  };
  const actions = [
    {
      name: "View",
      icon: "",
      color: "blue",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "",
      color: "blue",
      onClick: (item) => handleCardUpdate(item.id),
    },
    {
      name: "Delete",
      icon: "",
      color: "blue",
      onClick: handleDeleteCard,
    },
  ];

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="w-full flex my-4 justify-between">
          <div className="flex text-24 font-semibold items-center justify-center">
            All Cards
          </div>
          <div className="w-[15%] flex justify-end text-end">
            <button
              className="btn-primary w-full"
              onClick={() => setPopupId("createCard")}
            >
              Create Card
            </button>
          </div>
        </div>
        <ShowComponent condition={popupId === "createCard"}>
          <Popup setPopup={() => setPopupId("")} className="w-[30%]">
            <CreateCard
              contentOnly={true}
              setPopupId={setPopupId}
              fetchCards={fetchCards}
            />
          </Popup>
        </ShowComponent>
        <ShowComponent condition={popupId === "cardDelete"}>
          <Popup setPopup={() => setPopupId("")} className="w-[50%] z-40 mt-5">
            {itemToDelete && (
              <div className="w-full flex flex-col text-center mt-4">
                <p className="mb-4">
                  Are you sure you want to delete this Card?
                </p>
                <div className="flex justify-center">
                  <Button
                    className="bg-green text-white py-3.5 px-5 mb-5"
                    onClick={deleteCardConfirmed}
                  >
                    Yes
                  </Button>
                  <Button
                    className="bg-red text-white py-3.5 px-5 mb-5 ml-3"
                    onClick={cancelDelete}
                  >
                    No
                  </Button>
                </div>
              </div>
            )}
          </Popup>
        </ShowComponent>
        <Table data={cardsData} actions={actions} loading={loading} />
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllCards;
