import React, { useEffect, useState } from "react";
import HrmSidebar from "Components/Common/HrmSidebar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import Table from "Components/Common/GeneralTable";
import { useNavigate } from "react-router-dom";
import Popup from "Components/Common/Pages/Popup";
import ShowComponent from "Components/ShowComponent";
import Button from "Components/Common/Button";
import EmployeesApi from "api/hrmapi/employee";
import { toast } from "react-toastify";
function AllEmployeeDataMagement() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupId, setPopupId] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleCreate = () => {
    navigate(`/create-employee-data-management`);
  };
  const handleInvitations = () => {
    navigate(`/employee-Invitations`);
  };
  const handleDeleteClick = (itemToDelete) => {
    setItemToDelete(itemToDelete);
    setPopupId("deleteId");
  };
  const cancelDelete = () => {
    setPopupId("");
    setItemToDelete(null);
  };
  const handleUpdateClick = (itemId) => {
    navigate(`/employee-data/edit/${itemId}`);
  };
  const handleDetailsClick = (itemId) => {
    navigate(`/employee-data/details/${itemId}`);
  };
  const deleteConfirmed = () => {
    setPopupId("");
    if (itemToDelete) {
      EmployeesApi.deleteEmployees(itemToDelete.id)
        .then((response) => {
          const newData = dataToShow.filter(
            (item) => item.id !== itemToDelete.id
          );
          setDataToShow(newData);
          const Message = response
            ? "Record Deleted Successfully From List"
            : "An error occurred";
          toast.success(`${Message}`);
        })
        .catch((error) => {
          const errorMessage = error.response?.data.message
          ? error.response.data.message
          : "An error occurred";

        toast.error(`Error: ${errorMessage}`);
        });
      setItemToDelete(null);
    }
  };
  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      onClick: (item) => handleDetailsClick(item.id),
    },
    {
      name: "Edit",
      icon: "fas fa-edit",
      onClick: (item) => handleUpdateClick(item.id),
    },
    {
      name: "Delete",
      color: "red",
      icon: "fas fa-trash-alt",
      onClick: handleDeleteClick,
    },
  ];
  useEffect(() => {
    EmployeesApi.allEmployeesApi()
      .then((res) => {
        const newData = res.data.map((item) => ({
          id: item.id,
          title: item.title,
          work_email: item.work_email,
          joining_date: item.joining_date,
          created_at: item.created_at,
        }));
        setDataToShow(newData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);

  return (
    <AppWrapper sideBar={<HrmSidebar />}>
      <PagesWrapper>
        <ShowComponent condition={popupId === "deleteId"}>
          <Popup setPopup={() => setPopupId("")} className="w-[50%]">
            {itemToDelete && (
              <div className="row text-center mt-4">
                <p className="col-12">
                  Are you sure you want to delete this Employee?
                </p>
                <Button
                  className="btn btn-danger my-3 w-[14%]"
                  onClick={deleteConfirmed}
                >
                  Yes
                </Button>
                <Button
                  className="btn btn-success my-3 ml-2 w-[14%]"
                  onClick={cancelDelete}
                >
                  Cancel
                </Button>
              </div>
            )}
          </Popup>
        </ShowComponent>
        <div className="w-full flex mb-4 justify-between">
          <div className="text-24 mb-4 ">
            <h1>All Employee</h1>
          </div>
          <div className="w-[34%] flex justify-end mt-2 text-end">
            <div className="w-full  justify-between flex">
            <button className="btn-primary  w-[45%]" onClick={handleCreate}>
              Create Employee
            </button>
            <button className="btn-primary w-[45%]" onClick={handleInvitations}>
              Invitations 
            </button>
            </div>
          </div>
        </div>
        <Table
          data={dataToShow}
          loading={loading}
          actions={actions}
          // startValue={paginationValue.startValue}
        />
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AllEmployeeDataMagement;
