import React from "react";
import DatePicker from "react-datepicker";

function SelectDate({ label, selectedDate, onDateChange }) {
  return (
    label === "Start Date" ? (
      <div className="form-group mt-2">
      <label className="form-label text-[#969696] font-bold ">{label}</label>
      <div className="mb-3">
        <DatePicker
          className="form-control mt-2"
          placeholderText="Select Date"
          selected={selectedDate}
          onChange={(date) => onDateChange(date)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
    ): label === "End Date" ? (
      <div className="form-group mt-2">
      <label className="form-label text-[#969696] font-bold ">{label}</label>
      <div className="mb-3">
        <DatePicker
          className="form-control mt-2"
          placeholderText="Select Date"
          selected={selectedDate}
          onChange={(date) => onDateChange(date)}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
    ):(
      (
        <div className="form-group ">
        <label className="form-label">{label}</label>
        <div className="mb-3">
          <DatePicker
            className="form-control mt-2"
            placeholderText="Select Date"
            selected={selectedDate}
            onChange={(date) => onDateChange(date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
    ))
  );
}

export default SelectDate;
