import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import VendorAPI from "api/posapi/vendor";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import Table from "Components/Common/GeneralTable";
import Pagination from "Components/Common/Pagination";
import ActiveInactiveButton from "Components/Common/ActiveInactiveButton";
import FilterElement from "Components/Common/FilterElement";
import GernalSelectDropDown from "Components/Common/GeneralSelectDropDown";
import AppWrapper from "Components/Routes/AppWrapper";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import CreateVendor from "./CreateVendor";

function AllVendors() {
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterPerpage, setFilterPerPage] = useState("20");
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState(false);
  const [idPredicate, setIdPredicate] = useState("eq");
  const [filterId, setFilterId] = useState("");
  const [namePredicate, setNamePredicate] = useState("eq");
  const [filterName, setFilterName] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [phoneNumberPredicate, setPhoneNumberPredicate] = useState("eq");
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [companyPredicate, setCompanyPredicate] = useState("eq");
  const [filterCompany, setFilterCompany] = useState("");
  const [popupId, setPopupId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    totalCount: 0,
    startValue: 0,
    endValue: 0,
  });

  const navigate = useNavigate();
  const handleDetailsClick = (itemId) => {
    navigate(`/vendor/details/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      onClick: (item) => handleDetailsClick(item.id),
    },
  ];


 const fetchVendors = () => {
  setLoading(true);
  VendorAPI.allVendorAPI(
    pageNumber,
    filterPerpage,
    filter,
    idPredicate,
    filterId,
    namePredicate,
    filterName,
    phoneNumberPredicate,
    filterPhoneNumber,
    companyPredicate,
    filterCompany
  )
    .then((response) => {
      setDataToShow(response.data.vendors);
      setTotalPages(response.data.total_pages);
      setPaginationValue({
        ...paginationValue,
        totalCount: response.data.total_count,
        startValue: response.data.starting_value,
        endValue: response.data.ending_value,
      });
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
 }

  useEffect(() => {
    fetchVendors()
  }, [
    pageNumber,
    filterPerpage,
    filter,
    filter && idPredicate,
    filter && filterId,
    filter && namePredicate,
    filter && filterName,
    filter && phoneNumberPredicate,
    filter && filterPhoneNumber,
    filter && companyPredicate,
    filter && filterCompany,
  ]);

  const filterElements = [
    {
      label: "ID",
      predicate: idPredicate,
      filterValue: filterId,
      changePredicate: (value) => setIdPredicate(value),
      changeFilter: (value) => setFilterId(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Greater & =", value: "gteq" },
        { label: "Less & =", value: "lteq" },
      ],
    },
    {
      label: "Name",
      predicate: namePredicate,
      filterValue: filterName,
      changePredicate: (value) => setNamePredicate(value),
      changeFilter: (value) => setFilterName(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Phone Number",
      predicate: phoneNumberPredicate,
      filterValue: filterPhoneNumber,
      changePredicate: (value) => setPhoneNumberPredicate(value),
      changeFilter: (value) => setFilterPhoneNumber(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
    {
      label: "Company",
      predicate: companyPredicate,
      filterValue: filterCompany,
      changePredicate: (value) => setCompanyPredicate(value),
      changeFilter: (value) => setFilterCompany(value),
      options: [
        { label: "=", value: "eq" },
        { label: "Contains", value: "cont" },
      ],
    },
  ];
  const selectPerpage = [
    {
      title: "Show per page",
      value: filterPerpage,
      onChange: (e) => setFilterPerPage(e.target.value),
      options: [
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
      ],
    },
  ];

  return (
      <AppWrapper sideBar={<PosSidebar />}>
        <PagesWrapper>
        <div className="w-full flex my-4 justify-between">
            <div className="flex items-center justify-center">
              <h1 className="text-24 font-semibold">Vendors</h1>
            </div>
            <div className="w-[15%] flex justify-end text-end">
              <button
                className="btn-primary w-full"
                onClick={() => setPopupId("createVendor")}
              >
                Create Vendor
              </button>
            </div>
          </div>
            <div className="flex justify-end w-full">
              <div className="justify-center mt-2">
                <button
                  className="w-full p-2 flex justify-around items-center bg-white rounded-md"
                  onClick={() => setShowFilters(!showFilters)}
                  type="button"

                >
                  <div className="w-[8%]">
                    <img
                      src="./icons/fi-rr-settings-sliders.svg"
                      alt="image"
                      className="m-2"
                    />
                  </div>
                  <div className="m-2">Advanced Filters</div>
                </button>
              </div>
            </div>
          
          <ShowComponent condition={popupId === "createVendor"}>
            <Popup setPopup={() => setPopupId("")} className="w-[450px]">
              <CreateVendor contentOnly={true} 
              setPopupId={setPopupId}
              fetchVendors={fetchVendors}
              />
            </Popup>
          </ShowComponent>
          {showFilters && (
            <div className="card">
              <div className="card-body">
                <div className="flex flex-wrap gap-4 py-4">
                {filterElements.map((filterElement, index) => (
                    <FilterElement
                      key={index}
                      label={filterElement.label}
                      predicate={filterElement.predicate}
                      filterValue={filterElement.filterValue}
                      changePredicate={filterElement.changePredicate}
                      changeFilter={filterElement.changeFilter}
                      options={filterElement.options}
                    />
                  ))}
                  {selectPerpage.map((Item, index) => (
                    <GernalSelectDropDown
                      className="form-group col-md-2"
                      key={index}
                      title={Item.title}
                      value={Item.value}
                      onChange={Item.onChange}
                      options={Item.options}
                    />
                  ))}
                  <ActiveInactiveButton filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </div>
          )}
          <Table
            data={dataToShow}
            loading={loading}
            actions={actions}
            startValue={paginationValue.startValue}
          />
          {dataToShow.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              totalCount={paginationValue.totalCount}
              startValue={paginationValue.startValue}
              endValue={paginationValue.endValue}
            />
          )}
        </PagesWrapper>
      </AppWrapper>
  );
}

export default AllVendors;
