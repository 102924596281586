import React from "react";
import FormatDate from "Components/Common/FormatDate";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function SalesmanReport({
  startDate,
  endDate,
  selectedBranch,
  selectedSalesman,
  salesmanReport,
  showTime,
  capitalizeFirstLetter,
  contentOnly = false,
}) {
  return (
    <AppWrapper sideBar={<AdminSideBar />} contentOnly={contentOnly}>
      <PagesWrapper>
        <div className="text-22 font-bold text-center mt-5 mb-10">
          Sales Report by Salesman
        </div>
        <div className="flex w-full justify-between px-14">
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">From Date:</div>
              <div className="w-[50%]">{FormatDate(startDate)}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">To Date:</div>
              <div className="w-[50%]">{FormatDate(endDate)}</div>
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex w-full">
              <div className="font-bold w-[50%]">Print Date:</div>
              <div className="w-[50%]">{FormatDate(new Date())}</div>
            </div>
            <div className="flex w-full mt-3">
              <div className="font-bold w-[50%]">Print Time:</div>
              <div className="w-[50%]">{showTime}</div>
            </div>
            <div className="flex w-full my-3">
              <div className="font-bold w-[50%]">Page #:</div>
              <div className="w-[50%]">1</div>
            </div>
          </div>
        </div>
        <table className="w-[90%] pr-10 ml-12">
          <thead className="w-full border-y-1 border-black">
            <tr className="w-full">
              <th className="w-[40%] px-2 py-4">Salesman</th>
              <th className="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                Total Price
              </th>
              <th className="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                Discount
              </th>
              <th className="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                Tax
              </th>
              <th className="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                Bonus
              </th>
              <th className="w-[12%] px-2 py-4" style={{ textAlign: "end" }}>
                Net
              </th>
            </tr>
          </thead>
          <tr>
            <td colSpan="10" className="p-4 font-bold">
              Branch:{" "}
              {selectedBranch?.name
                ? capitalizeFirstLetter(selectedBranch?.name)
                : ""}
            </td>
          </tr>
          <tbody className="w-full pt-3">
            {salesmanReport || salesmanReport.length > 0 ? (
              <>
                <tr className="w-full">
                  <td class="w-[40%] pr-2 py-4 pl-8">
                    {selectedSalesman?.name
                      ? capitalizeFirstLetter(selectedSalesman?.name)
                      : ""}
                  </td>
                  <td class="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                    {salesmanReport?.sub_total}
                  </td>
                  <td class="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                    {salesmanReport?.discount}
                  </td>
                  <td class="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                    {salesmanReport?.tax}
                  </td>
                  <td class="w-[12%] pr-2 py-4" style={{ textAlign: "center" }}>
                    {salesmanReport?.value}
                  </td>
                  <td class="w-[12%] px-2 py-4" style={{ textAlign: "end" }}>
                    {salesmanReport?.total_price}
                  </td>
                </tr>

                <tr className="w-full border-t-1 border-black">
                  <td class="w-[40%] px-2 py-4"></td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.sub_total}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.discount}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.tax}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.value}
                  </td>
                  <td
                    class="w-[12%] px-2 py-4 font-bold"
                    style={{ textAlign: "end" }}
                  >
                    {salesmanReport?.total_price}
                  </td>
                </tr>

                <tr className="w-full">
                  <td class="w-[40%] px-2 py-4"></td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.sub_total}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.discount}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.tax}
                  </td>
                  <td
                    class="w-[12%] pr-2 py-4 font-bold"
                    style={{ textAlign: "center" }}
                  >
                    {salesmanReport?.value}
                  </td>
                  <td
                    class="w-[12%] px-2 py-4 font-bold"
                    style={{ textAlign: "end" }}
                  >
                    {salesmanReport?.total_price}
                  </td>
                </tr>
              </>
             ) : (
              <tr className="w-full pt-4 text-center border-t-1 border-black">
                <td colSpan="10" className="p-4 font-bold">
                  No Data Found
                </td>
              </tr>
            )} 
          </tbody>
        </table>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default SalesmanReport;
