import React, { useState, useMemo, useEffect } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import Popup from "Components/Common/Pages/Popup";
import OrderApi from "api/posapi/order";
import { ToastContainer, toast } from "react-toastify";
import ShowComponent from "Components/ShowComponent";
import CreateCustomer from "Components/Accounts/CreateCustomer";
import UpdateCustomer from "Components/Accounts/UpdateCustomer";
import Button from "Components/Common/Button";
import Invoice from "./Invoice";
import ConfirmationSale from "Components/Pos/CreateSale/ConfirmationSale";
import PosSidebar from "Components/Common/PosSidebar";
import DiscountAPI from "api/posapi/discount";
import numeral from "configurations/default_money";
import SaleItemsTable from "./SaleItemsTable";
import AppWrapper from "Components/Routes/AppWrapper";
import SlickCarouselWithPagination from "./PaginateSlick";
import { QrScanner } from "@yudiel/react-qr-scanner";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import FormatDate from "Components/Common/FormatDate";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import useSound from "use-sound";
import AuthAPI from "api/auth/auth";

import {
  addItemToCart,
  clearCart,
  CouponCart,
  saleId,
  SaleIdEmail,
  SessionId,
  IsOpen,
  IsClose,
  customerData,
  removeItemFromCart,
  updateItemQuantity,
  updateTotals,
} from "store/Sale";
import { useDispatch, useSelector } from "react-redux";
import GeneralInput from "Components/Common/GeneralInput";
import ViewTotal from "./ViewTotal";
import ViewInvoice from "./ViewInvoice";
import ReturnSale from "./ReturnSale";
import ViewStock from "./ViewStock";
import CustomerApi from "api/customerapi/customer";
import CreateTransfer from "../Transfer/CreateTransfer";
import { clearAllAuthData } from "store/Auth";
import CashierApi from "api/cashier/cashier";
import PrintBill from "./PrintBill";
import CashToVisa from "./CashToVisa";
import CircularViews from "../Circular/CircularModal";
import Circular from "../Circular/Circular";
import AccessCode from "../AccessCode/AccessCode";
import { setTaxFreeData } from "store/Taxfree";
import { TrainRounded } from "@mui/icons-material";
import DiscountOffers from "./DiscountOffers";
import Beep from "./sounds/beep.mp3";
import TaxFree from "./TaxFree";
function CashierUi() {
  const defaultLoc = useSelector((state) => state.auth.currentUser.default_loc);
  const userName = useSelector((state) => state.auth.currentUser.user_name);
  const userRole = useSelector((state) => state.auth.currentUser.role_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cashAmount, setCashAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");
  const tableData = useSelector((obj) => obj.sales.cart);
  const {
    subtotal,
    totalItems,
    totalTax,
    totalDiscount,
    totalPrice,
    couponDiscount,
  } = useSelector((state) => state.sales);
  const saleid = useSelector((state) => state.sales?.saleid);
  const sessionId = useSelector((state) => state.sales?.sessionId);
  const isOpen = useSelector((state) => state.sales?.isOpen);
  const isClose = useSelector((state) => state.sales?.isClose);
  const getCustomerData = useSelector((state) => state.sales?.customer);
  const [show, setShow] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [status, setStatus] = useState("completed");
  const [cash, setCash] = useState("cash");
  const [showpopup, setShowPopUp] = useState(false);
  const [popupId, setPopupId] = useState("");
  const [recipedata, setRecipeData] = useState({});
  const [saleitems, setSaleItems] = useState([]);
  const [itemtax, setItemTax] = useState("");
  const [showAddCustomerPopup, setShowAddCustomerPopup] = useState(false);
  const [showUpdateCustomerPopup, setShowUpdateCustomerPopup] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [barcodealert, setBarcodeAlert] = useState("");
  const [error_message, setErrorMessage] = useState("");
  const [barcode_error, setBarCodeError] = useState(false);
  const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [showSearchedCustomers, setShowSearchedCustomers] = useState(false);
  const [returnAmount, setReturnAmount] = useState(0);
  const [allproducts, setAllProducts] = useState([]);
  const [allproduct, setAllProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryid, setCategoryId] = useState(null);
  const [quantityalert, setQuantityAlert] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [customerMode, setCustomerMode] = useState("search");
  const [validAccess, setValidAccess] = useState(false);
  const [accessCodeMessage, setAccessCodeMessage] = useState("");
  const [discountApplied, setDiscountApplied] = useState([]);
  const [appliedChecked, setAppliedChecked] = useState(false);
  const [isCancelAccessCode, setIsCancelAccessCode] = useState(false);
  const [scanDelay, setScanDelay] = useState(1000);
  const [playActive] = useSound(Beep);
  const [facingMode, setFacingMode] = useState("user");
  const [torchStatus, setTorchStatus] = useState(false);
  const [searchingProduct, setSearchingProduct] = useState(false);
  const [menu, setMenu] = useState(false);
  let passData = {
    invoice: "",
    mrzFHalf: "",
    mrzSHalf: "",
    fName: "",
    lName: "",
    nationality: "",
    residence: "",
    mobile: "",
    birthdate: "",
    idType: "",
    idIssuedBy: "",
    idNo: "",
  };
  const [passportData, setPassportData] = useState(passData);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const [selectedCard, setSelectedCard] = useState("");
  const [approvalCode, setApprovalCode] = useState("");
  const [tempModel, setTempModel] = useState("");

  useEffect(() => {
    const cashValue = numeral(cashAmount).format("0[.]00");
    const cardValue = numeral(cardAmount).format("0[.]00");

    if (cashValue > 0 || cardValue > 0) {
      const totalAmount = numeral(cashValue).add(cardValue).value();
      const returnAmt = numeral(totalAmount)
        .subtract(totalPrice)
        .format("0[.]00");
      setReturnAmount(returnAmt);
    } else if (cashValue == 0 && cardValue == 0) {
      setReturnAmount(0);
    }
  }, [cashAmount, cardAmount, totalPrice]);
  const handleErrorMessage = (error) => {
    const errorMessage = error.response?.data.message
      ? error.response.data.message
      : "An error occurred";

    toast.error(`Error: ${errorMessage}`);
  };
  const handleSuccessMessage = (response) => {
    const Message = response ? response.data.message : "An error occurred";
    toast.success(`Success:${Message}`);
  };
  const handleListItemClick = (phoneNo, customerId) => {
    dispatch(customerData({ customerId: customerId }));
    setFilterPhoneNumber(phoneNo);

    setPopupId("");
  };
  const fetchProducts = () => {
    setBarcode("");
    OrderApi.productsAvailable(queryParams)
      .then((response) => {
        const selectedProduct = response.data[0];
        if (selectedProduct) {
          handleProductSelect(selectedProduct);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setBarcode("");
        setDataLoading(false);
      });
  };
  const queryParams = {};
  if (barcode && barcode.length === 13) {
    queryParams["q[barcode]"] = barcode;
  }
  if (categoryid) {
    queryParams["q[category_id]"] = categoryid;
  }
  if (barcode.length === 13) {
    fetchProducts();
  }
  const handleBarcodeKeyDown = (event) => {
    if (event.key === "Enter") {
      queryParams["q[sku]"] = barcode;
      fetchProducts(); 
    }
  };
  const AllCategoryClicked = () => {
    setCategoryId(null);
    setSelectedCategoryIds(null);
    OrderApi.productsAvailable()
      .then((response) => {
        setAllProducts(response.data);
        setAllProduct(response.data);
        setDataLoading(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
        setDataLoading(false);
      });
  };
  useEffect(() => {
    if (categoryid) {
      setAllProducts((allProducts) => []);
      OrderApi.productsAvailable(queryParams)
        .then((response) => {
          setDataLoading(false);
          setCategoryId(null);
          setAllProducts(response.data);
        })
        .catch((error) => {
          handleErrorMessage(error);
          setDataLoading(false);
        });
    }
  }, [categoryid]);

  useEffect(() => {
    OrderApi.prevSalesAPI()
      .then((response) => {
        const quantityMap = {};
        allproduct.forEach((product) => {
          const { product_id, quantity } = product;
          quantityMap[product_id] = quantity;
        });
        const updatedProductWithQuantity = response?.data.sale_items.map(
          (product) => {
            const { product_id } = product;
            const totalQuantity = quantityMap[product_id] || 0;
            return {
              product_id: product.product_id,
              product_name: product.product_name,
              total_tax_amount: product.total_tax_amount,
              discount: product.discount,
              qty: product.quantity,
              input_field_value: product.quantity,
              quantity: totalQuantity,
              price: product.price,
              amount: product.amount,
              ch_discount: product.ch_discount,
            };
          }
        );
        const total = {
          subtotal: response.data.calculations.subtotal,
          totalItems: response.data.calculations.total_items,
          totalDiscount: response.data.calculations.total_discount,
          totalTax: response.data.calculations.total_tax,
          totalPrice: response.data.calculations.total_price,
        };
        dispatch(saleId(response.data.sale_id));
        dispatch(SaleIdEmail(response.data.sale_id));
        dispatch(updateTotals(total));
        dispatch(CouponCart(updatedProductWithQuantity));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [allproduct]);
  useEffect(() => {
    DiscountAPI.categorysAvailable()
      .then((response) => {
        setCategories(response.data);
        AllCategoryClicked();
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.log(error);
      });
  }, []);

  const handleCustomerSearch = (customerId) => {
    if (!filterPhoneNumber.trim() && typeof customerId !== "number") {
      return;
    }
    OrderApi.AllCustomer(filterPhoneNumber, customerId)
      .then((response) => {
        const customers = response?.data?.customers[0];
        dispatch(
          customerData({
            customerId: customers.id,
            customerPhone: customers.phone_no,
            customerName: customers.name,
            customerAddress: customers.address,
            customerEmail: customers.email,
          })
        );
        setFilterPhoneNumber("");
        setDataLoading(false);
      })
      .catch((error) => {
        toast.error("Customer Not Exists");
        setDataLoading(false);
      });
  };

  const showModal = () => {
    if (tableData.length > 0) {
      setShowPopUp(!showpopup);
    } else {
      toast.error(`Error: Please add product to cart`);
    }
  };
  const customerMOdal = () => {
    setShowAddCustomerPopup(!showAddCustomerPopup);
  };
  const customerUpdateModal = () => {
    setShowUpdateCustomerPopup(!showUpdateCustomerPopup);
  };
  const handleBarcodeInputChange = (event, barcode) => {
    const value = barcode || event.target.value;
    setBarcode(value);
    const remainingCharacters = 13 - parseInt(value.length);

    if (value.length === 0) {
      setBarcodeAlert("");
      setBarCodeError(false);
      setErrorMessage("");
    } else if (value.length !== 13) {
      setBarcodeAlert(
        remainingCharacters >= 1
          ? `Remaining characters: ${remainingCharacters}`
          : ""
      );
      setBarCodeError(true);
      setErrorMessage("Number of characters should be equal to 13!");
    } else {
      setBarcodeAlert("");
      setBarCodeError(false);
      setErrorMessage("");
    }
  };
  const handleCategorySelect = (obj) => {
    const CategoryId = categories.find(
      (category) => category.category_id === obj.category_id
    );
    setCategoryId(CategoryId.category_id);
    setSelectedCategoryIds(CategoryId.category_id);
  };
  const handleProductSelect = (selectedProduct) => {
    dispatch(IsOpen(true));
    dispatch(IsClose(false));

    if (!saleid) {
      OrderApi.initiateOrderAPI()
        .then((response) => {
          dispatch(SessionId(response?.data?.sale?.cashier_session_id));
          dispatch(saleId(response.data.sale.id));
          dispatch(SaleIdEmail(response.data.sale.id));
          OrderApi.addItemAPI({
            cart: {
              product_id: selectedProduct.product_id,
              sale_id: response?.data?.sale.id,
              quantity: 1,
            },
          })
            .then((response) => {
              if(response.data.message){
                toast.warning(response.data.message)
              }
              setSearchingProduct(false);
              setDataLoading(false);
              const product = {
                product_id: response.data.sale_item.product_id,
                product_name: selectedProduct.product_name,
                total_tax_amount: response.data.sale_item.total_tax_amount,
                discount: response.data.sale_item.discount,
                qty: response.data.sale_item.quantity,
                input_field_value: 1,
                quantity: selectedProduct.quantity,
                price: response.data.sale_item.price,
                amount: response.data.sale_item.amount,
                ch_discount: response.data.sale_item.ch_discount,
              };
              const calculation = {
                subtotal: response.data.calculations.subtotal,
                totalItems: response.data.calculations.total_items,
                totalDiscount: response.data.calculations.total_discount,
                totalTax: response.data.calculations.total_tax,
                totalPrice: response.data.calculations.total_price,
              };
              dispatch(addItemToCart(product));
              dispatch(updateTotals(calculation));
            })
            .catch((error) => {
              setSearchingProduct(false);
              handleErrorMessage(error);
            });
        })
        .catch((error) => {
          setSearchingProduct(false);
          handleErrorMessage(error);
        });
    } else {
      const existingProduct = tableData.find(
        (product) => product.product_id === selectedProduct.product_id
      );
      if (existingProduct) {
        if (existingProduct.qty < selectedProduct.quantity) {
          handleQtyChange(existingProduct, existingProduct.qty + 1);
        } else {
          setQuantityAlert(true);
          setTimeout(() => {
            setQuantityAlert(false);
          }, 2000);
        }
      } else {
        setDataLoading(true);
        OrderApi.addItemAPI({
          cart: {
            product_id: selectedProduct.product_id,
            sale_id: saleid,
            quantity: 1,
          },
        })
          .then((response) => {
            if(response.data.message){
              toast.warning(response.data.message)
            }
            setSearchingProduct(false);
            setDataLoading(false);
            const product = {
              product_id: response.data.sale_item.product_id,
              product_name: selectedProduct.product_name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: 1,
              quantity: selectedProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
              ch_discount: response.data.sale_item.ch_discount,
            };
            const calculation = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
            };
            dispatch(addItemToCart(product));
            dispatch(updateTotals(calculation));
            setDataLoading(false);
          })
          .catch((error) => {
            setSearchingProduct(false);
            handleErrorMessage(error);
            setDataLoading(false);
          });
      }
    }
  };
  // ==================== ADD UPDATE REMOVE TABLE DATA ======================
  const removeProductFromTable = (product_id) => {
    OrderApi.removeItemAPI({
      cart: {
        product_id: product_id.product_id,
        sale_id: saleid,
      },
    })
      .then((response) => {
        const total = {
          subtotal: response.data.calculations.subtotal,
          totalItems: response.data.calculations.total_items,
          totalDiscount: response.data.calculations.total_discount,
          totalTax: response.data.calculations.total_tax,
          totalPrice: response.data.calculations.total_price,
          couponDiscount: response.data.coupon_discount,
        };
        if(response.data.message){
          toast.warning(response.data.message)
        }
        dispatch(updateTotals(total));
        dispatch(removeItemFromCart(product_id));
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.log(error);
      });
  };
  // ========================================================================
  const handleOnBlurQuantity = (existingProduct, value) => {
    if (!value) {
      OrderApi.updateItemAPI({
        cart: {
          product_id: existingProduct.product_id,
          sale_id: saleid,
          quantity: existingProduct.qty,
        },
      })
        .then((response) => {
          if (response.data.message) {
            toast.warning(response.data.message);
          }
          setSearchingProduct(false);
          const updatedProduct = {
            product_id: response.data.sale_item.product_id,
            product_name: existingProduct.product_name,
            total_tax_amount: response.data.sale_item.total_tax_amount,
            discount: response.data.sale_item.discount,
            qty: response.data.sale_item.quantity,
            input_field_value: response.data.sale_item.quantity,
            quantity: existingProduct.quantity,
            price: response.data.sale_item.price,
            amount: response.data.sale_item.amount,
            ch_discount: response.data.sale_item.ch_discount,
          };
          const total = {
            subtotal: response.data.calculations.subtotal,
            totalItems: response.data.calculations.total_items,
            totalDiscount: response.data.calculations.total_discount,
            totalTax: response.data.calculations.total_tax,
            totalPrice: response.data.calculations.total_price,
            couponDiscount: response.data.coupon_discount,
          };
          dispatch(updateTotals(total));
          dispatch(updateItemQuantity(updatedProduct));
        })
        .catch((error) => {
          setSearchingProduct(false);
          handleErrorMessage(error);
          console.log(error);
        });
    }
  };

  const handleQtyChange = (existingProduct, value) => {
    if (!value) {
      dispatch(
        updateItemQuantity({
          ...existingProduct,
          product_id: existingProduct.product_id,
          input_field_value: "",
        })
      );
    } else {
      let quantity = value;
      if (quantity >= 1 && quantity <= existingProduct.quantity) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: parseInt(value),
          },
        })
          .then((response) => {
            setSearchingProduct(false);
            setDataLoading(false);
            if (response.data.message) {
              toast.warning(response.data.message);
            }
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              product_name: existingProduct.product_name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
              couponDiscount: response.data.coupon_discount,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            setSearchingProduct(false);
            handleErrorMessage(error);
            setDataLoading(false);
            console.log(error);
          });
      } else if (quantity < 1) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: 1,
          },
        })
          .then((response) => {
            setSearchingProduct(false);
            setDataLoading(false);
            if (response.data.message) {
              toast.warning(response.data.message);
            }
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              product_name: existingProduct.product_name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
              couponDiscount: response.data.coupon_discount,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            setSearchingProduct(false);
            handleErrorMessage(error);
            setDataLoading(false);
            console.log(error);
          });
      } else if (quantity > existingProduct.quantity) {
        setDataLoading(true);
        OrderApi.updateItemAPI({
          cart: {
            product_id: existingProduct.product_id,
            sale_id: saleid,
            quantity: existingProduct.quantity,
          },
        })
          .then((response) => {
            setSearchingProduct(false);
            setDataLoading(false);
            if (response.data.message) {
              toast.warning(response.data.message);
            }
            const updatedProduct = {
              product_id: response.data.sale_item.product_id,
              product_name: existingProduct.product_name,
              total_tax_amount: response.data.sale_item.total_tax_amount,
              discount: response.data.sale_item.discount,
              qty: response.data.sale_item.quantity,
              input_field_value: response.data.sale_item.quantity,
              quantity: existingProduct.quantity,
              price: response.data.sale_item.price,
              amount: response.data.sale_item.amount,
            };
            const total = {
              subtotal: response.data.calculations.subtotal,
              totalItems: response.data.calculations.total_items,
              totalDiscount: response.data.calculations.total_discount,
              totalTax: response.data.calculations.total_tax,
              totalPrice: response.data.calculations.total_price,
              couponDiscount: response.data.coupon_discount,
            };
            dispatch(updateTotals(total));
            dispatch(updateItemQuantity(updatedProduct));
          })
          .catch((error) => {
            setSearchingProduct(false);
            setDataLoading(false);
            handleErrorMessage(error);
            console.log(error);
          });
        setQuantityAlert(true);
        setTimeout(() => {
          setQuantityAlert(false);
        }, 2000);
      }
    }
  };
  const taxFree = useSelector((state) => state.Taxfree);

  const handleCreateOrder = (e) => {
    setDiscountApplied([]);
    const card_amount = numeral(cardAmount).format("0[.]00") || 0;
    const cash_amount = numeral(cashAmount).format("0[.]00") || 0;
    const total_price = numeral(totalPrice).format("0[.]00") || 0;
    const return_amount = numeral(returnAmount).format("0[.]00") || 0;
    e.preventDefault();
    if (
      numeral(card_amount).add(cash_amount).value() <
      numeral(total_price).value()
    ) {
      toast.error(`Error: Received amount less than total bill`);
      return;
    }
    const OrderItems = tableData.map((item) => ({
      product_id: item.product_id,
      quantity: item.qty,
    }));

    const taxFreeParams = !taxFree?.is_tax_free
      ? {}
      : {
          ...taxFree,
        };

    const OrderData = {
      cart: {
        status: status,
        customer_id: getCustomerData.customerId,
        sale_id: saleid,
        payment_method: cash,
        return_amount: numeral(return_amount).value() || 0,
        cash_amount: numeral(
          parseFloat(cash_amount) <= 0
            ? 0
            : parseFloat(cash_amount) - parseFloat(return_amount)
        ).value(),
        card_amount: numeral(
          parseFloat(card_amount) > parseFloat(total_price)
            ? parseFloat(total_price)
            : parseFloat(card_amount)
        ).value(),
        sale_items: OrderItems,
        ...taxFreeParams,
        card_id: selectedCard,
        approval_code: approvalCode,
      },
    };

    OrderApi.createOrder(OrderData)
      .then((response) => {
        dispatch(setTaxFreeData({}));

        Object.keys(passportData).map((index) => (passportData[index] = ""));
        setPassportData(passportData);
        setPopupId("printBillResponse");
        handleSuccessMessage(response);
        setRecipeData(response?.data?.sale);
        setPaymentMethod(response.data.sale_received_amounts);
        setCashAmount("");
        setCardAmount("");
        setApprovalCode("");
        setSelectedCard("");       
        setSaleItems(response.data.sale_items);
        setItemTax(response.data.taxes_applied_on_sale);
        setShow(true);
        dispatch(clearCart());
        setFilterPhoneNumber("");
        setSearchedCustomers([]);
        setShowPopUp(false);
      })
      .catch((error) => {
        handleErrorMessage(error);
      });
  };
  const handleResetClick = () => {
    if (saleid) {
      OrderApi.clearCartAPI({
        cart: {
          sale_id: saleid,
        },
      })
        .then((res) => {
          dispatch(clearCart());
          setSearchedCustomers([]);
          setFilterPhoneNumber("");
          setDiscountApplied([]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(clearCart());
      setSearchedCustomers([]);
      setFilterPhoneNumber("");
    }
  };
  const handleCustomerCreated = (customerId, phoneNo) => {
    handleCustomerSearch(customerId);
    setTimeout(() => {
      setShowAddCustomerPopup(false);
    }, 2000);
  };
  const handleCustomerUpdated = (customerId) => {
    dispatch(customerData({ customerId: customerId }));
    handleCustomerSearch(customerId);
    setTimeout(() => {
      setShowAddCustomerPopup(false);
    }, 2000);
  };
  const handleCouponApply = (e) => {
    e.preventDefault();
    if (!coupon) {
      toast.error(`Error: Plaese Enter Coupon!`);
      return;
    }
    if (tableData.length === 0) {
      toast.error(`Error: Please add product to cart!`);
      return;
    }
    OrderApi.applyCoupon({
      coupon_code: coupon,
      sale_id: saleid,
    })
      .then((response) => {
        setAppliedChecked(true);
        setPopupId("");
        const updatedProductWithQuantity =
          response?.data.applicable_discount_coupon.sale_items.map(
            (product) => {
              return {
                product_id: product.product_id,
                product_name: product.name,
                total_tax_amount: product.total_tax_amount,
                discount: product.discount,
                qty: product.qty,
                input_field_value: product.input_field_value,
                quantity: product.quantity,
                price: product.price,
                amount: product.amount,
                ch_discount: product.ch_discount,
              };
            }
          );
        const total = {
          subtotal:
            response.data.applicable_discount_coupon.calculations.subtotal,
          totalItems:
            response.data.applicable_discount_coupon.calculations.total_items,
          totalDiscount:
            response.data.applicable_discount_coupon.calculations
              .total_discount,
          totalTax:
            response.data.applicable_discount_coupon.calculations.total_tax,
          totalPrice:
            response.data.applicable_discount_coupon.calculations.total_price,
          couponDiscount:
            response.data.applicable_discount_coupon.coupon_discount,
        };
        dispatch(updateTotals(total));
        dispatch(CouponCart(updatedProductWithQuantity));
        const message = response
          ? response.data.applicable_discount_coupon.message
          : "An error occurred";

        toast.success(`Success:${message}`);
        setCoupon("");
        // setPopupId("");
      })
      .catch((error) => {
        console.log(error);
        handleErrorMessage(error);
      });
  };
  const handleModal = (e) => {
    const { name } = e.target;
    if (name === "finishSale") {
      if (tableData.length > 0 && !getCustomerData.customerId) {
        toast.error(`Error: Please add customer`);
      } else if (tableData.length > 0 && getCustomerData.customerId) {
        setShowPopUp(true);
        setPopupId(name);
      } else {
        toast.error(`Error: Please add product to cart`);
      }
    } else {
      setShowPopUp(true);
      setPopupId(name);
    }
  };

  const handleTaxfree = () => {
    if (passportData.fName && passportData.mobile) {
      dispatch(
        setTaxFreeData({
          shopper: {
            firstName: passportData.fName,
            lastName: passportData.lName,
            nationality: passportData.nationality,
            countryOfResidence: passportData.residence,
            phoneNumber: passportData.mobile,
            birth: {
              date: passportData.birthdate,
            },
            shopperIdentityDocument: {
              type: passportData.idType,
              issuedBy: passportData.idIssuedBy,
              number: passportData.idNo,
            },
          },
          is_tax_free: true,
        })
      );
      toast.success(`Success: Tax Free`);
      setPopupId(tempModel);
    } else {
      toast.error(`Error: Please fill out all required fields`);
    }
  };
  
  const closeTaxFree = () => {
    setPopupId(tempModel)
  };

  const fetchPassportDetails = () => {
    if (passportData.mrzFHalf && passportData.mrzSHalf) {
      const mrz = passportData.mrzFHalf + "\\n" + passportData.mrzSHalf;
      CustomerApi.getPassportDetails({ mrz })
        .then((response) => {
          setPassportData({
            fName: response.data.firstName,
            lName: response.data.lastName,
            birthdate: response.data.birth.date,
            idType: response.data.shopperIdentityDocument.type,
            residence: response.data.countryOfResidence,
            nationality: response.data.nationality,
            idNo: response.data.shopperIdentityDocument.number,
            idIssuedBy: response.data.shopperIdentityDocument.issuedBy,
            mobile: passportData.mobile,
          });
        })
        .catch((err) => console.log(err));
    } else {
      toast.error(`Error: Please fill out all required fields`);
    }
  };
  let passportInputs = [
    {
      title: "MRZ",
      lableAlign: "left",
      value: passportData.mrzFHalf,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "mrzFHalf",
      required: true,
    },
    {
      title: "",
      lableAlign: "left",
      value: passportData.mrzSHalf,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "mrzSHalf",
      required: true,
    },
    {
      title: "Get",
      lableAlign: "left",
      value: "",
      placeholder: "",
      type: "button",
      className: "mb-2",
      name: "get",
      required: true,
      onClick: fetchPassportDetails,
    },
    {
      title: "First Name: ",
      lableAlign: "left",
      value: passportData.fName,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "fName",
      required: true,
      disabled: true,
    },
    {
      title: "Last Name: ",
      lableAlign: "left",
      value: passportData.lName,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "lName",
      required: true,
      disabled: true,
    },
    {
      title: "Nationality: ",
      lableAlign: "left",
      value: passportData.nationality,
      placeholder: "",
      type: "country",
      className: "mb-2",
      name: "nationality",
      required: true,
      disabled: true,
    },
    {
      title: "Country Of Residence: ",
      lableAlign: "left",
      value: passportData.residence,
      placeholder: "",
      type: "country",
      className: "mb-2",
      name: "residence",
      required: true,
      disabled: true,
    },
    {
      title: "Mobile No: ",
      lableAlign: "left",
      value: passportData.mobile,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "mobile",
      required: true,
    },
    {
      title: "Birthdate: ",
      lableAlign: "left",
      value: passportData.birthdate,
      placeholder: "",
      type: "date",
      className: "mb-2",
      name: "birthdate",
      required: true,
      disabled: true,
    },
    {
      title: "Identity Type: ",
      lableAlign: "left",
      value: passportData.idType,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "idType",
      required: true,
      disabled: true,
    },
    {
      title: "Identity Issued By: ",
      lableAlign: "left",
      value: passportData.idIssuedBy,
      placeholder: "",
      type: "country",
      className: "mb-2",
      name: "idIssuedBy",
      required: true,
      disabled: true,
    },
    {
      title: "Identity No: ",
      lableAlign: "left",
      value: passportData.idNo,
      placeholder: "",
      type: "text",
      className: "mb-2",
      name: "idNo",
      required: true,
      disabled: true,
    },
  ];

  const [showViewTotalPopup, setShowViewTotalPopup] = useState(false);
  const viewTotalModal = () => {
    setShowViewTotalPopup(!showViewTotalPopup);
  };
  const [showViewInvoicePopup, setShowViewInvoicePopup] = useState(false);
  const viewInvoiceModal = () => {
    setShowViewInvoicePopup(!showViewInvoicePopup);
  };
  const [showViewStockPopup, setShowViewStockPopup] = useState(false);
  const viewStockModal = () => {
    setShowViewStockPopup(!showViewStockPopup);
  };

  const passportChangeHandler = (e) => {
    const { name, value } = e.target;
    setPassportData((passportData) => ({ ...passportData, [name]: value }));
  };
  const [discounttypes, setDiscountTypes] = useState([]);
  const [discounttype, setDiscountType] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [maxValueLimit, setMaxValueLimit] = useState(0);
  const [currentSaleId, setCurrentSaleId] = useState(0);
  const handleDiscountTypeChange = (e) => {
    let value = e.target.value;
    setDiscountType(value);
  };
  const capitalizeFirstLetter = (string) => {
    return string
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleMaxValue = (e) => {
    setMaxValue(parseFloat(e.target.value));
  };
  const handleAccessCode = (e) => {
    e.preventDefault();
    if (maxValue >= 0.1 && maxValue <= maxValueLimit) {
      DiscountAPI.applyAccessCode({
        sale_id: saleid,
        access_code: accessCode,
        value: maxValue,
      })
        .then((response) => {
          setAppliedChecked(true);
          const Message =
            response?.data?.applicable_discount_access_code?.message;
          toast.success(`Success:${Message}`);
          setAccessCode("");
          setMaxValue("");
          setMaxValueLimit("");
          setAccessCodeMessage("");

          setPopupId("");
          const updatedProductWithQuantity = response?.data.applicable_discount_access_code.sale_items.map(
            (product) => {

              return {
                product_id: product.product_id,
                product_name: product.name,
                total_tax_amount: product.total_tax_amount,
                discount: product.discount,
                qty: product.qty,
                input_field_value: product.input_field_value,
                quantity: product.quantity,
                price: product.price,
                amount: product.amount,
                ch_discount: product.ch_discount,
              };
            }
          );

          const total = {
            subtotal:
              response.data.applicable_discount_access_code.calculations
                .subtotal,
            totalItems:
              response.data.applicable_discount_access_code.calculations
                .total_items,
            totalDiscount:
              response.data.applicable_discount_access_code.calculations
                .total_discount,
            totalTax:
              response.data.applicable_discount_access_code.calculations
                .total_tax,
            totalPrice:
              response.data.applicable_discount_access_code.calculations
                .total_price,
            couponDiscount:
              response.data.applicable_discount_access_code.coupon_discount,
          };
          dispatch(updateTotals(total));
          dispatch(CouponCart(updatedProductWithQuantity));
          // setPopupId("");
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            toast.error(capitalizeFirstLetter(error.response.data.message));
        });
    } else {
      toast.error(`Error: Plaese Enter Correct Max Value!`);
      setMaxValue("");
      return;
    }
  };

  const handleVerifyAccessCode = (e) => {
    e.preventDefault();
    if (accessCode) {
      setIsCancelAccessCode(false);
      DiscountAPI.isApplicable(accessCode)
        .then((response) => {
          const errorMessage = response?.data?.message;
          setAccessCodeMessage(errorMessage);
          const maxLimit = parseFloat(response?.data?.max_limit);
          setMaxValueLimit(maxLimit);
          if (errorMessage === "not ok") {
            toast.error(`Error: Invalid Access Code!`);
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  };

  const handleAccessCodeCancel = () => {
    setIsCancelAccessCode(true);
    setPopupId("");
    setAccessCode("");
    setMaxValue("");
  };

  useEffect(() => {
    DiscountAPI.DiscountTypeAPI()
      .then((res) => {
        const filteredDiscountTypes = res.data.discount_types.filter(
          (type) => type === "coupon_sale" || type === "access_code"
        );
        setDiscountTypes(filteredDiscountTypes);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    setAppliedChecked(false);
    OrderApi.discountAppliedOnSales({ sale_id: saleid })
      .then((res) => {
        setDiscountApplied(res.data.allDiscounts);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [appliedChecked, couponDiscount, totalDiscount]);
  const handleLogout = () => {
    AuthAPI.signOut()
      .then((response) => {
        setTimeout(() => {
          toast.success(response?.data?.msg);
        }, 100);
        if (response) {
          dispatch(IsOpen(true));
          dispatch(IsClose(false));
          localStorage.removeItem("token");
          navigate("/login");
          dispatch(
            clearAllAuthData({
              currentUser: null,
              token: null,
              role_id: "",
              user_name: "",
            })
          );
          dispatch(clearCart());
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("An error Accourd");
        }
      });
  };

  const [purchaseDetailsData, setPurchaseDetailsData] = useState([]);
  const [prevDetalis, setPrevDetalis] = useState([]);

  const handleActionChange = (action, id) => {
    if (action === "details") {
      CashierApi.PreviousPurchaseHistory(id)
        .then((response) => {
          const stockData = response.data.map((data) => ({
            id: data.id,
            invoice_no: data.invoice_no,
            updated_at: data.updated_at,
            location_name: data.location_name,
            total_price: data.total_price,
          }));
          setPurchaseDetailsData(stockData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (action === "invoiceDetails") {
      CashierApi.PreviousPurchaseDetials(id)
        .then((response) => {
          const Data = response.data.map((data) => ({
            sku: data.sku,
            product_name: data.product_name,
            quantity: data.quantity,
            price: data.price,
            amount: data.amount,
          }));
          setPrevDetalis(Data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setCustomerMode(action);
  };

  const onAccess = () => {
    setPopupId("circulars");
    setValidAccess(true);
  };
  const onAccessTransfer = () => {
    setPopupId("stockOperation");
    setValidAccess(true);
  };
  const onCashToVisaAccess = () => {
    setPopupId("cashtovisa");
    setValidAccess(true);
  };

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPM = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const showTime = `${formattedHours}:${formattedMinutes} ${amPM}`;

  const handleMenu = () => {
    setMenu(!menu);
  };
  const handleRemoveDiscountApplied = (obj) => {
    setAppliedChecked(true);
    OrderApi.discountRemovedOnSales(obj)
      .then((response) => {
        toast.success(response.data?.message)
        setAppliedChecked(false)
        const total = {
          subtotal: response.data.calculations.subtotal,
          totalItems: response.data.calculations.total_items,
          totalDiscount: response.data.calculations.total_discount,
          totalTax: response.data.calculations.total_tax,
          totalPrice: response.data.calculations.total_price,
          couponDiscount: response.data.coupon_discount,
        };
        dispatch(updateTotals(total));
      })
      .catch((error) => {
        handleErrorMessage(error);
        console.log(error);
      });
  };

  const formattedDate = FormatDate(date);
  const [viewClosingDayReport, setViewClosingDayReport] = useState({
    timezoneDate: "",
    couponDiscount: "",
    totalSales: "",
    cashSales: "",
    visaSales: "",
    discount: "",
    reward: "",
    taxAmount: "",
    netSale: "",
  });
  const handleCloseCashier = (e) => {
    if (saleid) {
      toast.error("Please Complete/Cancel Pending Sale");
      return;
    }
    OrderApi.CloseCashier()
      .then((response) => {
        dispatch(IsOpen(false));
        dispatch(IsClose(true));
        if (response.data.message) {
          toast.success("Cashier Close Successfully");
        }
        if (sessionId) {
          OrderApi.ViewClosingDayReport(
            defaultLoc,
            formattedDate,
            sessionId
          ).then((response) => {
            const responseData = response.data;
            const updateDate = responseData.updated_at ||  responseData.return_updated_at;
            setViewClosingDayReport({
              timezoneDate: updateDate,
              couponDiscount: responseData.coupon_discount,
              totalSales: responseData.total_sale_price,
              cashSales: responseData.total_cash_received,
              visaSales: responseData.total_visa_received,
              discount: responseData.total_discount,
              reward: responseData.total_reward,
              taxAmount: responseData.total_tax,
              netSale: responseData.net_sale,
              refund: responseData.return,
            });
            handleModal(e);
            dispatch(SessionId(null));
          });
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("Please Create Sale");
        }
      });
  };

  const handleOpenCashier = () => {
    dispatch(IsOpen(true));
    dispatch(IsClose(false));
    OrderApi.OpenCashier()
      .then((response) => {
        if (response.data.message) {
          toast.success("Cashier Open Successfully");
        }
      })
      .catch((error) => {
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const handleBarcodeRead = (result) => {
    if (!searchingProduct) {
      playActive();
      handleBarcodeInputChange("", result);
    }
  };
  return (
    <div className="cashier-ui-main p-4 bg-[#ffffff] min-h-[100vh] overflow-hidden sm:overflow-y-auto">
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
      <ShowComponent condition={popupId === "printBill"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[27%]">
          <PrintBill contentOnly={true} setPopupId={setPopupId} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "DiscountOffers"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-1/2">
          <DiscountOffers contentOnly={true} setPopupId={setPopupId} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "printBillResponse"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[27%]">
          <Invoice
            recipedata={recipedata}
            saleitems={saleitems}
            paymentMethod={paymentMethod}
            itemTax={itemtax}
            setPopupId={setPopupId}
            isRedux={true}
          />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "cashtovisa"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <CashToVisa contentOnly={true} setPopupId={setPopupId} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "stockOperation"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[70%]">
          <CreateTransfer contentOnly={true} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "taxFree"}>
        <Popup
          showpopup={showpopup}
          setPopup={() => setPopupId("")}
          className="w-full sm:w-1/2"
        >
        <TaxFree
        passportInputs={passportInputs}
        handleTaxfree={handleTaxfree}
        closeTaxFree={closeTaxFree}
        passportChangeHandler={passportChangeHandler}
        />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "menu"}>
        <Popup
          showpopup={showpopup}
          setPopup={() => setPopupId("")}
          className="w-full sm:auto sm:w-1/2"
        >
          <div className="sales-category-slick-slider">
            <SlickCarouselWithPagination>
              <Button
                onClick={AllCategoryClicked}
                className={`${
                  selectedCategoryIds == null
                    ? "bg-blue mr-2 text-white"
                    : "bg-slate-100 mr-2 text-dark"
                }`}
              >
                All
              </Button>
              {categories.map((obj) => (
                <Button
                  key={obj.category_id}
                  className={`${
                    selectedCategoryIds === obj.category_id
                      ? "bg-blue mr-2 text-white"
                      : "bg-slate-100 mr-2 text-dark"
                  }`}
                  style={{ borderRadius: "9px" }}
                  onClick={() => handleCategorySelect(obj)}
                >
                  {obj.category_name}
                </Button>
              ))}
            </SlickCarouselWithPagination>
          </div>
          <div
            className="sytlecreatesalegap w-full grid grid-cols-3 xl:grid-cols-3 sm:gap-4 gap-2 h-[85vh]  sm:h-[60vh] overflow-y-auto">
            {allproducts.length === 0 ? (
              <>
                <p></p>
                <p className="mt-5 text-center justify-center">
                  No Product Available
                </p>
              </>
            ) : (
              allproducts.map((obj) => (
                <div
                  className="sytlecreatesale sytlecreatesalegap bg-slate-100 rounded-md p-2 text-center"
                  key={obj.product_id}
                  disabled={dataLoading ? true : false}
                  onClick={() => handleProductSelect(obj)}
                >
                  <p className="sytlecreatesaleunset text-left leading-4">
                    {obj.product_name}
                  </p>
                  <img
                    style={{
                      maxHeight: "60px",
                      margin: "10px auto",
                    }}
                    src={obj.get_avatar}
                    alt={" "}
                    // alt={obj.product_name}
                  />
                  <p className="sytlecreatesaleunset bg-blue text-white rounded-xl leading-5 text-12">
                    {obj.price}
                  </p>
                </div>
              ))
            )}
          </div>
          <div className="modal-footer mt-3 text-right">
            <Button
              type="Button"
              className="bg-red inline-block"
              onClick={() => setPopupId("")}
            >
              Close
            </Button>
          </div>
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "finishSale"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[60%]">
          <ConfirmationSale
            showpopup={showpopup}
            setShowPopUp={setShowPopUp}
            totalbill={totalPrice}
            setCash={setCash}
            setStatus={setStatus}
            tableData={tableData}
            cash={cash}
            cashAmount={cashAmount}
            cardAmount={cardAmount}
            setCardAmount={setCardAmount}
            setCashAmount={setCashAmount}
            returnAmount={returnAmount}
            setReturnAmount={setReturnAmount}
            status={status}
            handleCreateOrder={handleCreateOrder}
            setPopupId={setPopupId}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            approvalCode={approvalCode}
            setApprovalCode={setApprovalCode}
            setTempModel={setTempModel}
          />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "customer"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[60%]">
          <div className="h-full relative">
            <ShowComponent condition={customerMode === "newCustomer"}>
              <CreateCustomer
                ClassName={showAddCustomerPopup ? "p-3 " : ""}
                setPopupId={setPopupId}
                onCustomerCreated={handleCustomerCreated}
                contentOnly={true}
                setCustomerMode={setCustomerMode}
                filterPhoneNumber={filterPhoneNumber}
              />
            </ShowComponent>
            <ShowComponent condition={customerMode === "edit"}>
              <UpdateCustomer
                ClassName={showUpdateCustomerPopup ? "p-3 " : ""}
                setPopupId={setPopupId}
                onCustomerUpdated={handleCustomerUpdated}
                contentOnly={true}
                setCustomerMode={setCustomerMode}
              />
            </ShowComponent>
            <ShowComponent condition={customerMode === "search"}>
              <div className="overflow-x-auto shadow-md mt-1 w-full min-h-[420px] max-h-[420px] overflow-y-auto">
                <table className="relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 w-full">
                  <thead className="sticky top-[-1px] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-2 border-[#c3c2c2]">
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        CUSTOMER NAME
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        MOBILE #
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        ADDRESS
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!getCustomerData.customerId ? (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 ">
                        <td colSpan={7} className="border-l-2 px-1 py-2">
                          <div className="text-center">No Data Available</div>
                        </td>
                      </tr>
                    ) : (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer">
                        <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                          {getCustomerData.customerName}
                        </td>
                        <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                          {getCustomerData.customerPhone}
                        </td>
                        <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                          {getCustomerData.customerAddress}
                        </td>
                        <td className="border-l-2 border-r-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                          <div className="flex justify-center items-center">
                            <button
                              className="fas fa-eye"
                              onClick={() =>
                                handleActionChange(
                                  "details",
                                  getCustomerData.customerId
                                )
                              }
                            ></button>
                            <button
                              className="ml-4 fas fa-edit"
                              onClick={() =>
                                handleActionChange(
                                  "edit",
                                  getCustomerData.customerId
                                )
                              }
                            ></button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </ShowComponent>
            <ShowComponent condition={customerMode === "details"}>
              <div className="overflow-x-auto shadow-md mt-1 w-full min-h-[420px] max-h-[420px] overflow-y-auto">
                <table className="relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 w-full">
                  <thead className="sticky top-[-1px] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-2 border-[#c3c2c2]">
                      <th className="px-1 py-2">Invoice No</th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        Invoice Date
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        Branch
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        Net Ammount / VAT
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        aCTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!purchaseDetailsData.length ? (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 ">
                        <td colSpan={4} className="border-l-2 px-1 py-2">
                          <div className="text-center">No Data Available</div>
                        </td>
                      </tr>
                    ) : (
                      purchaseDetailsData.map(
                        ({
                          id,
                          invoice_no,
                          updated_at,
                          location_name,
                          total_price,
                        }) => (
                          <tr
                            key={id}
                            className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 hover:bg-gray-300 border-b dark:border-gray-700 cursor-pointer"
                          >
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {invoice_no}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {FormatDate(updated_at)}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {location_name}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {total_price}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              <button
                                onClick={() =>
                                  handleActionChange("invoiceDetails", id)
                                }
                              >
                                Details
                              </button>
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </ShowComponent>
            <ShowComponent condition={customerMode === "invoiceDetails"}>
              <div className="overflow-x-auto shadow-md mt-1 w-full min-h-[420px] max-h-[420px] overflow-y-auto">
                <table className="relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 w-full">
                  <thead className="sticky top-[-1px] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-2 border-[#c3c2c2]">
                      <th className="px-1 py-2">Product Code</th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        Item Dec
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        QTY
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        U.Price
                      </th>
                      <th className="border-l-2 border-l-[#c3c2c2] px-1 py-2">
                        Total Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!prevDetalis.length ? (
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 ">
                        <td colSpan={4} className="border-l-2 px-1 py-2">
                          <div className="text-center">No Data Available</div>
                        </td>
                      </tr>
                    ) : (
                      prevDetalis.map(
                        ({ sku, product_name, quantity, price, amount }) => (
                          <tr
                            key={sku}
                            className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 hover:bg-gray-300 border-b dark:border-gray-700 cursor-pointer"
                          >
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {sku}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {product_name}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {quantity}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {price}
                            </td>
                            <td className="border-l-2 border-l-[#c3c2c2] px-1 py-2 text-black">
                              {amount}
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </ShowComponent>
            <div className="absolute bottom-0 flex justify-start items-center gap-1">
              <ShowComponent condition={customerMode === "search"}>
                <input
                  type="text"
                  className="form-control hideNumberArrow w-2/3 md:w-64 lg:w-96"
                  placeholder="Phone No"
                  value={filterPhoneNumber}
                  onChange={(e) => setFilterPhoneNumber(e.target.value)}
                  onFocus={() => setShowSearchedCustomers(true)}
                />
                <Button
                  className="w-1/3 mb-[0.5rem]"
                  name="search"
                  onClick={handleCustomerSearch}
                >
                  Refresh
                </Button>
              </ShowComponent>
              <ShowComponent condition={customerMode === "newCustomer"}>
                <Button
                  className="w-full mt-4"
                  name="search"
                  onClick={() => setCustomerMode("search")}
                >
                  Search customer
                </Button>
              </ShowComponent>
              <ShowComponent condition={customerMode === "search"}>
                <Button
                  className="w-1/3 mb-[0.5rem]"
                  name="newCustomer"
                  onClick={() => setCustomerMode("newCustomer")}
                >
                  New Customer
                </Button>
              </ShowComponent>
              <ShowComponent condition={customerMode === "search"}>
                <Button
                  className="w-1/3 mb-[0.5rem] bg-red"
                  name="newCustomer"
                  onClick={() => setPopupId("")}
                >
                  Close
                </Button>
              </ShowComponent>
              <ShowComponent condition={customerMode === "details"}>
                <Button
                  className="w-full mb-[0.5rem]"
                  name="newCustomer"
                  onClick={() => setCustomerMode("search")}
                >
                  Go Back
                </Button>
              </ShowComponent>
              <ShowComponent condition={customerMode === "invoiceDetails"}>
                <Button
                  className="w-full mb-[0.5rem]"
                  name="newCustomer"
                  onClick={() => setCustomerMode("details")}
                >
                  All Invoices
                </Button>
              </ShowComponent>
            </div>
          </div>
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "discount"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <div className="w-full">
            <label htmlFor="Discounttype" className="text-[14px] font-bold">
              Discount Type
            </label>
            <select
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={discounttype}
              onChange={(e) => handleDiscountTypeChange(e)}
            >
              <option value="access_code">Access Code</option>
            </select>
          </div>
          <div className="flex mt-4">
            {(accessCodeMessage === "not ok" ||
              accessCodeMessage === "" ||
              isCancelAccessCode) && (
              <>
                <div className="sm:w-1/2 bg-white rounded-3xl p-3">
                  <label className="text-[14px] font-bold">Access Code</label>
                  <input
                    className="form-control rounded mt-2 mb-4 hideNumberArrow"
                    name="Access Code"
                    type="number"
                    value={accessCode}
                    placeholder="Enter Access Code"
                    onChange={(e) => {
                      setAccessCode(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="flex justify-end">
                  <Button
                    onClick={handleVerifyAccessCode}
                    type="button"
                    className="bg-orange  mb-5 mt-9 ml-5 p-2 py-3"
                  >
                    Verify Access Code
                  </Button>
                </div>
              </>
            )}
            {maxValueLimit > 0 && !isCancelAccessCode && (
              <div className="sm:w-1/2 bg-white rounded-3xl p-3">
                <label className="text-[14px] font-bold">
                  Max Value(Between 0.1 to {maxValueLimit})
                </label>
                <input
                  className="form-control rounded mt-2 mb-2 hideNumberArrow"
                  name="Max Value"
                  type="number"
                  value={maxValue}
                  placeholder="Enter Value"
                  onChange={handleMaxValue}
                  required
                />
                <div className="flex justify-end">
                  <Button
                    onClick={handleAccessCode}
                    type="button"
                    className="bg-orange mt-3 p-2 py-3"
                  >
                    Apply
                  </Button>
                  <Button
                    type="Button"
                    className="bg-red mt-3 ml-4 p-2 py-3"
                    onClick={handleAccessCodeCancel}
                  >
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
          {discountApplied?.find(
            (element) => element.discount_type === "access_code"
          ) && (
            <>
              {discountApplied
                .filter((obj) => obj.discount_type === "access_code")
                .map((obj) => (
                  <div
                    key={obj.id}
                    className="stylecreatesale stylecreatesalegap w-[30%] justify-between flex border-solid border-2 border-gray-400 rounded-md p-2 text-center"
                  >
                    <div className="w-[70%] text-center">
                      {obj.discount_title}
                    </div>
                    <div className="w-[30%] text-center">
                      <span
                        className="text-[#287DFD]"
                        onClick={() => handleRemoveDiscountApplied(obj)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="red"
                          height="16"
                          width="14"
                          viewBox="0 0 448 512"
                        >
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                ))}
            </>
          )}
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "Vouchers"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <div className="w-full">
            <label htmlFor="Discounttype" className="text-[14px] font-bold">
              Discount Type
            </label>
            <select
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={discounttype}
              onChange={(e) => handleDiscountTypeChange(e)}
            >
              <option value="coupon_sale">Coupon Sale</option>
            </select>
          </div>
          <div className="flex mt-4">
            <div className="sm:w-1/2 bg-white rounded-3xl p-3">
              <label className="text-[14px] font-bold">Coupon Code</label>
              <input
                className="form-control rounded mt-2 mb-2 hideNumberArrow"
                name="Discount Title"
                value={coupon}
                placeholder="Enter Coupon Code"
                onChange={(e) => {
                  setCoupon(e.target.value);
                }}
                required
              />
            </div>
            <div className="sm:w-1/2 bg-white rounded-3xl p-3 mt-2 mb-2">
              <div className="flex justify-end">
                <Button
                  onClick={handleCouponApply}
                  type="button"
                  className="bg-orange mt-8 p-2 py-3"
                >
                  Apply
                </Button>
                <Button
                  type="Button"
                  className="bg-red ml-4 mt-8 p-2 py-3"
                  onClick={() => setPopupId("")}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
          <div>
            {discountApplied?.find(
              (element) => element.discount_type === "coupon_sale"
            ) && (
              <>
                {discountApplied
                  .filter((obj) => obj.discount_type === "coupon_sale")
                  .map((obj) => (
                    <div
                      key={obj.id}
                      className="stylecreatesale stylecreatesalegap w-[30%] justify-between flex border-solid border-2 border-gray-400 rounded-md p-2 text-center"
                    >
                      <div className="w-[70%] text-center">
                        {obj.discount_title}
                      </div>
                      <div className="w-[30%] text-center">
                        <span
                          className="text-[#287DFD]"
                          onClick={() => handleRemoveDiscountApplied(obj)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            height="16"
                            width="14"
                            viewBox="0 0 448 512"
                          >
                            <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "closeCashier"}>
        <Popup setPopup={() => setPopupId("")} className="w-[450px]">
          <div className="border-[1px] border-gray-400 p-5">
            <div className="flex flex-col">
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                Branch ID: {defaultLoc}
              </button>
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                Inventory ID: 2
              </button>
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                POS ID: 1
              </button>
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                Test Head Office
              </button>
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                {FormatDate(viewClosingDayReport.timezoneDate)}
              </button>
              <button className="bg-[#b6c9ee] w-full h-[30px] border-[1px] border-gray-400">
                {showTime}
              </button>
            </div>
            <div className="mt-4">
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">
                  Total Sales(Includes Tax)
                </div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.totalSales
                    ? "0.0"
                    : viewClosingDayReport.totalSales}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">Cash Sales</div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.cashSales
                    ? "0.0"
                    : viewClosingDayReport.cashSales}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">Visa Sales</div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.visaSales
                    ? "0.0"
                    : viewClosingDayReport.visaSales}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">Discount Amount</div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.discount
                    ? "0.0"
                    : viewClosingDayReport.discount}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">Bonus</div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.reward
                    ? "0.0"
                    : viewClosingDayReport.reward}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">Reward Amount</div>
                <div class="flex items-center mr-3">
                  {"0.0"}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">
                  Return Sales(Includes Tax)
                </div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.refund
                    ? "0.0"
                    : viewClosingDayReport.refund}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-b-0 border-gray-400">
                <div class="flex items-center ml-3">TAX Amount</div>
                <div class="flex items-center mr-3">
                  {!viewClosingDayReport.taxAmount
                    ? "0.0"
                    : viewClosingDayReport.taxAmount}
                </div>
              </div>
              <div class="flex justify-between bg-[#b6c9ee] w-full h-[30px] border-[1px] border-gray-400">
                <div class="flex items-center ml-3">
                  Net Sales(Includes Tax)
                </div>
                <div class="flex items-center m-3">
                  {!viewClosingDayReport.netSale
                    ? "0.0"
                    : viewClosingDayReport.netSale}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <button
              class="bg-[#b6c9ee] font-bold py-2 px-4 border-2 border-gray-400"
              onClick={() => setPopupId("")}
            >
              OK
            </button>
            <button
              class="bg-[#b6c9ee] font-bold py-2 px-4 border-2 border-gray-400"
              onClick={() => setPopupId("")}
            >
              Cancel
            </button>
          </div>
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "viewTotal"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-auto">
          <ViewTotal />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "viewInvoice"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-auto">
          <ViewInvoice checkable={true} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "returnSale"}>
        <Popup
          setPopup={() => setPopupId("")}
          className="w-full overflow-auto sm:w-auto"
        >
          <ReturnSale />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "viewStock"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <ViewStock />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "circulars"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[50%]">
          <Circular contentOnly={true} uploadAble={false} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "accessCode"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[40%]">
          <AccessCode onAccess={onAccess} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "accessCodeTransfer"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[40%]">
          <AccessCode onAccess={onAccessTransfer} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "onCashToVisaAccess"}>
        <Popup setPopup={() => setPopupId("")} className="w-full sm:w-[40%]">
          <AccessCode onAccess={onCashToVisaAccess} />
        </Popup>
      </ShowComponent>
      <ShowComponent condition={popupId === "barcodeScanner"}>
        <Popup
          setPopup={() => setPopupId("")}
          className="w-full sm:w-[80%] flex justify-center items-center"
        >
          <QrScanner
            onDecode={(result) => handleBarcodeRead(result)}
            onError={(error) => console.log(error?.message)}
            containerStyle={{ width: "300px", height: "300px", padding: "0", margin: "0 auto" }}
            audio={false}
          />
        </Popup>
      </ShowComponent>
      <div className="cashier-ui-inner sm:flex h-full relative sm:static">
        <div
          className={`cashier-ui-left ${
            menu ? "opacity-0" : "opacity-100"
          } flex-1 relative h-full sm:pr-4 transition-all`}
        >
          <div className="cashier-ui-header flex flex-wrap justify-between mb-2">
            <div className="w-full flex justify-center items-center sm:block sm:w-auto">
              <img
                className="max-w-[120px] sm:ml-[90px]"
                src="/assets/img/cunzite.png"
                alt="logo"
              />
            </div>
            <div className="text-[9px] sm:text-[16px] md:text-base lg:text-md">
              <button
                className="bg-[#81587e] rounded text-white px-1 sm:px-0 w-[73px] h-[30px] sm:h-[50px]"
                onClick={handleModal}
                name="viewTotal"
              >
                VIEW TOTALS
              </button>
              <button
                className="bg-[#81587e] rounded text-white px-1 sm:px-0 w-[79px] h-[30px] sm:h-[50px] ml-[2px]"
                onClick={handleModal}
                name="viewInvoice"
              >
                VIEW INVOICES
              </button>
              <button
                className="bg-[#81587e] rounded text-white px-1 sm:px-0 w-[73px] h-[30px] sm:h-[50px] ml-[2px]"
                onClick={handleModal}
                name="viewStock"
              >
                VIEW STOCK
              </button>
              <button
                className="bg-[#81587e] rounded text-white px-1 sm:px-0 w-[73px] h-[30px] sm:h-[50px] ml-[2px]"
                onClick={handleModal}
                name={!validAccess ? "onCashToVisaAccess" : "cashtovisa"}
              >
                CASH TO VISA
              </button>
            </div>
            <button
              className="bg-slate-200 h-[30px] sm:hidden w-[30px] rounded"
              onClick={handleMenu}
            >
              <i class="fa fa-bars"></i>
            </button>
          </div>
          <div className="cashier-ui-actions flex justify-between">
            <div>
              <input
                className="border-2 border-[#c3c2c2] w-[170px] sm:w-[360px] h-[35px] px-2 mb-2 rounded"
                type="text"
                min={0}
                placeholder="Search by barcode or sku"
                value={barcode}
                onKeyDown={handleBarcodeKeyDown}
                onChange={handleBarcodeInputChange}
              />
              <button
                className="bg-[#81587e] text-white w-[80px] h-[35px] ml-[1px] rounded"
                name="menu"
                onClick={handleModal}
              >
                MENU
              </button>
              <button
                className="bg-[#81587e] text-white w-[80px] h-[35px] ml-[1px] rounded"
                name="barcodeScanner"
                onClick={handleModal}
              >
                SCANNER
              </button>
            </div>
          </div>
          <div className="flex flex-wrap h-auto sm:h-[calc(100vh-145px)]">
            <div className="overflow-x-auto shadow-md mt-1 w-full max-w-[calc(100vw-20px)] min-h-[395px] max-h-[395px] overflow-y-auto">
              <table className="relative text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 w-full overflow-hidden">
                <thead className="sticky top-[-1px] text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="border-2 border-[#c3c2c2]">
                    <th className="px-1 py-1 w-[90px]">ITEM NO</th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[300px] px-1 py-1">
                      ITEM NAME
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1">
                      UNIT
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1">
                      QTY
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1">
                      U.PRICE
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1">
                      BONUS
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1">
                      TOTAL
                    </th>
                    <th className="border-l-2 border-l-[#c3c2c2] w-[90px] px-1 py-1"></th>
                    <th className="border-x-2 border-x-[#c3c2c2] w-[90px] px-1 py-1">
                      CH DIS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <div > */}
                  {tableData.length === 0 ? (
                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 ">
                      <td colSpan={9} className="border-l-2 px-1 py-2">
                        <div className="text-center">No Data Available</div>
                      </td>
                    </tr>
                  ) : (
                    tableData.map((product) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 text-black text-center w-[90px]">
                            {product.product_id}
                          </td>
                          <td
                            // colspan="4"
                            className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[300px] text-black"
                          >
                            {product.product_name}
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] text-black w-[90px] flex justify-between items-center">
                            <button
                              className="bg-[#81587e] text-white ml-[1px] w-[20px] h-[20px] cursor-pointer"
                              onClick={(e) =>
                                handleQtyChange(product, product.qty - 1)
                              }
                              disabled={product.qty == 1 || dataLoading}
                            >
                              Q-
                            </button>
                            <input
                              className="hideNumberArrow text-center h-[20px] w-[40px] text-black"
                              type="number"
                              value={product.input_field_value}
                              onChange={(e) =>
                                handleQtyChange(product, e.target.value)
                              }
                              onBlur={(e) =>
                                handleOnBlurQuantity(product, e.target.value)
                              }
                            />
                            {/* {product.quantity} */}
                            <button
                              className="bg-[#81587e] text-white ml-[1px] w-[20px] h-[20px]"
                              onClick={() => handleProductSelect(product)}
                              disabled={
                                product.qty > product.quantity || dataLoading
                              }
                            >
                              Q+
                            </button>
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[90px] text-center text-black">
                            {product.quantity}
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[90px] text-center text-black">
                            {product.price}
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[90px] text-center text-black">
                            NA
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[90px] text-center text-black">
                            {product.amount}
                          </td>
                          <td className="border-l-2 border-l-[#c3c2c2] px-1 py-1 w-[90px] text-black">
                            <span
                              className="text-[#287DFD]"
                              onClick={() => removeProductFromTable(product)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="red"
                                height="16"
                                width="14"
                                viewBox="0 0 448 512"
                              >
                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                              </svg>
                            </span>
                          </td>
                          <td className="border-x-2 border-x-[#c3c2c2] px-1 py-1 w-[90px] text-center text-black">
                            {product.ch_discount}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="cashier-ui-footer bottom-0 flex flex-col-reverse gap-4 md:flex-row justify-between md:items-end w-full pt-4">
              <div className="cashier-footer-left">
                <span className="font-bold">CUSTOMER</span>
                {getCustomerData.customerId && (
                  <>
                    <input
                      type="text"
                      class="ml-3 px-2 h-[35px] border-2 border-[#c3c2c2] w-[33%]"
                      value={getCustomerData.customerName}
                    />
                    <input
                      type="text"
                      class="ml-3 px-2 h-[35px] border-2 border-[#c3c2c2] w-[33%]"
                      value={getCustomerData.customerPhone}
                    />
                  </>
                )}
                <div className="flex mt-1">
                  <div className="flex flex-wrap w-[290px]">
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      onClick={handleModal}
                      name="DiscountOffers"
                    >
                      OFFERS
                    </button>
                    <button className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]">
                      SPECIAL OFFERS
                    </button>
                    <button className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]">
                      ORDER
                    </button>
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      onClick={handleResetClick}
                    >
                      CANCEL SALE
                    </button>
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      name="discount"
                      onClick={handleModal}
                    >
                      DISCOUNT
                    </button>
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      name="customer"
                      onClick={handleModal}
                    >
                      CUSTOMERS
                    </button>
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      onClick={handleModal}
                      name="printBill"
                    >
                      PRINT BILL
                    </button>
                    <button
                      className="bg-[#81587e] text-white d h-[40px] w-[50%] border border-[#c3c2c2]"
                      onClick={handleModal}
                      name="returnSale"
                    >
                      RETURN SALE
                    </button>
                  </div>
                  <button
                    className="bg-[#cb9169] text-white font-bold px-4"
                    name="finishSale"
                    onClick={handleModal}
                  >
                    FINISH SALE
                  </button>
                </div>
              </div>
              <div className="cashier-footer-right border border-[#c3c2c2]">
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span className="sm:mr-[180px]">SUBTOTALS</span>
                  <span>{numeral(subtotal).format("0[.]00")}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>DISCOUNT</span>
                  <span>{numeral(totalDiscount).format("0[.]00")}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>VOUCHERS</span>
                  <span>{couponDiscount}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>VAT</span>
                  <span>{numeral(totalTax).format("0[.]00")}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>NET</span>
                  <span>NA</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>CASH</span>
                  <span>NA</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>VISA</span>
                  <span>NA</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>TOTAL ITEMS</span>
                  <span>{numeral(totalItems).format("0[.]")}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>TOTAL</span>
                  <span>{numeral(totalPrice).format("0[.]00")}</span>
                </div>
                <div className="border border-b-[#c3c2c2] p-1 flex justify-between items-center">
                  <span>REMAIN</span>
                  <span>NA</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute ${
            menu
              ? "right-0 opacity-100"
              : "right-[-110%] opacity-0 sm:opacity-100"

          } top-0 w-full sm:w-[200px] h-full bg-[#ffffff] sm:min-h-[calc(100vh-32px)] sm:static flex flex-col justify-start sm:justify-between sm:pt-0 transition-all`}
        >
          <div
            className={` ${
              menu
                ? "right-0 opacity-100"
                : "right-[-110%] opacity-0 sm:opacity-100"
            } top-0 w-full bg-[#ffffff] pb-3 text-right sm:hidden transition-all`}

            onClick={handleMenu}
          >
            CLOSE X
          </div>
          <div>
            <div className="cashier-ui-top flex flex-col mb-3">
              <button className="bg-[#cb9169] text-white w-full h-[30px] mb-[1px]">
                User: {capitalizeFirstLetter(userName)}
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                Terminal (POS ID: 1)
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                Branch ID: {defaultLoc}
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                Inventory ID: 2
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                Test Head Office
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                {FormatDate(date)}
              </button>
              <button className="bg-[#81587e] text-white w-full h-[30px] mb-[1px]">
                {showTime}
              </button>
            </div>
            <div className="cashier-ui-middle flex flex-wrap">
              <button
                className={`text-${
                  isOpen ? "black" : "white"
                } bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base`}
                onClick={handleOpenCashier}
              >
                Open Cashier
              </button>
              <button
                className={`text-${
                  isClose ? "black" : "white"
                } bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base`}
                name="closeCashier"
                onClick={handleCloseCashier}
              >
                Close Cashier
              </button>
              <button className="text-white bg-[#cb9169] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Card Holder
              </button>
              <button
                className="text-white bg-[#cb9169] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base"
                name="Vouchers"
                onClick={handleModal}
              >
                Vouchers
              </button>
              <button className="text-white bg-[#cb9169] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Complaints
              </button>
              <button
                className="text-white bg-[#cb9169] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base"
                name={!validAccess ? "accessCode" : "circulars"}
                // name={"accessCode"}
                onClick={handleModal}
              >
                Circulars
              </button>
              <button className="text-white bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Cards
              </button>
              <button className="text-white bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Branch Stock
              </button>
              <button
                className="text-white bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base"
                name={!validAccess ? "accessCodeTransfer" : "stockOperation"}
                onClick={handleModal}
              >
                Stock Operations
              </button>
              <button className="text-white bg-[#81587e] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Vip Cards
              </button>
              <button
                className="text-black bg-[#fff0a3] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base"
                name="taxFree"
                onClick={handleModal}
              >
                VAT Free
              </button>
              <button className="text-black bg-[#fff0a3] w-[50%] border border-[#c3c2c2] mb-[1px] h-[50px] text-base">
                Wholesale
              </button>
            </div>
          </div>
          <div className="cashier-ui-bottom py-4 w-full flex gap-[1px]">
            <button
              className="bg-[#81587e] text-white w-[50%] h-[50px]"
              onClick={handleLogout}
            >
              SIGNOUT
            </button>
            <button
              className="bg-[#81587e] text-white w-[50%] h-[50px]"
              onClick={handleLogout}
            >
              SWITCH USER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CashierUi;
