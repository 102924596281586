import React from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AppWrapper from "Components/Routes/AppWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminSideBar from "Components/Common/AdminSideBar";
import { toast } from "react-toastify";
import Button from "Components/Common/Button";
import Loader from "../../assets/svg/Loader.svg";

function ProductCommissionUpdate() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [buttonShow, setButtonShow] = useState(true);
  const navigate = useNavigate();

  const [saleCommissionData, setSaleCommissionData] = useState({
    value: "",
    commisionType: "",
    name: "",
    product_id: "",
    discountValue: "",
  });
  useEffect(() => {
    if (window.location.pathname.includes("commission/details")) {
      setButtonShow(false);
    }
    ConfigurationAPI.viewProductCommission({ id })
      .then((response) => {
        setLoading(false);
        const Data = response.data.sale_product_commision_configuration;
        setSaleCommissionData({
          name: Data.product_name,
          product_id: Data.product_id,
          value: Data.value,
          commisionType: Data.commision_type,
          discountValue: Data.discount_value,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);
  const handleUpdateSaleCommission = (e) => {
    e.preventDefault();
    if (
      saleCommissionData.commisionType === "percentage" &&
      parseFloat(saleCommissionData.value) > 100
    ) {
      setLoading(false);
      toast.error("Percentage value cannot be greater than 100");
      return;
    }
    setLoading(true);
    ConfigurationAPI.updateProductCommission(id, {
      sale_product_commision_configuration: {
        value: parseFloat(saleCommissionData.value),
        product_id: saleCommissionData.product_id,
        commision_type: saleCommissionData.commisionType,
        discount_thresh: parseFloat(saleCommissionData.discountValue),
        discount_thresh_type: "percentage",
      },
    })
      .then((response) => {
        setLoading(false);
        const Message = response ? response.data.message : "An error occurred";
        toast.success(`Success:${Message}`);
        setTimeout(() => {
          if (!response.data.error) {
            navigate("/all-commission");
          }
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response.data.message);
      });
  };

  const handleBack = () => {
    navigate("/all-commission");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between my-4">
          <div className="flex items-center text-24 font-semibold">
            Update Product Commission
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img src={Loader} className="rounded-full m-auto" alt="Loading" />
          </div>
        ) : (
          <>
            <div className="pt-4">
              <div className="border border-t-2 rounded-xl p-5 bg-white">
                <form onSubmit={handleUpdateSaleCommission}>
                  <div className="flex">
                    <div className="w-[20%] mr-4">
                      <div className="mb-2">Product Name</div>
                      <div>
                        <input
                          readOnly
                          value={saleCommissionData.name}
                          type="text"
                          className="form-control hideNumberArrow"
                        />
                      </div>
                    </div>
                    <div className="w-[20%] mr-4">
                      <div className="mb-2">Commission Value</div>
                      <div>
                        <input
                          value={saleCommissionData.value}
                          type="number"
                          onChange={(e) =>
                            setSaleCommissionData((prevState) => ({
                              ...prevState,
                              value: e.target.value,
                            }))
                          }
                          className="form-control hideNumberArrow"
                        />
                      </div>
                    </div>
                    <div className="w-[20%] mr-4">
                      <div className="mb-2">Commission Type</div>
                      <div>
                        <select
                          value={saleCommissionData.commisionType}
                          required
                          onChange={(e) =>
                            setSaleCommissionData((prevState) => ({
                              ...prevState,
                              commisionType: e.target.value,
                            }))
                          }
                          className="form-control"
                        >
                          <option value="">Select Type</option>
                          <option value="amount">Amount</option>
                          <option value="percentage">Percentage</option>
                        </select>
                      </div>
                    </div>
                    <div className="w-[20%] mr-4">
                      <div className="mb-2">Discount Value</div>
                      <div>
                        <input
                          className="form-control"
                          value={saleCommissionData.discountValue}
                          required
                          onChange={(e) =>
                            setSaleCommissionData((prevState) => ({
                              ...prevState,
                              discountValue: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="w-[20%] mt-5">
                      {buttonShow && (
                        <button
                          className={`${
                            loading
                              ? "cursor-not-allowed btn-primary w-[70%]"
                              : " btn-primary w-[70%]"
                          }`}
                          type="submit"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default ProductCommissionUpdate;
