import React, { useState } from "react";
import FormatDate from "./FormatDate";
import ActionsRender from "./ActionsRender";
import Loader from "../../assets/svg/Loader.svg";
const Table = ({ data, actions, loading, onSendClick }) => {
  const closeMenu = (menu) => {
    if (menu) {
      menu.style.display = "none";
    }
  };

  const handleCursorMovement = (menu) => {
    if (menu) {
      menu.removeEventListener("mouseleave", () => closeMenu(menu));
      menu.addEventListener("mouseleave", () => closeMenu(menu));
    }
  }; 

  const capitalizeAndReplaceUnderscores = (str) => {
    if (typeof str === 'string') {
      return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    } else {
      return str;
    }
  };

  const headers =
    data.length > 0
      ? Object.keys(data[0]).filter((header) => header !== "category_id")
      : [];

  const renderHeader = (header, index) => (
    <div
      key={index}
      className={`relative ${
        index === 0 ? "w-[10%]" : "w-1/4"
      } text-blue border-box ml-2 text-wrap break-all text-center`}
    >
      {header}
      {/* <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 gap-0"/> */}
    </div>
  );

  const renderCell = (item, header, headerIndex) => {
    let cellContent;
    if (header === "avatar_url") {
      return (
        <div
          key={headerIndex}
          className={`${
            headerIndex === 0 ? "w-[10%]" : "w-1/4"
          } border-box  ml-2 text-wrap break-all text-center`}
        >
        <img
          src={item[header]}
          alt="Avatar"
          className="w-10 h-10 rounded-full"
        />
        </div>
      );
    } else if (header === "card_img"){
      const divClasses = `${
        headerIndex === 0 ? "w-[10%]" : "w-full"
      } border-box ml-2 text-wrap break-all text-center ${
        header === "card_img" ? "flex justify-center items-center" : ""
      }`;
      cellContent = (
        <div key={headerIndex} className={divClasses}>
          <img
            src={item[header]}
            alt={header === "card_img" ? "Card Image" : ""}
            className="w-20 h-10"
          />
        </div>
      );  
    } else if (
        [
          "created_at",
          "invitation_sent_at",
          "invitation_accepted_at",
          "updated_at",
          "issue_date",
          "expiry_date",
          "joining_date",
          "termination_date",
          "warranty_expiry_date",
          "id_expiry_date",
          "probation_end",
          "purchase_date",
          "dob",
          "start_date",
          "end_date",
        ].includes(header)
    ) {
      cellContent = item[header] ? FormatDate(item[header]) : "N/A";
    } else if (header === "description") {
      cellContent =
        item[header].length > 10 ? item[header].substring(0, 5) + "..." : item[header];
    } else if (header === "active") {
      cellContent = item[header] ? "Active" : "Inactive";
    } else if (
      header === "default" ||
        header === "invitation_accepted" ||
        header === "track_attendance" ||
        header === "required" ||
        header === "issue_date" ||
        header === "expiry_date" ||
        header === "document_no" ||
        header === "has_image" ||
        header === "expiry_reminders" ||
        header === "temporary_contract"
    ) {
      cellContent = item[header] ? "Yes" : "No";
    } else if (header === "reinvite_enable") {
      cellContent = item[header] ? (
        <button
          onClick={() => onSendClick(item.id)}
          className="w-16 h-6 bg-blue text-white rounded"
        >
          Resend
        </button>
      ) : "accepted";
    } else if (typeof item[header] === "object") {
      cellContent = "";
    } else {
      cellContent = header === "username_email" ? item[header] : capitalizeAndReplaceUnderscores(item[header]);
    }
  
    return (
      <div
        key={headerIndex}
        className={`${
          headerIndex === 0 ? "w-[10%]" : "w-1/4"
        } border-box  ml-2 text-wrap break-all text-center`}
      >
        {cellContent}
      </div>
    );
  };

  const renderAction = (action, actionIndex, item) => (
    <button
      className="p-3 w-[130px] bg-white hover:text-blue hover:bg-[#cbd5e1] cursor-pointer font-bold text-start rounded-xl"
      key={actionIndex}
      onClick={() => action.onClick(item)}
    >
      {action.name}
    </button>
  );
  const transformedHeaders =
    data.length > 0
      ? Object.keys(data[0])
          .filter((header) => header !== "category_id")
          .map((header) =>
            header === "avatar_url"
              ? "Images"
              : header ==="username_email" ? "Username/Email" 
              : header ==="reinvite_enable" ? "Status"
              : header ==="active" ? "Status"
              : header ==="card_img" ? "Card Image"
              : capitalizeAndReplaceUnderscores(header))
      : [];

  return (
    <>
      {loading ? (
        <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
          <img src={Loader} className="m-auto" alt="" />
        </div>
      ) : (
        <>
          {data.length > 0 ? (
            <div className="w-full bg-white rounded-md mt-2">
              <div className="text-blue flex justify-center py-5 font-bold">
                {transformedHeaders.map(renderHeader)}
                {data.length > 0 && actions && actions.length > 0 && (
                  <div className="w-1/4 ml-10 text-center">Actions</div>
                )}
              </div>
              <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700 gap-0 mt-2" />
              <div>
                {data.map((item, index) => (
                  <div className="flex justify-center items-center w-full pt-5 pb-7 border-b border-b-[#D9D9D9]">
                    {headers.map((header, headerIndex) =>
                      renderCell(item, header, headerIndex)
                    )}
                    {actions && actions.length > 0 && (
                      <div className="w-1/4 ml-10 text-center">
                        <ActionsRender item={data}>
                          <div
                            id={`menu_${index}`}
                            className="absolute left-[86.5%] flex flex-col bg-white rounded-xl"
                            onMouseEnter={() =>
                              handleCursorMovement(
                                document.getElementById(`menu_${index}`)
                              )
                            }
                          >
                            {actions.map((action, actionIndex) =>
                              renderAction(action, actionIndex, item)
                            )}
                          </div>
                        </ActionsRender>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="p-4 w-full text-center">No data available.</div>
          )}
        </>
      )}
    </>
  );
};

export default Table;
