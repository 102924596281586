import PagesWrapper from "./Common/Pages/PagesWrapper";
import Chart from "./Common/GeneralChart ";
import AppWrapper from "./Routes/AppWrapper";
import Sidebar from "./Common/Sidebar";

function CashierDashboard() {
  const options = {
    xaxis: {
      chart: {
        id: "line-chart",
      },
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [30, 40, 25, 50, 49, 21, 70],
    },
  ];
  const menuItems = [
    {
      key: "cashierdashboard",
      text: "Dashboard",
      icon: "fas fa-fire",
      path: "/",
      allowedRoles: [4],
    },
    {
      key: "Cashiersales",
      text: "Sales",
      icon: "fa fa-shopping-cart",
      dropdown: true,
      subMenuItems: [
        { key: "createSales", text: "Create Sales", path: "/sale-pages" },
        { key: "allSales", text: "All Sales", path: "/sales" },
      ],
      allowedRoles: [4],
    },
    {
      key: "cashierSalemancommission",
      text: "Commission",
      icon: "fa fa-shopping-cart",
      path: "/allpos-salecommission",
      allowedRoles: [4],
    },
   
  ];
  return (
    <AppWrapper sideBar={<Sidebar menuItems={menuItems} />}>
    <PagesWrapper>
      <div className="section-header text-2xl mb-20">
        <h1><b>Dashboard</b></h1>
      </div>
      <div className="flex flex-nowrap gap-10 justify-center">
      <div className="flex flex-nowrap px-8 py-1 mb-2 bg-white rounded-2xl">
          
          <div className="w-[62px] h-[62px] box  mt-5">
            <i className="far fa-user text-2xl"></i>
          </div>
          <div className="ml-4">
            <h3 className="text-base mt-10"><b>Total Admin</b></h3>
          <div className="mt-2">10</div>
          
        </div>
      </div>
          <div className="flex flex-nowrap px-8 py-1 mb-2  bg-white rounded-2xl">
          
              <div className="w-[62px] h-[62px] box  mt-5">
                <i className="far fa-newspaper text-2xl"></i>
              </div>
              <div className="ml-4">
                <h3 className="text-base mt-10"><b>News</b></h3>
              <div className="mt-2">42</div>
              
            </div>
          </div>
          <div className="flex flex-nowrap px-8 py-1 mb-2  bg-white rounded-2xl">
          
              <div className="w-[62px] h-[62px] box  mt-5">
                <i className="far fa-file text-2xl"></i>
              </div>
              <div className="ml-4">
                <h3 className="text-base mt-10"><b>Reports</b></h3>
              <div className="mt-2">1,201</div>
              
            </div>
          </div>
          <div className="flex flex-nowrap px-8 py-1 mb-2  bg-white rounded-2xl">
          
              <div className="w-[62px] h-[62px] box  mt-5">
                <i className="far fa-circle text-2xl"></i>
              </div>
              <div className="ml-4">
                <h3 className="text-base mt-10"><b>Online Users</b></h3>
              <div className="mt-2">49</div>
              
            </div>
          </div>
        </div>
        <div className="flex flex-nowrap gap-2 mt-20">
          <div className="">
            <div className="bg-white rounded-md w-[650px] mt-6">
              <Chart options={options} series={series} type="bar" />
            </div>
          </div>
          <div className="">
            <div className="">
              <div className="mb-5 px-2 py-2">
                <h4 className="text-xl"><b>Recent Activities</b></h4>
              </div>
              <div className="flex box">
                <ul className="list-unstyled list-unstyled-border">
                  <li className="media">
                    <img
                      className="mr-3 rounded-circle"
                      width="50"
                      src="assets/img/avatar/avatar-1.png"
                      alt="avatar"
                    />
                    <div className="media-body">
                      <div className="float-right text-primary">Now</div>
                      <div className="media-title">Farhan A Mujib</div>
                      <span className="text-small text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin.
                      </span>
                    </div>
                  </li>
                  <li className="media">
                    <img
                      className="mr-3 rounded-circle"
                      width="50"
                      src="assets/img/avatar/avatar-1.png"
                      alt="avatar"
                    />
                    <div className="media-body">
                      <div className="float-right">12m</div>
                      <div className="media-title">Ujang Maman</div>
                      <span className="text-small text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin.
                      </span>
                    </div>
                  </li>
                  <li className="media">
                    <img
                      className="mr-3 rounded-circle"
                      width="50"
                      src="assets/img/avatar/avatar-1.png"
                      alt="avatar"
                    />
                    <div className="media-body">
                      <div className="float-right">17m</div>
                      <div className="media-title">Rizal Fakhri</div>
                      <span className="text-small text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin.
                      </span>
                    </div>
                  </li>
                  <li className="media">
                    <img
                      className="mr-3 rounded-circle"
                      width="50"
                      src="assets/img/avatar/avatar-1.png"
                      alt="avatar"
                    />
                    <div className="media-body">
                      <div className="float-right">21m</div>
                      <div className="media-title">Alfa Zulkarnain</div>
                      <span className="text-small text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin.
                      </span>
                    </div>
                  </li>
                </ul>
                <div className="text-center mt-12 mb-3">
                  <a className="btn btn-primary btn-lg btn-round px-2">View All</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}
export default CashierDashboard;
