
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import PosSidebar from "Components/Common/PosSidebar";
import AppWrapper from "Components/Routes/AppWrapper";
import React, { useState, useEffect } from "react";
import CunziteCreateSale from "./cunziteCreateSale.png"
import ThemeCreateSale from "./themeCreateSale.png"
import { useNavigate } from "react-router-dom";
function SalePages() {
    const navigate = useNavigate()
    const handleNavigate = e => {
        const { id } = e.target
        navigate(`/${id}`)
    }
    return (
        <AppWrapper sideBar={<PosSidebar />}>
            <PagesWrapper>
                <div className="section-header mb-7">
                    <h1 className="text-[30px]">Choose Screen</h1>
                </div>
                <div className="flex gap-3">
                    <div>
                        <img src={CunziteCreateSale} id="cashier-ui" alt="" onClick={handleNavigate} />
                    </div>
                    <div>
                        <img src={ThemeCreateSale} id="create-sale" alt="" onClick={handleNavigate} />
                    </div>
                </div>
            </PagesWrapper>
        </AppWrapper>
    );
}
export default SalePages;
