import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import ConfigurationAPI from "api/configuration/configuration";
import Button from "Components/Common/Button";
import { toast } from "react-toastify";

function CardDetail() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [dataToShow, setDataToShow] = useState({});

  useEffect(() => {
    ConfigurationAPI.cardDetail(params.id)
      .then((response) => {
        setLoading(false);
        setDataToShow(response?.data?.card);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/all-cards");
  };

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="flex justify-between w-full mt-10">
          <div className="flex text-2xl font-bold items-center">
            Card Detail
          </div>
          <div className="w-[8%]">
            <Button
              className="w-full bg-white text-blue border-blue border hover:bg-blue hover:text-white"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
        {loading ? (
          <div className={`text-center p-3 ${loading ? "loader" : ""}`}>
            <img
              src="/assets/img/loader.gif"
              className="rounded-full m-auto"
              alt="Loading"
            />
          </div>
        ) : (
          <>
            <div className="w-full mt-4 p-3">
              <div className="w-full flex justify-between">
                <div className="w-[47%] bg-white rounded-3xl min-h-[30vh]">
                  <div className="text-xl my-6 ml-4 w-1/2 font-bold">
                    Card # {dataToShow.id}
                  </div>
                  <div className="border-b p-4 border-b-[#D9D9D9] flex w-full justify-between">
                    <div className=" font-bold">Card Name:</div>
                    <div>{dataToShow.name}</div>
                  </div>
                </div>
                <div className="w-[47%] flex justify-center items-center bg-white rounded-3xl min-h-[30vh]">
                  {dataToShow.card_img ? (
                    <img
                      src={dataToShow.card_img}
                      alt="Image"
                      className="w-[60%] h-40 rounded-3xl"
                    />
                  ) : (
                    <img
                      src="/icons/no-image.png"
                      alt="No Image Found"
                      className="w-full h-40"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </PagesWrapper>
    </AppWrapper>
  );
}

export default CardDetail;
