import React, { useEffect, useState } from "react";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import GernalChartData from "./GernalChartData";
import FormatDate from "Components/Common/FormatDate";
import PosReportApi from "api/reports/posreports";
import AppWrapper from "Components/Routes/AppWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";

function AccountsReports() {
  const highSaleProducts = {
    hspName: [],
    hspQty: [],
    hspDate: [],
  };

  const productWiseSale = {
    pwsName: [],
    pwsQty: [],
    pwsDate: [],
  };

  const salesManSale = {
    smName: [],
    smQty: [],
    smDate: [],
  };

  const oldCustomer = {
    type: [],
    count: [],
    date: []
  }

  const newCustomer = {
    type: [],
    count: [],
    date: []
  }
  const [active, setActive] = useState(''); 
  const [highSellingProducts, setHighSellingProducts] = useState(highSaleProducts);
  const [productWiseSales, setProductWiseSales] = useState(productWiseSale);
  const [salesManSales, setSalesManSales] = useState(salesManSale);
  const [oldCustomerdata, setOldCustomerdata] = useState(oldCustomer);
  const [newCustomerdata, setNewCustomerdata] = useState(newCustomer);


  const getData = (sDate, eDate) => {
    PosReportApi.highestSalingProduct(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.name);
        const qty = response.data.map((key) => key.quantity);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setHighSellingProducts({
          hspName: Names,
          hspQty: qty,
          hspDate: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });

    PosReportApi.ProductWiseSale(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.name);
        const qty = response.data.map((key) => key.sales);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setProductWiseSales({
          pwsName: Names,
          pwsQty: qty,
          pwsDate: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });

    PosReportApi.salesmanSaleReport(sDate, eDate)
      .then((response) => {
        const Names = response.data.map((key) => key.username);
        const qty = response.data.map((key) => key.sales);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setSalesManSales({
          smName: Names,
          smQty: qty,
          smDate: date
        })
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });
      PosReportApi.oldCustomerReport(sDate, eDate)
      .then((response) => {
        const type = response.data.map((key) => key.type_c);
        const count = response.data.map((key) => key.count);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setOldCustomerdata({
          type: type,
          count: count,
          date: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });


      PosReportApi.newCustomerReport(sDate, eDate)
      .then((response) => {
        const type = response.data.map((key) => key.type_c);
        const count = response.data.map((key) => key.count);
        const date = response.data.map((key) => {
          const dateVal = Math.floor(key.group_column);
          if (dateVal < 99) {
            return dateVal.toString() + " Week";
          } else if (dateVal > 99) {
            return dateVal.toString();
          } else return key.group_column.toString();
        });
        setNewCustomerdata({
          type: type,
          count: count,
          date: date,
        });
      })
      .catch((error) => {
        console.error("Error No Record Found", error);
      });
  };

  const dateFilter = (e) => {
    let startDate = new Date();
    let endDate = new Date();
    const interval = parseInt(e.target.value);
    const unit = e.target.name;
    if (unit === "month") {
      const currentMonth = startDate.getMonth();
      startDate.setMonth(currentMonth - interval);
    } else if (unit === "week") {
      const daysPerWeek = 7;
      const daysInterval = interval * daysPerWeek;
      startDate.setDate(startDate.getDate() - daysInterval);
    }
    getData(FormatDate(startDate), FormatDate(endDate));
  };

  useEffect(() => {
    getData(false, false);
  }, []);

  const handleReset = (()=>{
    getData(false, false);
  })

  const weekList = [1, 3];
  const monthList = [1, 3, 6];

  return (
    <AppWrapper sideBar={<AdminSideBar />}>
      <PagesWrapper>
        <div className="text-24 font-semibold mb-10">
          POS Reports
        </div>
        <div className="flex flex-wrap justify-start bg-white indent-2">
          <img src="./icons/filter.png" alt="image" className="bg-white mt-2 h-[18px] w-[18px]" />
          <h1 className="text-base  bg-white mt-3"><b>Advance Filters</b></h1>
        </div>
        <div className="indent-96 bg-white py-6">
        {weekList.map((week, key) => (
            <button
              className="reports-buttons  bg-[#002244] mr-5 text-white text-base rounded-md hover:bg-[#7CB9E8] px-2 h-[2rem] "
              key={key}
              value={week}
              name="week"
              onClick={dateFilter}
            >
              {week}W
            </button>
          ))}
          {monthList.map((month, key) => (
            <button
              className="reports-buttons  bg-[#002244] mr-5 text-white text-base rounded-md hover:bg-[#7CB9E8] px-2 h-[2rem] "
              key={key}
              value={month}
              name="month"
              onClick={dateFilter}
            >
              {month}M
            </button>
          ))}
        <button
            className="reports-buttons  bg-[#002244] mr-5 text-white text-base rounded-md hover:bg-[#7CB9E8] px-2 h-[2rem] "
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
        <div>
        </div>

          <div className="col-lg-12 col-md-12 col-12 col-sm-12 mt-10">
          <div className="card py-3 mt-2">
          <h5 className=" indent-4 my-3 text-xl">
           <b> New Customer </b>
          </h5>
            <div className="chart">
              <GernalChartData
                dataName={newCustomerdata.type}
                dataQty={newCustomerdata.count}
                dataDate={newCustomerdata.date}
                xAxisTitle="Dates"
                yAxisTitle="Count"
                type="bar"
              />
            </div>
          </div>
        </div>
          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <div className="card py-3 mt-2">
          <h5 className="indent-4 my-3 text-xl">
          <b>Old Customer</b> 
          </h5>
          <div className="chart">
              <GernalChartData
                dataName={oldCustomerdata.type}
                dataQty={oldCustomerdata.count}
                dataDate={oldCustomerdata.date}
                xAxisTitle="Dates"
                yAxisTitle="Count"
                type="bar"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <div className="card py-3 mt-2">
          <h5 className="indent-4 my-3 text-xl">
           <b>Highest Selling Products</b>
          </h5>
            <div className="chart">
              <GernalChartData
                dataName={highSellingProducts.hspName}
                dataQty={highSellingProducts.hspQty}
                dataDate={highSellingProducts.hspDate}
                xAxisTitle="Dates"
                yAxisTitle="Quantity"
                type="bar"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <div className="card py-3 mt-2">
          <h5 className="indent-4 my-3 text-xl">
          <b>  Top Product Wise Sales</b>
          </h5>
            <div className="chart">
              <GernalChartData
                dataName={productWiseSales.pwsName}
                dataQty={productWiseSales.pwsQty}
                dataDate={productWiseSales.pwsDate}
                xAxisTitle="Dates"
                yAxisTitle="Sales"
                type="bar"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <div className="card py-3 mt-2">
          <h5 className="indent-4 my-3 text-xl"> <b>Top Sales Man Sale</b></h5>
            <div className="chart">
              <GernalChartData
                dataName={salesManSales.smName}
                dataQty={salesManSales.smQty}
                dataDate={salesManSales.smDate}
                xAxisTitle="Dates"
                yAxisTitle="Sales"
                type="bar"
              />
            </div>
          </div>
        </div>
      </PagesWrapper>
    </AppWrapper>
  );
}

export default AccountsReports;
