import React, { useState } from "react";
import FormatDate from "Components/Common/FormatDate";
import numeral from "configurations/default_money";
import Button from "Components/Common/Button";
import ProductTable from "./ProductTable";
import QRCode from "qrcode.react";
import ShowComponent from "Components/ShowComponent";
import Popup from "Components/Common/Pages/Popup";
import InvoiceShareEmail from "./InvoiceShareEmail";

function Invoice({
  recipedata,
  itemTax,
  saleitems,
  paymentMethod,
  setPopupId,
  isRedux,
}) {
  const date = new Date();
  const showTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const [popId, setPopId] = useState("");

  const conditionalSaleItems = [
    { key: isRedux ? "product_name" : "product", label: "Item" },
    { key: isRedux ? "quantity" : "quantity", label: "Qty" },
    { key: isRedux? "amount_new" : "amount_new" , label: "Amount" },
  ];

  const saleItemsColumns = [
    ...conditionalSaleItems,
    { key: "price", label: "Price" },
    // { key: "discount", label: "Disc" },
    // { key: "total_tax_amount", label: "Tax" },
   
    { key: "amount_new_with_tax", label: "Amt /VAT" },
  ];

  const itemTaxColumns = [
    { key: isRedux ? "name" : "tax_name", label: "VAT" },
    { key: isRedux ? "percentage" : "percentage", label: "Percentage" },
    { key: isRedux ? "sum_tax_amount" : "value", label: "Value" },
  ];
  return (
    <div className="w-full   print-container">
      <div className="w-full print-invoice print-invoice-scroll pt-0 pb-3 print:text-left">
        <div className="text-center mb-3 print-invoice-img">
          <img
            alt=""
            src="./CunziteLogo.png"
            className="m-auto w-[70px] h-[70px]"
          />
        </div>
        <div className="text-center">
          <h6 className="mb-1 font-bold">VAT NUMBER</h6>
          <span className="font-bold">100614829800003</span>
        </div>
        <div className=" text-black text-center font-bold fs-1 mt-4 mb-4">
          TAX INVOICE
        </div>
        <div className="flex w-full justify-between mt-5 mb-5">
          <div className="flex">
            <div className="font-bold">Print Date:</div>
            <div className="">
              {recipedata?.updated_at
                ? FormatDate(recipedata?.updated_at)
                : FormatDate(date)}
            </div>
          </div>
          <div className="flex">
            <div className="font-bold">Print Time:</div>
            <div className="">{showTime}</div>
          </div>
        </div>
        <div className="flex  w-full py-2">
          <div className="w-[50%] font-bold">Branch: </div>
          <span className="">{recipedata?.location_name}</span>
        </div>
        {/* <div className="flex border justify-between my-0 py-2 border-bottom">
          <div>Sale Status</div>
          <span className="d-block ml-3">
            {(recipedata?.sale_status || recipedata?.status) === "completed"
              ? "Completed"
              : (recipedata?.sale_status || recipedata?.status) ===
                "refunded_sale_child"
              ? "Refunded Sale Child"
              : (recipedata?.sale_status || recipedata?.status) ===
                "complete_refunded"
              ? "Complete Refunded"
              : recipedata?.sale_status || recipedata?.status}
          </span>
        </div> */}
        <div className="flex w-full py-2">
          <div className="w-[50%] font-bold">Invoice No: </div>
          <span className="">{recipedata?.invoice_no}</span>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[50%] font-bold">Invoice Date: </div>
          <div className="">
            {recipedata?.updated_at
              ? FormatDate(recipedata?.updated_at)
              : FormatDate(date)}
          </div>
        </div>
        <div className="flex w-full py-2  ">
          <div className="w-[50%] font-bold">Salesman</div>
          <span className="">{recipedata?.sale_created_by}</span>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[50%] font-bold">Payment Type: </div>
          <div className="">
            {paymentMethod?.find((element) => element.payment_method == "cash")
              .amount > 0 &&
            paymentMethod?.find((element) => element.payment_method == "card")
              .amount > 0
              ? "Cash/Visa"
              : paymentMethod?.find(
                  (element) => element.payment_method == "cash"
                ).amount > 0
              ? "Cash"
              : paymentMethod?.find(
                  (element) => element.payment_method == "card"
                ).amount > 0
              ? "Credit Card"
              : paymentMethod?.payment_method}
          </div>
        </div>
        <div className="flex w-full py-2  ">
          <div className="w-[50%] font-bold">TRN:</div>
          <div className="">100614829800003</div>
        </div>
        <div className="flex w-full py-2  ">
          <div className="w-[50%] font-bold">Cash Amount:</div>
          <div className="">
            {numeral(
              paymentMethod?.find((element) => element.payment_method == "cash")
                .amount
            ).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2  ">
          <div className="w-[50%] font-bold">Visa Amount:</div>
          <div className="">
            {numeral(
              paymentMethod?.find((element) => element.payment_method == "card")
                .amount
            ).format("0[.]00")}
          </div>
        </div>
        <div className=" border-b-2 border-black"></div>
        <ProductTable
          tableData={saleitems}
          columns={saleItemsColumns}
          className={"invoice-table w-full  mb-3 mt-3"}
        />
        <div className=" border-b-2 border-black"></div>
        <div className=" border-b-2 border-black mt-2"></div>
        {/* <ProductTable
          tableData={itemTax}
          columns={itemTaxColumns}
          className={"invoice-table w-full border mb-1 mt-3"}
        /> */}

        <div className="flex w-full py-2  ">
          <div className="w-[60%] font-bold">Net Amount:</div>
          <div className="">
            {numeral(recipedata?.net_amount).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Total Amount :</div>
          <div className="">
            {numeral(recipedata?.total_amount).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Discount:</div>
          <div className="">
            {numeral(recipedata?.general_discount).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Coupon Discount:</div>
          <div className="">
            {numeral(recipedata?.coupon_discount).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Special Discount:</div>
          <div className="">
            0
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">VAT:</div>
          <div className="">
            {numeral(recipedata?.tax).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Net Amount/ VAT</div>
          <div className="">
            {numeral(recipedata?.total_price).format("0[.]00")}
          </div>
        </div>
        <div className="flex w-full py-2 ">
          <div className="w-[60%] font-bold">Changed</div>
          <div className="">
            {numeral(recipedata?.return_amount).format("0[.]00")}
          </div>
        </div>
        <div className="mt-10 social-media">
          <div className="flex w-full   justify-center ">
            <div className="">Tel: +971 4 332 3449</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Info@cunzite.com</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">www.cunzite.com</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Facebook:@cunziteperfume</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Instagram:@cunziteperfume</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Snapchat:cunziteperfume</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Whatsapp:+971 56 404 8882</div>
          </div>
          <div className="flex w-full  justify-center">
            <div className="">Thank you for shopping with us </div>
          </div>
        </div>
        {recipedata?.taxfree && (
          <>
        <div className="mt-10 mb-10 flex w-full justify-center">
          Exchange Policy
        </div>
        
            <div className="qrcode flex w-full justify-center">
              <QRCode value="https://cunzite.com/ar/exchange-policy" />
            </div>
            <div className="flex border w-full mt-5">
              <div className="w-full justify-center flex  items-center p-2">
                <img src="./texfree.webp" alt="img" className="rounded-full" />
              </div>
              <div className="">
                <div className="leading-8 font-bold w-full">
                  Thank you for your purchase
                </div>
                <div className="leading-4">
                  By using out service, you agree to our T&Cs and Privacy Policy
                  - visit www.planetpayment.ae for full details. Planet has been
                  authorized by the FTA.
                </div>
                <div className="leading-8 font-bold">{recipedata.taxfree?.taxRefundTagNumber}</div>
                <div className="border-bottom w-[80%] justify-center flex"></div>
                <div className="mb-3 mt-3 font-bold"> www.planetpayment.ae</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="justify-center pt-2   text-left  flex">
        <Button
          className="w-[30%] btn-primary mr-2 text-white bg-green"
          onClick={() => window.print()}
        >
          Print
        </Button>
        <Button
          className="w-[30%] btn-secondary mr-2 bg-blue"
          onClick={() => setPopId("shareEmail")}
        >
          Share
        </Button>
        <Button
          className="w-[30%] btn-secondary  bg-red"
          onClick={() => setPopupId("")}
        >
          Close
        </Button>
        <ShowComponent condition={popId === "shareEmail"}>
            <Popup setPopup={() => setPopupId("")} className="w-[35%]">
              <InvoiceShareEmail
                setPopupId={setPopupId}
                contentOnly={true}
              />
            </Popup>
          </ShowComponent>
      </div>
    </div>
  );
}
export default Invoice;
