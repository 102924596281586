import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "Components/Common/Pages/PagesWrapper";
import AdminSideBar from "Components/Common/AdminSideBar";
import Table from "Components/Common/GeneralTable";
import AppWrapper from "Components/Routes/AppWrapper";
import Button from "Components/Common/Button";
import TransfersAPI from "api/posapi/transfers";
function TransferHistory() {
  const navigate = useNavigate();
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleCreateTransfer = () => {
    navigate(`/create-transfer`);
  };
  const handleDetailsClick = (itemId) => {
    navigate(`/transfer/${itemId}`);
  };
  const actions = [
    {
      name: "View",
      icon: "fas fa-eye",
      color: "black",
      onClick: (item) => handleDetailsClick(item.id),
    },
  ];

  useEffect(() => {
    setLoading(true);
    TransfersAPI.historyTranferAPI()
      .then((response) => {
        setDataToShow(response.data.transfers);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <AppWrapper sideBar={<AdminSideBar />}>
        <PagesWrapper>
          <div className="w-full flex mb-4 justify-between">
            <div className="text-24 font-semibold mb-4">
              Transfers
            </div>
            <div className="w-[15%] flex justify-end mt-2 text-end">
              <button className="btn-primary w-full" onClick={handleCreateTransfer}>
                Create Transfer
              </button>
            </div>
          </div>
          <Table data={dataToShow} loading={loading} actions={actions} />
        </PagesWrapper>
      </AppWrapper>
    </>
  );
}
export default TransferHistory;
