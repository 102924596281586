import React from "react";
function Popup({ children, className, setPopup }) {
  return (
    <>
      <div className="flex justify-center absolute left-0 right-0 bottom-0 top-0 sm:top-7">
        <div
          className={`fixed ${className} max-h-[100vh] sm:max-h-[90vh] overflow-auto sm:border sm:border-gray-500 z-[100]`}
        >
          <div className="bg-white shadow-lg h-screen sm:h-auto w-full sm:w-auto">
            <div className="flex justify-end border-b border-gray-300 p-2">
              <button
                type="button"
                className="p-[8px] bg-slate-300 text-white rounded-full border"
                onClick={setPopup}
              >
              <img src="/assets/img/cross.svg" width="7" alt="" />
              </button>
            </div>
            <div className="p-4 bg-white">{children}</div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-50 visible z-[9]"></div>
    </>
  );
}
export default Popup;