import React, { useState } from "react";

const Pagination = ({
  setPageNumber,
  pageNumber,
  totalPages,
  totalCount,
  startValue,
  endValue,
}) => {
  const [tempPage, setTempPage] = useState(pageNumber);

  const increasePage = () => {
    if (tempPage < totalPages) {
      setPageNumber(pageNumber + 1);
      setTempPage(tempPage + 1);
    }
  };

  const decrPage = () => {
    if (tempPage > 1) {
      setPageNumber(pageNumber - 1);
      setTempPage(tempPage - 1);
    }
  };

  const handleChange = (event) => {
    setTempPage(event.target.value);
  };

  const assignPage = (e) => {
    if (e.key === "Enter") {
      const newPage = parseInt(e.target.value, 10);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
        setPageNumber(newPage);
        setTempPage(newPage);
      }
    }
  };

  const handlePages = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPageNumber(newPage);
      setTempPage(newPage);
    }
  };

  const handleLastPage = () => {
    handlePages(totalPages);
    setTempPage(totalPages);
  };

  return (
    <div className="w-auto  flex flex-row justify-center alighn-center">
      <div className="w-auto align-center text-center flex bg-white rounded-xl  mt-2  mb-1  ">
        <div className="w-auto md:w-7/12 bg-white lg:w-7/12 mt-2 md:mt-0 rounded-md  p-2">
          <ul className="flex justify-between space-x-2">
            <li
              className={`px-2  flex items-center justify-center rounded-md ${
                pageNumber === 1 && "opacity-50 cursor-not-allowed font-Montserrat"
              }`}
            >
              <button
                onClick={decrPage}
                className={`text-black h-9 ${
                  pageNumber === 1 ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center  font-Montserrat`}
              >
                Previous
              </button>
            </li>
            {tempPage > 2 && (
              <li className="flex items-center justify-center">
                <button
                  onClick={() => handlePages(1)}
                  className={`text-black ${
                    tempPage === 2 ? "bg-blue text-white" : ""
                  } px-2  rounded-md text-center font-Montserrat`}
                >
                  1
                </button>
              </li>
            )}
            {tempPage > 4 && (
              <li
                className={`text-black flex items-center justify-center${
                  tempPage === 1 ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center  font-Montserrat`}
              >
                .....
              </li>
            )}
            {tempPage === 4 && (
              <li className="flex items-center justify-center">
                <button
                  onClick={() => handlePages(tempPage - 2)}
                  className={`text-black ${
                    tempPage - 2 === pageNumber ? "bg-blue text-white" : ""
                  } px-2  rounded-md text-center font-Montserrat`}
                >
                  {tempPage - 2}
                </button>
              </li>
            )}
            {tempPage > 1 && (
              <li className="flex items-center justify-center">
                <button
                  onClick={() => handlePages(tempPage - 1)}
                  className={`text-black ${
                    tempPage - 1 === pageNumber ? "bg-blue text-white" : ""
                  } px-2  rounded-md text-center font-Montserrat`}
                >
                  {tempPage - 1}
                </button>
              </li>
            )}
            <li className="flex items-center justify-center">
              <input
                type="number"
                min="1"
                value={tempPage}
                className={`hideNumberArrow text-black h-8 w-12  ${
                  tempPage == pageNumber ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center font-Montserrat`}
                onChange={handleChange}
                onKeyPress={assignPage}
              />
            </li>
            {tempPage < totalPages - 1 && (
              <li className="flex items-center justify-center">
                <button
                  onClick={() => handlePages(tempPage + 1)}
                  className={`text-black ${
                    tempPage + 1 === pageNumber ? "bg-blue text-white ": ""
                  } px-2  rounded-md text-center font-Montserrat`}
                >
                  {tempPage + 1}
                </button>
              </li>
            )}
            {tempPage === 1 && totalPages > 5 && (
              <>
                <li className="flex items-center justify-center">
                  <button
                    onClick={() => handlePages(tempPage + 2)}
                    className={`text-black ${
                      tempPage + 2 === pageNumber ? "bg-blue text-white" : ""
                    } px-2  rounded-md text-center font-Montserrat`}
                  >
                    {tempPage + 2}
                  </button>
                </li>
                <li className="flex items-center justify-center">
                  <button
                    onClick={() => handlePages(tempPage + 3)}
                    className={`text-black ${
                      tempPage + 3 === pageNumber ? "bg-blue text-white" : ""
                    } px-2  rounded-md text-center font-Montserrat`}
                  >
                    {tempPage + 3}
                  </button>
                </li>
                <li className="flex items-center justify-center">
                  <button
                    onClick={() => handlePages(tempPage + 4)}
                    className={`text-black ${
                      tempPage + 4 === pageNumber ? "bg-blue text-white" : ""
                    } px-2  rounded-md text-center font-Montserrat`}
                  >
                    {tempPage + 4}
                  </button>
                </li>
              </>
            )}
            {tempPage < totalPages - 2 && (
              <li className="text-blue flex items-center justify-center">
                .....
              </li>
            )}
            {tempPage !== totalPages && (
              <li className="flex items-center justify-center">
                <button
                  onClick={handleLastPage}
                  className={`text-black ${
                    totalPages === pageNumber ? "bg-blue text-white" : ""
                  } px-2  rounded-md text-center font-Montserrat`}
                >
                  {totalPages}
                </button>
              </li>
            )}
            <li
              className={`flex items-center justify-center ${
                pageNumber === totalPages && "opacity-50 cursor-not-allowed"
              }`}
            >
              <button
                onClick={increasePage}
                className={`text-black h-9 ${
                  totalPages === pageNumber ? "bg-blue text-white" : ""
                } px-2  rounded-md text-center font-Montserrat`}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
