import React from "react";
import Button from "./Button";
import { Tooltip } from "@mui/material";

function ActiveInactiveButton({ filter, setFilter }) {
  return (
    <div className="mt-[19px] ml-2">
      <Tooltip
        title={filter ? "Filter Enabled" : "Filter Disabled"}
        placement="bottom"
        arrow
      >
        <Button
          className={`${
            filter ? "btn bg-green disabled h-[2.95rem] w-[6rem] mt-2" : "btn bg-grey btn-primary h-[2.95rem] w-[6rem] mt-2"
          }`}
          type="button"
          onClick={() => {
            filter ? setFilter(false) : setFilter(true);
          }}
        >
        <div>{filter ? "Apply" : "Apply"}</div>
        </Button>
      </Tooltip>
    </div>
  );
}

export default ActiveInactiveButton;
