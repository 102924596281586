import React, { useState, useEffect } from "react";
import Button from "Components/Common/Button";
import { useSelector } from "react-redux";
import numeral from "configurations/default_money";
import ConfigurationAPI from "api/configuration/configuration";
import { toast } from "react-toastify";

function ConfirmationSale({
  status,
  handleCreateOrder,
  cardAmount,
  setCardAmount,
  cashAmount,
  setCashAmount,
  returnAmount,
  setStatus,
  setPopupId,
  selectedCard,
  setSelectedCard,
  approvalCode,
  setApprovalCode,
  setTempModel,
}) {
  const { totalPrice } = useSelector((state) => state.sales);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  const [isImageClicked, setIsImageClicked] = useState(false);
  const taxFree = useSelector((state) => state.Taxfree.is_tax_free);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    ConfigurationAPI.showAllCards()
      .then((response) => {
        setLoading(false);
        setCardsData(response?.data?.cards);
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong!");
        }
      });
    setTempModel("finishSale");
    if (isChecked) {
      setPopupId("taxFree");
    }
  }, [isChecked, setTempModel("")]);

  const handleCancel = () => {
    setPopupId("");
  };

  const handleCardSelect = (id) => {
    if (id === selectedCard) {
      setSelectedCard("");
      setIsImageClicked(false);
      setCardAmount("");
      setApprovalCode("");
    } else {
      setSelectedCard(id);
      setIsImageClicked(true);
    }
  };

  const handleApprovalCode = (e) => {
    setApprovalCode(e.target.value);
  };

  const handleCardAmountChange = (e) => {
    if (!isImageClicked) {
      e.preventDefault();
      return;
    }
    setCardAmount(e.target.value);
  };

  const message =
    parseFloat(cardAmount) > parseFloat(totalPrice)
      ? "Can't exceed Total Bill"
      : "";

  const buttons = ["7", "8", "9", "B"];
  const buttons2 = ["4", "5", "6"];
  const buttons3 = ["1", "2", "3"];
  const buttons4 = ["0", "."];

  return (
    <>
      <form onSubmit={handleCreateOrder}>
        <div className="flex">
          <div className="w-[50%] mr-40">
            <div className="flex w-full items-center">
              <div className="w-[30%] font-bold">Total Bill:</div>
              <input
                className="w-[60%] form-control"
                type="number"
                value={numeral(totalPrice).format("0[.]00")}
              />
            </div>
            <div className="flex w-full items-center mt-1">
              <div className="w-[30%] font-bold">Cash Amount:</div>
              <input
                className="w-[60%] form-control"
                type="number"
                placeholder="0.00"
                value={cashAmount}
                onChange={(e) =>
                  setCashAmount((preCashAmount) => e.target.value)
                }
              />
            </div>
            <div className="flex w-full items-center mt-1">
              <div className="w-[30%] font-bold">Card Amount:</div>
              <input
                className={`w-[60%] form-control ${
                  message ? "border-red" : ""
                }`}
                type="number"
                placeholder="0.00"
                value={cardAmount}
                onChange={handleCardAmountChange}
                disabled={!isImageClicked}
                required={isImageClicked}
              />
            </div>
            <p className="text-red text-xs mt-1 ml-2 w-[60%] flex justify-end">
              {message}
            </p>
            <div className="flex w-full items-center">
              <div className="w-[30%] font-bold">Return Amount:</div>
              <input
                className="w-[60%] form-control"
                type="number"
                placeholder="Return Amount"
                value={numeral(returnAmount).format("0[.]00")}
                readOnly={true}
              />
            </div>
          </div>
          <div className="w-[50%]">
            <div className="flex justify-end w-full mb-5">
              <Button
                type="submit"
                className=" btn-primary w-[30%]"
                disabled={parseFloat(cardAmount) > parseFloat(totalPrice)}
              >
                OK
              </Button>
              <Button
                type="button"
                className="btn-primary w-[30%] ml-5"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
            <div className="font-bold">TAX FREE</div>
            <div className="w-full border-2 h-10 flex items-center justify-center">
              <input
                type="checkbox"
                id="taxFreeCheckbox"
                checked={isChecked || taxFree}
                onChange={handleCheckboxChange}
                className="mr-2 h-4 w-4"
              />
              <span htmlFor="taxFree" className="font-bold">
                Tax Free
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end">
          {/* Style Start Not Implemented */}
          {/* <div className="w-[50%] mr-6">
            <div className="font-bold mt-6">CASH MULTI-CURRENCY OPTIONS</div>
            <div className="w-full border-2 h-10 mb-4 min-h-[50vh]">
              <div className="w-full flex justify-end pr-10 pt-10">
                <input type="text" className="p-3 border-2" />
              </div>
              <div className="flex">
                <div className="w-[50%]"></div>
                <div className="w-[50%]">
                  <div className="w-full flex pr-10 pt-1">
                    {buttons.map((button) => (
                      <button
                        key={button}
                        id={button}
                        className="w-[25%] h-[40px] mr-1 bg-transparent border border-gray-500 font-semibold cursor-pointer"
                        disabled
                      >
                        {button}
                      </button>
                    ))}
                  </div>
                  <div className="grid grid-cols-12 gap-0 mr-10">
                    <div className="col-span-9">
                      {buttons2.map((button) => (
                        <button
                          key={button}
                          id={button}
                          className="w-[30.5%] h-[40px] mr-1 mt-1 bg-transparent border border-gray-500 font-semibold cursor-pointer"
                          disabled
                        >
                          {button}
                        </button>
                      ))}
                      {buttons3.map((button) => (
                        <button
                          key={button}
                          id={button}
                          className="w-[30.5%] h-[40px] mr-1 mt-1 bg-transparent border border-gray-500 font-semibold cursor-pointer"
                          disabled
                        >
                          {button}
                        </button>
                      ))}

                      {buttons4.map((button, index) => (
                        <button
                          key={button}
                          id={button}
                          className={`mr-1 mt-1 bg-transparent border border-gray-500 font-semibold cursor-pointer h-[40px] ${
                            index === 0 ? "w-[62%]" : "w-[32%]"
                          }`}
                          disabled
                        >
                          {button}
                        </button>
                      ))}
                    </div>
                    <div className="bg-transparent border border-gray-500 col-span-3 cursor-pointer mr-1 flex justify-center items-center mt-1">
                      <button className="font-extrabold cursor-pointer" disabled>
                        E
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[86%] ml-5 mr-10 mt-5 border border-gray-500">
                <tr className="w-full">
                  <th className="w-[40%] p-2 border-r border-gray-500">
                    Currency
                  </th>
                  <th className="w-[20%] p-2 border-r border-gray-500">
                    Exchange Rate
                  </th>
                  <th className="w-[20%] p-2 border-r border-gray-500">
                    Exchange Amount
                  </th>
                  <th className="w-[20%] p-2"></th>
                </tr>
              </div>
            </div>
          </div> */}
          {/* Style End Not Implemented */}

          <div className="w-[50%]">
            <div className="font-bold mt-6">CARDS</div>
            <div className="w-full border-2 h-10 mb-4 min-h-[50vh]">
              <div className="flex flex-wrap px-5">
                {cardsData.map((card) => (
                  <div key={card.id} className="w-1/2">
                    <img
                      alt={card.name}
                      src={card.card_img}
                      className={`w-[90%] h-[90px] mt-4 ml-4 cursor-pointer ${
                        selectedCard === card.id ? "border-4 border-blue" : ""
                      }`}
                      onClick={() => handleCardSelect(card.id)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex w-[96%] mx-2 mt-20 p-4">
                <input
                  className="w-[80%] border-2 mx-2 p-4"
                  placeholder="APPROVAL CODE"
                  value={approvalCode}
                  onChange={handleApprovalCode}
                  required={isImageClicked}
                />
                <div className="w-[20%] font-extrabold border-2 mx-2 py-4">
                  <img
                    alt=""
                    src="./three-dots.png"
                    className="px-2 w-[100px] h-[20px] cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ConfirmationSale;
